import { motion, AnimatePresence } from 'framer-motion';
import { X, Medal, Award, Trophy, AlertCircle } from 'lucide-react';

interface TrophyStatsProps {
  isOpen: boolean;
  onClose: () => void;
}

export function TrophyStats({ isOpen, onClose }: TrophyStatsProps) {
  const trophyDetails = [
    {
      type: 'Gold',
      icon: Trophy,
      color: 'text-amber-500',
      bg: 'bg-amber-500/10',
      border: 'border-amber-500/20',
      description: 'Earned by completing hard questions'
    },
    {
      type: 'Silver',
      icon: Award,
      color: 'text-gray-400',
      bg: 'bg-gray-400/10',
      border: 'border-gray-400/20',
      description: 'Earned by completing medium questions'
    },
    {
      type: 'Bronze',
      icon: Medal,
      color: 'text-amber-700',
      bg: 'bg-amber-700/10',
      border: 'border-amber-700/20',
      description: 'Earned by completing easy questions'
    },
    {
      type: 'Incorrect',
      icon: AlertCircle,
      color: 'text-red-500',
      bg: 'bg-red-500/10',
      border: 'border-red-500/20',
      description: 'Questions answered incorrectly',
      subText: 'Keep practicing to improve your score!',
      isNegative: true
    }
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-gray-900/95 backdrop-blur-sm z-[200] flex items-stretch"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div className="flex flex-col w-full bg-gray-900"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: 'spring', damping: 25, stiffness: 200 }}
          >
            <header className="flex-none bg-gray-900 border-b border-gray-800 pt-safe sticky top-0 z-10">
              <div className="flex items-center justify-between p-4">
                <div className="flex items-center gap-3">
                  <div className="p-2 rounded-xl bg-gray-800">
                    <Trophy size={20} />
                  </div>
                  <h2 className="text-lg font-medium">Trophy Stats</h2>
                </div>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-gray-800 rounded-xl transition-colors"
                >
                  <X size={20} />
                </button>
              </div>
            </header>

            <div className="flex-1 overflow-y-auto p-4 space-y-6 pb-safe min-h-0">
              {trophyDetails.map((trophy, index) => (
                <motion.div
                  key={trophy.type}
                  className={`p-4 rounded-xl ${trophy.bg} ${trophy.border} border-2`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="flex items-start gap-4">
                    <div className={`p-3 rounded-xl ${trophy.bg}`}>
                      <trophy.icon className={`w-8 h-8 ${trophy.color}`} />
                    </div>
                    <div className="flex-1">
                      <div className="flex items-center justify-between mb-2">
                        <h3 className={`text-lg font-medium ${trophy.color}`}>
                          {trophy.type} {!trophy.isNegative ? 'Trophy' : 'Answers'}
                        </h3>
                      </div>
                      <p className="text-sm text-gray-400">{trophy.description}</p>
                      {trophy.subText && (
                        <p className="text-xs text-gray-500 mt-1">{trophy.subText}</p>
                      )}
                    </div>
                  </div>
                </motion.div>
              ))}
              <div className="h-12" /> {/* Extra spacing at bottom */}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}