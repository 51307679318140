import TicTacToe from './tic-tac-toe/game';
import TowerBlocks from './towerblocks/game';
import StickHero from './StickHero/game';
import Snowboard from './SnowBoard/game';
import CrossyRoad from './crossy-road/game';
import SquidGame from './SquidGame/game';
import Minecraft from './Minecraft/game';
import Slither from './Slither/game';
interface GameWrapperProps {
  gameId: string;
  difficulty: 'easy' | 'medium' | 'hard';
  onRestart: () => void;
  sectionId?: string; // Add section ID
}

export function GameWrapper({ gameId, difficulty, onRestart, sectionId }: GameWrapperProps) {
  // Log which section and game was selected
  console.log("GameWrapper - Section ID:", sectionId);
  console.log("GameWrapper - Game ID:", gameId);
  console.log("GameWrapper - Difficulty:", difficulty);

  const games = {
    'tic-tac-toe': { component: TicTacToe, title: 'Tic Tac Toe' },
    'towerblocks': { component: TowerBlocks, title: 'Tower Blocks' },
    'stickhero': { component: StickHero, title: 'Stick Hero' },
    'snowboard': { component: Snowboard, title: 'Snowboard' },
    'crossy-roads': { component: CrossyRoad, title: 'Crossy Road' },
    'SquidGame': { component: SquidGame, title: 'Squid Game' },
    'Minecraft': { component: Minecraft, title: 'Minecraft' },
    'Slither': { component: Slither, title: 'Slither' },
  };
  
  const game = games[gameId as keyof typeof games];
    
  if (!game) {
    console.warn(`Game not found: ${gameId}`);
    return <div>Game not found</div>;
  }
  
  const { component: GameComponent, title } = game;
  
  return <GameComponent 
    difficulty={difficulty} 
    onRestart={onRestart} 
    title={title} 
    onClose={onRestart} 
    sectionId={sectionId}  // Pass the section ID to the game component
  />;
}