// src/components/games/crossy-road/game.tsx
import { useEffect, useState, useCallback, useRef } from "react";
import { QuestionModal } from "../question-modal";
import { useTrophies } from "@/hooks/use-trophies";
import { useGameState } from "@/hooks/use-game-state";

interface SnowboardFrameProps {
  onClose: () => void;
  title?: string;
  difficulty?: 'easy' | 'medium' | 'hard';
  onRestart?: () => void;
  sectionId?: string;
}

export default function SnowboardFrame({
  onClose,
  title = 'Snowboard',
  difficulty: difficultyProp = 'easy',
  onRestart,
  sectionId
}: SnowboardFrameProps) {
  console.log("Snowboard - Title received:", title);
  console.log("Snowboard - Difficulty received:", difficultyProp);
  console.log("Snowboard - Section ID received:", sectionId);

  const { awardTrophy } = useTrophies();
  const { setGameState, setWinner } = useGameState();

  const [showQuestion, setShowQuestion] = useState(false);
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">(difficultyProp);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setDifficulty(difficultyProp);
  }, [difficultyProp]);

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage({
        type: "modalStateChange",
        isActive: showQuestion
      }, "*");
    }
  }, [showQuestion]);

  useEffect(() => {
    function checkStorage() {
      const gameData = localStorage.getItem("snowboardResult");
      if (gameData) {
        try {
          const { result, score } = JSON.parse(gameData);
          console.log("Snowboard Result:", result);
          console.log("Snowboard Score:", score);
          console.log("Snowboard Result for Section:", sectionId);

          if (result === "lost") {
            setShowQuestion(true); // Show modal regardless, QuestionModal handles question selection/fallback
          } else {
            setGameState("won");
            setWinner("You");
          }
        } catch (error) {
            console.error("Error parsing snowboardResult from localStorage:", error);
            // Decide how to handle parsing errors, maybe default to closing or showing an error
        } finally {
             localStorage.removeItem("snowboardResult");
        }
      }
    }

    const interval = setInterval(checkStorage, 1000);

    function handleMessage(event: MessageEvent) {
       if (event.origin !== "https://course.pursuitnz.com" && event.origin !== window.location.origin) {
         // Optional: Ignore messages from unexpected origins for security
         // console.warn("Ignoring message from unexpected origin:", event.origin);
         // return;
       }

      if (event.data?.type === "closeGame") {
        onClose();
      }
    }

    window.addEventListener("message", handleMessage);
    return () => {
      clearInterval(interval);
      window.removeEventListener("message", handleMessage);
    };
  }, [setGameState, setWinner, onClose, sectionId, difficulty]); // Keep dependencies relevant to this effect

  const handleQuestionAnswer = useCallback(async (correct: boolean) => {
    if (correct) {
      const trophyType = {
        easy: "bronze",
        medium: "silver",
        hard: "gold",
      }[difficulty] as "bronze" | "silver" | "gold";

      if (sectionId) { // Only award trophy if it was part of a section
        await awardTrophy(trophyType);
        console.log(`Trophy awarded (${trophyType}) for section: ${sectionId}`);
      } else {
        console.log(`Correct answer, but no sectionId provided. No trophy awarded.`);
      }
      setGameState("won");
      setWinner("You");
    } else {
      setGameState("won");
      setWinner("Computer");
      console.log(`Game lost (incorrect answer) for section: ${sectionId || 'none'}`);
    }
    setShowQuestion(false);
  }, [difficulty, awardTrophy, setGameState, setWinner, sectionId]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999, // Ensure it covers everything
      }}
    >
      <iframe
        ref={iframeRef}
        src={`https://course.pursuitnz.com/CrossyRoads/index.html?difficulty=${difficulty}&section=${sectionId || ''}`}
        // Use a relative path if hosted on the same domain or configure CORS appropriately
        // src={`/games/CrossyRoads/index.html?difficulty=${difficulty}&section=${sectionId || ''}`}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title="Snowboard Game"
      />

      {showQuestion && (
        <QuestionModal
          onAnswer={handleQuestionAnswer}
          difficulty={difficulty}
          sectionId={sectionId} // Pass sectionId, QuestionModal handles undefined case
        />
      )}
    </div>
  );
}