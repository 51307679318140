import { motion, AnimatePresence } from 'framer-motion';
import { Wifi, WifiOff, X } from 'lucide-react';
import { useNetworkStatus } from '@/hooks/use-network-status';
import { useState, useEffect } from 'react';

export function NetworkStatus() {
  const { isOnline, effectiveType } = useNetworkStatus();
  const [hidden, setHidden] = useState(false);

  // Function to dismiss the alert for 20 minutes
  const dismissAlert = () => {
    setHidden(true);
    localStorage.setItem('network-alert-dismissed', Date.now().toString());
  };

  // Check if 20 minutes have passed since last dismissal
  useEffect(() => {
    const lastDismissed = localStorage.getItem('network-alert-dismissed');
    if (lastDismissed) {
      const timeElapsed = Date.now() - parseInt(lastDismissed, 10);
      if (timeElapsed > 20 * 60 * 1000) {
        setHidden(false);
        localStorage.removeItem('network-alert-dismissed');
      }
    }
  }, [isOnline, effectiveType]); // Recheck on network change

  if (hidden) return null;

  return (
    <AnimatePresence>
      {!isOnline && (
        <motion.div
          className="fixed bottom-0 inset-x-0 pb-safe z-[1000]"
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '100%' }}
        >
          <div className="bg-red-500 text-white p-4 flex items-center justify-center relative">
            <div className="flex items-center gap-2">
              <WifiOff size={18} />
              <span>You are offline. Some features may be limited.</span>
            </div>
            <button 
              onClick={dismissAlert} 
              className="absolute right-4 text-white"
            >
              <X size={18} />
            </button>
          </div>
        </motion.div>
      )}

      {isOnline && effectiveType && effectiveType !== '4g' && (
        <motion.div
          className="fixed bottom-0 inset-x-0 pb-safe z-[1000]"
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '100%' }}
        >
          <div className="bg-yellow-500 text-white p-4 flex items-center justify-center relative">
            <div className="flex items-center gap-2">
              <Wifi size={18} />
              <span>Slow connection detected. Some features may be affected.</span>
            </div>
            <button 
              onClick={dismissAlert} 
              className="absolute right-4 text-white"
            >
              <X size={18} />
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
