import { motion } from 'framer-motion';
import { Pencil } from 'lucide-react';
import { cn } from '@/lib/utils';

interface SearchOptionProps {
  label: string;
  value: string;
  isSelected: boolean;
  isCustom?: boolean;
  onSelect: () => void;
  onEdit?: () => void;
}

export function SearchOption({ 
  label, 
  value, 
  isSelected, 
  isCustom,
  onSelect,
  onEdit 
}: SearchOptionProps) {
  return (
    <motion.button
      className={cn(
        "w-full px-6 py-4 rounded-xl text-lg font-medium transition-colors relative overflow-hidden",
        isSelected ? "text-white" : "text-gray-300 hover:text-white"
      )}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onSelect}
    >
      <motion.div
        className="absolute inset-0 bg-blue-600"
        initial={false}
        animate={{
          opacity: isSelected ? 1 : 0,
          scale: isSelected ? 1 : 0.95
        }}
        transition={{ duration: 0.2 }}
      />
      
      <motion.div
        className="absolute inset-0 bg-gray-800"
        initial={false}
        animate={{
          opacity: isSelected ? 0 : 1
        }}
        transition={{ duration: 0.2 }}
      />

      <div className="relative z-10 flex items-center justify-between">
        <span>{label}</span>
        {isCustom && onEdit && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            className="p-1 hover:bg-black/20 rounded"
          >
            <Pencil size={16} />
          </button>
        )}
      </div>
    </motion.button>
  );
}