import { motion, LazyMotion, domAnimation } from 'framer-motion';

export function Clock() {
  return (
    <LazyMotion features={domAnimation}>
      <motion.div 
        className="flex flex-col justify-center items-center w-full font-poppins"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div 
          className="text-8xl sm:text-9xl md:text-[10rem] lg:text-[12rem] font-medium mb-6 uppercase text-white"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          TAMS
        </motion.div>
        <motion.div 
          className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium text-white/75 uppercase whitespace-nowrap"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          EMPLOYMENT HUB
        </motion.div>
      </motion.div>
    </LazyMotion>
  );
}