import { useState } from 'react';
import { motion } from 'framer-motion';
import { X, UserPlus, Loader2, QrCode } from 'lucide-react';
import { createClient } from '@supabase/supabase-js';
import { QRCodeSVG } from 'qrcode.react';

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
  {
    global: {
      headers: {
        'x-facilitator-code': '1111'
      }
    }
  }
);

interface CreateMemberFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function CreateMemberForm({ onClose, onSuccess }: CreateMemberFormProps) {
  const [username, setUsername] = useState('');
  const [passcode, setPasscode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showQR, setShowQR] = useState(false);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);

  // Check username availability with debounce
  const checkUsername = async (value: string) => {
    if (!value.trim()) return;
    
    setIsCheckingUsername(true);
    try {
      const { data, error } = await supabase
        .from('login_codes')
        .select('id')
        .eq('name', value)
        .maybeSingle();

      if (error) throw error;

      if (data) {
        setError('Username already exists');
      } else {
        setError(null);
      }
    } catch (err) {
      console.error('Error checking username:', err);
    } finally {
      setIsCheckingUsername(false);
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUsername(value);
    setError(null);

    // Check for reserved username
    if (value.toLowerCase() === 'facilitator') {
      setError('This username is reserved');
      return;
    }

    // Debounced username check
    const timeoutId = setTimeout(() => checkUsername(value), 500);
    return () => clearTimeout(timeoutId);
  };

  const handlePasscodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    // Only allow numbers and max length of 4
    if (value.length <= 4 && /^\d*$/.test(value)) {
      setPasscode(value);
      setError(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!username.trim() || !passcode.trim()) {
      setError('Username and passcode are required');
      return;
    }

    if (passcode.length !== 4) {
      setError('Passcode must be exactly 4 digits');
      return;
    }

    if (username.toLowerCase() === 'facilitator') {
      setError('This username is reserved');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      // Final username check before submission
      const { data: existingUser, error: checkError } = await supabase
        .from('login_codes')
        .select('id')
        .eq('name', username)
        .maybeSingle();

      if (checkError) throw checkError;

      if (existingUser) {
        setError('Username already exists');
        setIsSubmitting(false);
        return;
      }

      // Create new user
      const { error: createError } = await supabase
        .from('login_codes')
        .insert({
          name: username,
          code: passcode,
          used: true,
          created_at: new Date().toISOString(),
          is_admin: false
        });

      if (createError) {
        console.error('Error creating member:', createError);
        throw new Error('Failed to create member');
      }

      setShowQR(true);
    } catch (err) {
      console.error('Error creating member:', err);
      setError('Failed to create member. Please try again.');
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    if (showQR) {
      onSuccess();
    } else {
      onClose();
    }
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black/80 backdrop-blur-sm z-[300] flex items-center justify-center p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-gray-800 rounded-2xl p-6 max-w-md w-full relative"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
      >
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 rounded-xl bg-blue-500/20">
              {showQR ? (
                <QrCode size={20} className="text-blue-400" />
              ) : (
                <UserPlus size={20} className="text-blue-400" />
              )}
            </div>
            <h3 className="text-xl font-medium">
              {showQR ? 'Member QR Code' : 'Create New Member'}
            </h3>
          </div>
          <button
            onClick={handleClose}
            className="p-2 hover:bg-gray-700 rounded-lg transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {showQR ? (
          <div className="text-center space-y-6">
            <div className="bg-white p-4 rounded-xl inline-block mx-auto">
              <QRCodeSVG 
                value={`Username is '${username}' and Password is '${passcode}'`}
                size={200}
                level="H"
                includeMargin={true}
              />
            </div>
            <p className="text-sm text-gray-400">
              Scan this QR code to get the login credentials
            </p>
            <motion.button
              className="w-full px-4 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg text-sm font-medium transition-colors"
              onClick={handleClose}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Done
            </motion.button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-400 mb-1">
                Username
              </label>
              <div className="relative">
                <input
                  id="username"
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                  className="w-full px-4 py-3 bg-gray-700/50 rounded-lg text-white placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter username"
                  autoFocus
                />
                {isCheckingUsername && (
                  <div className="absolute right-3 top-1/2 -translate-y-1/2">
                    <Loader2 size={16} className="animate-spin text-gray-400" />
                  </div>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="passcode" className="block text-sm font-medium text-gray-400 mb-1">
                Passcode
              </label>
              <input
                id="passcode"
                type="text"
                inputMode="numeric"
                pattern="\d*"
                maxLength={4}
                value={passcode}
                onChange={handlePasscodeChange}
                className="w-full px-4 py-3 bg-gray-700/50 rounded-lg text-white placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter 4-digit passcode"
              />
              <p className="text-xs text-gray-500 mt-1">
                Enter exactly 4 digits (e.g., 1234)
              </p>
            </div>

            {error && (
              <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3">
                <p className="text-sm text-red-500">{error}</p>
              </div>
            )}

            <div className="flex gap-3 pt-2">
              <motion.button
                type="button"
                onClick={onClose}
                className="flex-1 px-4 py-3 bg-gray-700 hover:bg-gray-600 rounded-lg text-sm font-medium transition-colors"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={isSubmitting}
              >
                Cancel
              </motion.button>
              <motion.button
                type="submit"
                className="flex-1 px-4 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg text-sm font-medium transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={isSubmitting || !username.trim() || passcode.length !== 4 || Boolean(error)}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 size={16} className="animate-spin" />
                    <span>Creating...</span>
                  </>
                ) : (
                  <>
                    <UserPlus size={16} />
                    <span>Create Member</span>
                  </>
                )}
              </motion.button>
            </div>
          </form>
        )}
      </motion.div>
    </motion.div>
  );
}