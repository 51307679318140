import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { type CareerSection, type CareerModule, option3Content } from '@/data/option3-content';
import { useState } from 'react';
import { useChatStore } from '@/store/chat-store';

export function Option3Menu() {
  const [selectedSection, setSelectedSection] = useState<CareerSection | null>(null);
  const [selectedModule, setSelectedModule] = useState<CareerModule | null>(null);
  const { showOption3Menu, setShowOption3Menu } = useChatStore();

  const handleBack = () => {
    if (selectedModule) {
      setSelectedModule(null);
    } else if (selectedSection) {
      setSelectedSection(null);
    } else {
      setShowOption3Menu(false);
    }
  };

  const getTitle = () => {
    if (selectedModule) return selectedModule.title;
    if (selectedSection) return selectedSection.title;
    return 'Career Planning';
  };

  return (
    <AnimatePresence>
      {showOption3Menu && (
        <motion.div
          className="fixed inset-0 bg-black/80 backdrop-blur-sm z-[400] flex items-center justify-center p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-gray-900/50 backdrop-blur-md rounded-2xl w-full max-w-md overflow-hidden border border-white/10"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
          >
            {/* Header */}
            <div className="p-4 border-b border-white/10 flex items-center justify-between bg-gray-900/30">
              <div className="flex items-center gap-3">
                <motion.button
                  className={`p-2 hover:bg-white/10 rounded-xl transition-colors ${
                    !selectedSection ? 'opacity-0 pointer-events-none' : ''
                  }`}
                  onClick={handleBack}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <X size={20} className="rotate-45" />
                </motion.button>
                <h3 className="text-xl font-medium">{getTitle()}</h3>
              </div>
              <motion.button
                onClick={() => setShowOption3Menu(false)}
                className="p-2 hover:bg-white/10 rounded-xl transition-colors"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <X size={20} />
              </motion.button>
            </div>

            {/* Content */}
            <div className="p-4">
              <AnimatePresence mode="wait">
                {selectedModule ? (
                  <motion.div
                    key="resources"
                    className="space-y-3"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                  >
                    {selectedModule.resources.map((resource) => (
                      <motion.button
                        key={resource.id}
                        className="w-full p-4 rounded-xl bg-white/5 hover:bg-white/10 transition-colors text-left backdrop-blur-sm border border-white/5"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <div className="flex items-center justify-between mb-1">
                          <h4 className="font-medium">{resource.title}</h4>
                          {resource.duration && (
                            <span className="text-sm text-gray-400">{resource.duration}</span>
                          )}
                        </div>
                        <p className="text-sm text-gray-400">{resource.description}</p>
                        <div className="mt-2">
                          <span className="text-xs bg-white/10 px-2 py-1 rounded-full">
                            {resource.type}
                          </span>
                        </div>
                      </motion.button>
                    ))}
                  </motion.div>
                ) : selectedSection ? (
                  <motion.div
                    key="modules"
                    className="space-y-3"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                  >
                    {selectedSection.modules.map((module) => (
                      <motion.button
                        key={module.id}
                        className="w-full p-4 rounded-xl bg-white/5 hover:bg-white/10 transition-colors flex items-center gap-3 backdrop-blur-sm border border-white/5"
                        onClick={() => setSelectedModule(module)}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <div className={`p-2 rounded-lg ${module.color}`}>
                          <module.icon size={20} className="text-white" />
                        </div>
                        <div className="text-left">
                          <h4 className="font-medium">{module.title}</h4>
                          <p className="text-sm text-gray-400">{module.description}</p>
                        </div>
                      </motion.button>
                    ))}
                  </motion.div>
                ) : (
                  <motion.div
                    key="sections"
                    className="space-y-3"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                  >
                    {option3Content.map((section) => (
                      <motion.button
                        key={section.id}
                        className="w-full p-4 rounded-xl bg-white/5 hover:bg-white/10 transition-colors flex items-center gap-3 backdrop-blur-sm border border-white/5"
                        onClick={() => setSelectedSection(section)}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <div className={`p-2 rounded-lg ${section.color}`}>
                          <section.icon size={20} className="text-white" />
                        </div>
                        <div className="text-left">
                          <h4 className="font-medium">{section.title}</h4>
                          <p className="text-sm text-gray-400">{section.description}</p>
                        </div>
                      </motion.button>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}