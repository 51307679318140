import { create } from 'zustand';
import { openDB, type IDBPDatabase } from 'idb';

const DB_NAME = 'tams-hub-db';
const STORE_NAME = 'gameState';

interface GameState {
  selectedGame: string | null;
  gameState: 'playing' | 'won' | 'draw' | 'countdown' | null;
  winner: string | null;
  countdown: number;
  saveState: (state: any) => Promise<void>;
  loadState: () => Promise<any>;
  setSelectedGame: (game: string | null) => void;
  setGameState: (state: 'playing' | 'won' | 'draw' | 'countdown' | null) => void;
  setWinner: (winner: string | null) => void;
  setCountdown: (count: number) => void;
  clearSelection: () => void;
  restartGame: () => void;
}

// Helper function to get database connection
const getDB = async (): Promise<IDBPDatabase> => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      // Create the store if it doesn't exist
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
};
// Add memoization for game state updates
const memoizedState = new Map();

export const useGameState = create<GameState>((set) => ({
  selectedGame: null,
  gameState: null,
  winner: null,
  countdown: 5,

  saveState: async (state) => {
    try {
      // Check if state has changed
      const stateKey = JSON.stringify(state);
      if (memoizedState.get(stateKey)) {
        return;
      }
      memoizedState.set(stateKey, true);

      const db = await getDB();
      await db.put(STORE_NAME, state, 'currentGame');
    } catch (err) {
      console.error('Failed to save game state:', err);
    }
  },

  loadState: async () => {
    try {
      // Check cache first
      const cachedState = memoizedState.get('currentGame');
      if (cachedState) {
        return cachedState;
      }

      const db = await getDB();
      const state = await db.get(STORE_NAME, 'currentGame');
      memoizedState.set('currentGame', state);
      return state;
    } catch (err) {
      console.error('Failed to load game state:', err);
      return null;
    }
  },

  setSelectedGame: (game) => set({ selectedGame: game }),
  setGameState: (state) => set({ gameState: state }),
  setWinner: (winner) => set({ winner }),
  setCountdown: (count) => set({ countdown: count }),
  clearSelection: () => set({ selectedGame: null, gameState: null, winner: null, countdown: 5 }),
  restartGame: () => set({ gameState: 'playing', winner: null, countdown: 5 })
}));