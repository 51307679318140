export interface MemoryQuestion {
  id: string;
  question: string;
  options: {
    text: string;
    value: string;
  }[];
  correctAnswer: string;
  difficulty: 'easy' | 'medium' | 'hard';
}

export const memoryQuestions: MemoryQuestion[] = [
  {
    id: 'mem-e1',
    question: 'What should you wear to an interview?',
    options: [
      { text: 'Casual clothes like jeans and a hoodie', value: 'A' },
      { text: 'Whatever you feel like wearing', value: 'B' },
      { text: 'Professional and job-appropriate attire', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'easy'
  },
  {
    id: 'mem-e2',
    question: 'What time should you arrive for an interview?',
    options: [
      { text: '10–15 minutes early', value: 'A' },
      { text: 'Right on time', value: 'B' },
      { text: '30 minutes late', value: 'C' }
    ],
    correctAnswer: 'A',
    difficulty: 'easy'
  },
  {
    id: 'mem-e3',
    question: 'What is the most common question asked in an interview?',
    options: [
      { text: 'Do you like movies?', value: 'A' },
      { text: 'Tell me about yourself', value: 'B' },
      { text: 'What is your favourite food?', value: 'C' }
    ],
    correctAnswer: 'B',
    difficulty: 'easy'
  },
  {
    id: 'mem-e4',
    question: 'What should you bring to an interview?',
    options: [
      { text: 'A copy of your resume and references', value: 'A' },
      { text: 'A coffee and a snack', value: 'B' },
      { text: 'Nothing, just yourself', value: 'C' }
    ],
    correctAnswer: 'A',
    difficulty: 'easy'
  },
  {
    id: 'mem-e5',
    question: 'How should you greet the interviewer?',
    options: [
      { text: 'By giving a high five', value: 'A' },
      { text: 'By looking away and mumbling', value: 'B' },
      { text: 'With a firm handshake and eye contact', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'easy'
  },
  {
    id: 'mem-e6',
    question: 'What should you do with your phone during an interview?',
    options: [
      { text: 'Take a selfie with the interviewer', value: 'A' },
      { text: 'Keep checking it', value: 'B' },
      { text: 'Turn it off or set it to silent', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'easy'
  },
  {
    id: 'mem-m1',
    question: 'How should you respond if asked about weaknesses?',
    options: [
      { text: 'Say you have none', value: 'A' },
      { text: 'Share a real weakness and how you\'re improving it', value: 'B' },
      { text: 'Avoid the question', value: 'C' }
    ],
    correctAnswer: 'B',
    difficulty: 'medium'
  },
  {
    id: 'mem-m2',
    question: 'When asked, "Why should we hire you?" what should you say?',
    options: [
      { text: 'Because I need the job', value: 'A' },
      { text: 'I don\'t know', value: 'B' },
      { text: 'Explain your skills and how they match the role', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'medium'
  },
  {
    id: 'mem-m3',
    question: 'What is the best way to explain a gap in your CV?',
    options: [
      { text: 'Say it\'s none of their business', value: 'A' },
      { text: 'Avoid answering', value: 'B' },
      { text: 'Be honest and focus on productive activities', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'medium'
  },
  {
    id: 'mem-m4',
    question: 'How do you prepare for an interview?',
    options: [
      { text: 'Watch TV until the last minute', value: 'A' },
      { text: 'Research the company and practice answers', value: 'B' },
      { text: 'Go in without preparation', value: 'C' }
    ],
    correctAnswer: 'B',
    difficulty: 'medium'
  },
  {
    id: 'mem-h1',
    question: 'How do you demonstrate enthusiasm during an interview?',
    options: [
      { text: 'Avoid showing emotions', value: 'A' },
      { text: 'Show genuine interest in the role and company', value: 'B' },
      { text: 'Overexaggerate everything', value: 'C' }
    ],
    correctAnswer: 'B',
    difficulty: 'hard'
  },
  {
    id: 'mem-h2',
    question: 'What\'s the best way to describe handling a stressful situation?',
    options: [
      { text: 'Say you never feel stressed', value: 'A' },
      { text: 'Avoid the question', value: 'B' },
      { text: 'Provide a specific example and coping strategies', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'hard'
  },
  {
    id: 'mem-h3',
    question: 'What should you do if you\'re asked about a mistake at work?',
    options: [
      { text: 'Share a specific mistake and what you learned', value: 'A' },
      { text: 'Avoid mentioning mistakes', value: 'B' },
      { text: 'Say you\'ve never made a mistake', value: 'C' }
    ],
    correctAnswer: 'A',
    difficulty: 'hard'
  },
  {
    id: 'mem-h4',
    question: 'How do you negotiate salary in an interview?',
    options: [
      { text: 'Accept whatever is offered without question', value: 'A' },
      { text: 'Say you\'ll decide later and avoid discussing it', value: 'B' },
      { text: 'Research market rates and confidently state your worth', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'hard'
  },
  {
    id: 'mem-h5',
    question: 'What is the STAR method used for?',
    options: [
      { text: 'Talking about hobbies', value: 'A' },
      { text: 'Avoiding answering difficult questions', value: 'B' },
      { text: 'Answering behavioral interview questions effectively', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'hard'
  },
  {
    id: 'mem-h6',
    question: 'What should you do if an interviewer asks an inappropriate question?',
    options: [
      { text: 'Answer anyway to avoid conflict', value: 'A' },
      { text: 'Walk out immediately without saying anything', value: 'B' },
      { text: 'Politely decline to answer and steer the conversation back to the job', value: 'C' }
    ],
    correctAnswer: 'C',
    difficulty: 'hard'
  }
];
