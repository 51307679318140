import { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { cn } from '@/lib/utils';

interface ChatOptionsProps {
  options: {
    label: string;
    value: string;
  }[];
  onSelect: (value: string) => void;
  allowCustomInput?: boolean;
}

export function ChatOptions({ options, onSelect, allowCustomInput = true }: ChatOptionsProps) {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(options.length / itemsPerPage);
  
  const currentOptions = options.slice(
    currentPage * itemsPerPage, 
    (currentPage + 1) * itemsPerPage
  );

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(0, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(totalPages - 1, prev + 1));
  };

  return (
    <motion.div 
      className="flex items-center gap-4 mb-4 max-w-[80%]"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <motion.button
        className={cn(
          "p-2 bg-gray-800 rounded-xl transition-colors",
          currentPage > 0 ? "hover:bg-gray-700" : "opacity-50 cursor-not-allowed"
        )}
        whileHover={currentPage > 0 ? { scale: 1.1 } : undefined}
        whileTap={currentPage > 0 ? { scale: 0.9 } : undefined}
        onClick={handlePrevPage}
        disabled={currentPage === 0}
      >
        <ChevronLeft size={16} />
      </motion.button>

      <div className="flex-1 flex flex-wrap gap-2">
        {currentOptions.map((option) => (
          <motion.button
            key={option.value}
            onClick={() => onSelect(option.value)}
            className={cn(
              "px-4 py-2 rounded-xl bg-gray-800 hover:bg-gray-700 transition-colors text-sm"
            )}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {option.label}
          </motion.button>
        ))}
        {allowCustomInput && (
          <motion.div 
            className="text-sm text-gray-400 w-full mt-1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.3 } }}
          >
            Or type your own response
          </motion.div>
        )}
      </div>

      <motion.button
        className={cn(
          "p-2 bg-gray-800 rounded-xl transition-colors",
          currentPage < totalPages - 1 ? "hover:bg-gray-700" : "opacity-50 cursor-not-allowed"
        )}
        whileHover={currentPage < totalPages - 1 ? { scale: 1.1 } : undefined}
        whileTap={currentPage < totalPages - 1 ? { scale: 0.9 } : undefined}
        onClick={handleNextPage}
        disabled={currentPage === totalPages - 1}
      >
        <ChevronRight size={16} />
      </motion.button>
    </motion.div>
  );
}