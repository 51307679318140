import { Search, Briefcase, MessageSquare, Mail, HelpCircle, Sparkles, Wrench, GraduationCap, Code, ArrowLeft, BookOpen } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { SourceSuggestionCard } from "./source-suggestion-card";
import { chatActions } from "@/store/chat-actions"; 
import { useMobileTabs } from "@/hooks/use-mobile-tabs";
import { useActivitiesHighlight } from "@/hooks/use-activities-highlight";
import { useAuth } from "@/hooks/use-auth";
import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useChatStore } from "@/store/chat-store";

const mainActions = [ 
  // Page 1
  // {
  //   icon: Search,
  //   title: "Search Jobs",
  //   description: "Find jobs matching your skills and experience",
  //   action: chatActions.handleSearchJobs,
  //   disabled: true,
  //   page: 1
  // },
  {
    icon: Briefcase,
    title: "Explore Jobs",
    description: "Swipe Left or Right on Potential Jobs",
    action: () => {
      // Ensure we're not in a transitioning state
      const store = useChatStore.getState();
      if (!store.showJobExploration) {
        chatActions.handleExploreJob();
      }
    },
    disabled: false,
    page: 1
  },
  {
    icon: Briefcase,
    title: "Explain Jobs",
    description: "Explain a Job with Analogies",
    action: () => {
      // Ensure we're not in a transitioning state
  
      // Ask for a character, show, movie, or subject they like
      const topic = prompt("What's a Character / Show / Movie / Subject you enjoy?");
  
      // If the user cancels or leaves it empty, stop execution
      if (!topic) return;
  
      // Ask for a job
      const job = prompt("Enter a job you want explained:");
  
      // If the user cancels or leaves it empty, stop execution
      if (!job) return;
  
      // Construct the query with an expanded prompt for clarity and simplicity
      const query = `Write a short, simple explanation of what a ${job} does, using ${topic} as an analogy. Use short sentences. Keep it easy to read. Focus on the main tasks, like key responsibilities and customer interaction. Make it fun and clear. Imagine explaining it to someone who is new to work and might not be great at reading. Avoid big words. Keep it under 4 sentences.`;
  
      // Open ChatGPT with the refined query
      const url = `https://chatgpt.com/?q=${encodeURIComponent(query)}`;
  
      window.open(url, "_blank");
  },
  
    disabled: false,
    page: 1
},

{
  icon: Briefcase,
  title: "Research Jobs",
  description: "Get detailed information about specific jobs",
  action: () => {
    // Get the current year
    const currentYear = new Date().getFullYear();

    // Ask the user for a job title
    const jobTitle = prompt("Enter a job title to research:");

    // If the user entered a job title, open ChatGPT with the query
    if (jobTitle) {
      const query = encodeURIComponent(
        `In New Zealand, preferably Hawke's Bay, what are some stats you can give me -- a Jobseeker -- for the job '${jobTitle}'? If you can find stats for ${currentYear}, that would be ideal, but if not, any stat is greatly appreciated. 
        For vacancy statistics, please use Seek.co.nz as the source. Additionally, if available, break down the vacancies into Napier in Hawke's Bay and Hastings in Hawke's Bay separately.`
      );
      const chatGPTUrl = `https://chatgpt.com/?hints=search&q=${query}`;
      
      // Open in a new tab
      window.open(chatGPTUrl, "_blank");
    }

    // Ensure we're not in a transitioning state
 
  },
  disabled: false,
  page: 1
},



  {
    icon: Briefcase,
    title: "Study a Subject",
    description: "Study a Subject with NotebookLM.",
    action: () => {
        // Ensure we're not in a transitioning state
       

        // Open NotebookLM in a new tab
        window.open("https://notebooklm.google.com/", "_blank");
    },
    disabled: false,
    page: 1
},

  // {
  //   icon: Code,
  //   title: "Create Project",
  //   description: "Start a new project from scratch",
  //   action: chatActions.handleCreateProject,
  //   disabled: true,
  //   page: 1
  // },
  // {
  //   icon: MessageSquare,
  //   title: "Start Activity",
  //   description: "Begin interactive learning activities",
  //   action: chatActions.handleStartActivity,
  //   disabled: true,
  //   page: 1
  // },
  // Page 2
  {
    icon: Sparkles,
    title: "Summarise",
    description: "Get concise summaries of any text",
    action: chatActions.handleSummarise,
    disabled: false,
    page: 2
  },
  {
    icon: Mail,
    title: "Write Email",
    description: "Generate professional emails",
    action: chatActions.handleEmail,
    disabled: false,
    page: 2
  },
  {
    icon: HelpCircle,
    title: "Spelling",
    description: "Check and correct spelling",
    action: chatActions.handleSpelling,
    disabled: false,
    page: 2
  },
  {
    icon: Wrench,
    title: "Explain",
    description: "Get detailed explanations",
    action: chatActions.handleExplain,
    disabled: false,
    page: 2
  },
  // Page 3 - Course Content
  {
    icon: BookOpen,
    title: "WEEK 1",
    description: "WEEK 1 OF TAMS",
    action: () => {
      const store = useChatStore.getState();
      store.setShowCourseMenu(true);
    },
    disabled: false,
    page: 3
  },
  {
    icon: GraduationCap,
    title: "WEEK 2",
    description: "WEEK 2 OF TAMS",
    action: () => {
      const store = useChatStore.getState();
      store.setShowOption2Menu(true);
    },
    disabled: true,
    page: 3
  },
  {
    icon: Briefcase,
    title: "WEEK 3",
    description: "WEEK 3 OF TAMS",
    action: () => {
      const store = useChatStore.getState();
      store.setShowOption3Menu(true);
    },
    disabled: true,
    page: 3
  },
  {
    icon: Sparkles,
    title: "WEEK 4",
    description: "WEEK 4 OF TAMS",
    action: () => {
      const store = useChatStore.getState();
      store.setShowOption4Menu(true);
    },
    disabled: true,
    page: 3
  },
  {
    icon: Sparkles,
    title: "WEEK 5",
    description: "WEEK 5 OF TAMS",
    action: () => {
      const store = useChatStore.getState();
      store.setShowOption4Menu(true);
    },
    disabled: true,
    page: 3
  }
];

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

export function SourceSuggestions() {
  const { activeTab } = useMobileTabs();
  const { isHighlighted } = useActivitiesHighlight();
  const { userName } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const { messageHandler, clearMessages } = useChatStore();

  const currentPageActions = mainActions.filter(action => action.page === currentPage);

  const handleBack = () => {
    if (messageHandler) {
      clearMessages();
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto px-2 relative pt-16">
      {messageHandler && (
        <div className="absolute top-0 left-0 right-0 p-4 border-b border-gray-800">
          <motion.button
            className="p-2 hover:bg-gray-800 rounded-lg transition-colors flex items-center gap-2 text-gray-400 hover:text-white"
            onClick={handleBack}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <ArrowLeft size={20} />
            <span>Back</span>
          </motion.button>
        </div>
      )}

      <motion.h3
        className="text-lg font-medium text-white mb-6 text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        KIA ORA, {userName?.toUpperCase() || 'Guest'}!
        <p className="text-sm text-gray-400 mt-2">What would you like to do today?</p>
      </motion.h3>

      <div className="flex items-center justify-between mb-4">
        <motion.button
          className={`p-2 rounded-lg ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-800'}`}
          onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
          whileHover={currentPage !== 1 ? { scale: 1.1 } : undefined}
          whileTap={currentPage !== 1 ? { scale: 0.9 } : undefined}
        >
          <ChevronLeft size={20} />
        </motion.button>
        <span className="text-sm text-gray-400">Page {currentPage} of 3</span>
        <motion.button
          className={`p-2 rounded-lg ${currentPage === 3 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-800'}`}
          onClick={() => setCurrentPage(prev => Math.min(3, prev + 1))}
          disabled={currentPage === 3}
          whileHover={currentPage !== 3 ? { scale: 1.1 } : undefined}
          whileTap={currentPage !== 3 ? { scale: 0.9 } : undefined}
        >
          <ChevronRight size={20} />
        </motion.button>
      </div>

      <motion.div 
        className="grid grid-cols-2 gap-4 mt-8"
        variants={containerVariants}
        initial="hidden"
        animate="show"
        key={currentPage}
      >
        {currentPageActions.map((option) => {
          const isActive = option.title === "Start Activity" && 
                          activeTab === 'sources' && 
                          isHighlighted;

          return (
            <motion.button 
              key={option.title} 
              variants={itemVariants}
              onClick={option.disabled ? undefined : option.action}
              className="cursor-pointer h-[140px]"
            >
              <SourceSuggestionCard
                {...option}
                disabled={option.disabled}
                isActive={isActive}
              />
            </motion.button>
          );
        })}
      </motion.div>
    </div>
  );
}

export default SourceSuggestions;