import { useEffect, useState, useCallback } from "react";
import { QuestionModal } from "../question-modal";
import { memoryQuestions, MemoryQuestion } from "@/data/memory-questions";
import { useTrophies } from "@/hooks/use-trophies";
import { useGameState } from "@/hooks/use-game-state";

interface BlockTowerFrameProps {
  onClose: () => void; // Callback function to unload component
  title?: string; // Title prop
  difficulty?: 'easy' | 'medium' | 'hard'; // Difficulty prop
  onRestart?: () => void; // onRestart prop to match GameWrapper
  sectionId?: string; // Add section ID prop
}

export default function BlockTowerFrame({
  onClose,
  title = 'Tower Blocks',
  difficulty: difficultyProp = 'easy',
  onRestart,
  sectionId
}: BlockTowerFrameProps) {
  // Log all props received from GameWrapper
  console.log("BlockTower - Title received:", title);
  console.log("BlockTower - Difficulty received:", difficultyProp);
  console.log("BlockTower - Section ID received:", sectionId);
  
  const { awardTrophy } = useTrophies();
  const { setGameState, setWinner } = useGameState();
  
  const [showQuestion, setShowQuestion] = useState(false);
  const [question, setQuestion] = useState<MemoryQuestion | null>(null);
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">(difficultyProp);
  
  // Update difficulty state when prop changes
  useEffect(() => {
    setDifficulty(difficultyProp);
  }, [difficultyProp]);
  
  useEffect(() => {
    function checkStorage() {
      const gameData = localStorage.getItem("stackGameResult");
      if (gameData) {
        const { result, score } = JSON.parse(gameData);
        console.log("Stack Game Result:", result);
        console.log("Stack Game Score:", score);
        console.log("Stack Game Result for Section:", sectionId);
        
        if (result === "lost") {
          setQuestion(getRandomQuestion());
          setShowQuestion(true);
        } else {
          setGameState("won");
          setWinner("You");
        }
        
        localStorage.removeItem("stackGameResult");
      }
    }
    
    const interval = setInterval(checkStorage, 1000);
    
    function handleMessage(event: MessageEvent) {
      if (event.data?.type === "closeGame") {
        onClose(); // Close the BlockTower component when receiving the message
      }
    }
    
    window.addEventListener("message", handleMessage);
    return () => {
      clearInterval(interval);
      window.removeEventListener("message", handleMessage);
    };
  }, [setGameState, setWinner, onClose, sectionId]);

  const handleQuestionAnswer = useCallback(async (correct: boolean) => {
    if (correct) {
      const trophyType = {
        easy: "bronze",
        medium: "silver",
        hard: "gold",
      }[difficulty] as "bronze" | "silver" | "gold";
      
      // Include section info when awarding trophy
      await awardTrophy(trophyType);
      setGameState("won");
      setWinner("You");
      console.log(`Trophy awarded (${trophyType}) for section: ${sectionId}`);
    } else {
      setGameState("won");
      setWinner("Computer");
      console.log(`Game lost for section: ${sectionId}`);
    }
    setShowQuestion(false);
  }, [difficulty, awardTrophy, setGameState, setWinner, sectionId]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999,
      }}
    >
      <iframe
        src={`/TB/index.html?difficulty=${difficulty}&section=${sectionId || ''}`}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title={title}
        sandbox="allow-scripts allow-same-origin"
      />
      
      {showQuestion && question && (
        <QuestionModal 
          question={question} 
          onAnswer={handleQuestionAnswer} 
          title={title}
          difficulty={difficulty}
          sectionId={sectionId}
        />
      )}
    </div>
  );
}

// Function to fetch a random question
function getRandomQuestion(): MemoryQuestion {
  return memoryQuestions[Math.floor(Math.random() * memoryQuestions.length)];
}