import { DivideIcon as LucideIcon } from "lucide-react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

interface SourceSuggestionCardProps {
  title: string;
  description: string;
  icon: LucideIcon;
  disabled?: boolean;
  isActive?: boolean;
}

export function SourceSuggestionCard({ 
  title, 
  description, 
  icon: Icon, 
  disabled,
  isActive
}: SourceSuggestionCardProps) {
  return (
    <div
      className={cn(
        "flex flex-col items-start p-4 rounded-xl transition-colors text-left w-full h-full",
        disabled 
          ? "bg-gray-800/20 cursor-not-allowed opacity-50" 
          : "bg-gray-800/50 backdrop-blur-sm hover:bg-gray-800",
        isActive && "ring-2 ring-blue-500"
      )}
    >
      <div className="bg-gray-700 p-2.5 rounded-lg mb-3">
        <Icon size={18} className={cn("text-blue-400", isActive && "text-blue-500")} />
      </div>
      <div className="flex-1">
        <h4 className="text-sm font-medium text-white mb-1.5 line-clamp-1">{title}</h4>
        <p className="text-xs text-gray-400 line-clamp-2">{description}</p>
      </div>
    </div>
  );
}