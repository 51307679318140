import { DivideIcon as LucideIcon, LayoutGrid } from "lucide-react";
import { cn } from "@/lib/utils";

interface PanelHeaderProps {
  title: string;
  icon?: LucideIcon;
  className?: string;
  children?: React.ReactNode;
}

export function PanelHeader({ title, icon: Icon = LayoutGrid, className, children }: PanelHeaderProps) {
  return (
    <div className={cn("h-[50px] flex items-center justify-between px-4 border-b border-gray-800", className)}>
      <div className="flex items-center gap-3">
        <h2 className="text-base font-normal leading-none">{title}</h2>
      </div>
      <div className="flex items-center gap-2">
        {children}
      </div>
    </div>
  );
}