import { create } from 'zustand';
import { createClient } from '@supabase/supabase-js';
import { useAuth } from './use-auth';

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

interface TrophyState {
  bronzeTrophies: number;
  silverTrophies: number;
  goldTrophies: number;
  incorrectAnswers: number;
  isLoading: boolean;
  fetchTrophies: () => Promise<void>;
  awardTrophy: (type: 'bronze' | 'silver' | 'gold') => Promise<void>;
  incrementIncorrectAnswers: () => Promise<void>;
}

export const useTrophies = create<TrophyState>((set, get) => ({
  bronzeTrophies: 0,
  silverTrophies: 0,
  goldTrophies: 0,
  incorrectAnswers: 0,
  isLoading: true,

  fetchTrophies: async () => {
    const { codeId } = useAuth.getState();
    if (!codeId) return;
    
    try {
      // Add small delay to ensure Supabase is ready
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const { data, error } = await supabase
        .from('login_codes')
        .select('bronze_trophies, silver_trophies, gold_trophies, incorrect_answers')
        .eq('id', codeId)
        .single();

      if (error) {
        if (error.message?.includes('Failed to fetch')) {
          console.warn('Please connect to Supabase to fetch trophies');
        } else {
          console.error('Error fetching trophies:', error);
        }
        set({ isLoading: false });
        return;
      }

      set({
        bronzeTrophies: data.bronze_trophies || 0,
        silverTrophies: data.silver_trophies || 0,
        goldTrophies: data.gold_trophies || 0,
        incorrectAnswers: data.incorrect_answers || 0,
        isLoading: false
      });
    } catch (err) {
      console.error('Error fetching trophies:', err);
      set({ isLoading: false });
    }
  },

  incrementIncorrectAnswers: async () => {
    const { codeId } = useAuth.getState();
    if (!codeId) return;

    const currentValue = get().incorrectAnswers;

    try {
      const { error } = await supabase
        .from('login_codes')
        .update({ incorrect_answers: currentValue + 1 })
        .eq('id', codeId);

      if (error) throw error;

      set({ incorrectAnswers: currentValue + 1 });
    } catch (err) {
      console.error('Error incrementing incorrect answers:', err);
    }
  },
  awardTrophy: async (type) => {
    const { codeId } = useAuth.getState();
    if (!codeId) return;

    const columnMap = {
      bronze: 'bronze_trophies',
      silver: 'silver_trophies',
      gold: 'gold_trophies'
    };

    const column = columnMap[type];
    const currentValue = get()[`${type}Trophies`];

    try {
      const { error } = await supabase
        .from('login_codes')
        .update({ [column]: currentValue + 1 })
        .eq('id', codeId);

      if (error) throw error;

      set({ [`${type}Trophies`]: currentValue + 1 });
    } catch (err) {
      console.error('Error awarding trophy:', err);
    }
  }
}));