import { Gamepad2, Dices, Brain, Zap, Trophy } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

export interface GameGenre {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
}
export interface Game {
  id: string;
  title: string;
  description: string;
  color: string;
  genreId: string;
  comingSoon?: boolean;
  disabled?: boolean; // Optional disabled flag
}


export const genres: GameGenre[] = [
  {
    id: 'classic',
    title: 'Classic',
    description: 'Timeless favoruites',
    icon: Dices,
    color: 'from-blue-500/20 to-purple-500/20'
  },
  {
    id: 'arcade',
    title: 'Arcade',
    description: 'Fast-paced action games',
    icon: Zap,
    color: 'from-orange-500/20 to-red-500/20'
  },
  {
    id: 'experiment',
    title: 'Experiment',
    description: 'Experimental games',
    icon: Brain,
    color: 'from-green-500/20 to-teal-500/20'
  }
];

export const games: Game[] = [
  {
    id: 'tic-tac-toe',
    title: 'Tic Tac Toe',
    description: "Classic Game",
    color: 'from-blue-500/20 to-purple-500/20',
    genreId: 'classic'
  },
  {
    id: 'towerblocks',
    title: 'Tower Blocks',
    description: "Tower Blocks",
    color: 'from-blue-500/20 to-purple-500/20',
    genreId: 'arcade'
  },
  {
    id: 'stickhero',
    title: 'Stick Hero',
    description: "Stick Hero",
    color: 'from-blue-500/20 to-purple-500/20',
    genreId: 'arcade'
  },
  {
    id: 'crossy-roads',
    title: 'Crossy Roads',
    description: "Crossy Roads",
    color: 'from-blue-500/20 to-purple-500/20',
    genreId: 'arcade'
  },
  {
    id: 'snowboard',
    title: 'Snowboard',
    description: "Snowboard",
    color: 'from-blue-500/20 to-purple-500/20',
    genreId: 'experiment'
  },
  {
    id: 'SquidGame',
    title: 'Squid Game',
    description: "Red light, green light!",
    color: 'from-blue-500/20 to-purple-500/20',
    genreId: 'experiment'
  },
  {
    id: 'Minecraft',
    title: 'Minecraft',
    description: "Build and answer a question every 30 seconds.",
    color: 'from-blue-500/20 to-purple-500/20',
    disabled: false,
    genreId: 'experiment'
  },



  // {
  //   id: 'BlockNinja',
  //   title: 'Block Ninja',
  //   description: "NEW: Block Ninja",
  //   color: 'from-blue-500/20 to-purple-500/20',
  //   disabled: false,
  //   genreId: 'experiment'
  // },
  // {
  //   id: 'Escape2D',
  //   title: 'Escape',
  //   description: "NEW: Escape game",
  //   color: 'from-blue-500/20 to-purple-500/20',
  //   disabled: false,
  //   genreId: 'experiment'
  // },
  {
    id: 'Slither',
    title: 'Slither',
    description: "NEW: Slither game",
    color: 'from-blue-500/20 to-purple-500/20',
    disabled: false,
    genreId: 'experiment'
  },



  {
    id: 'FNAF',
    title: 'FNAF',
    description: "Five Nights at Freddy's. Coming soon and will unlock with a specific amount of gold trophies.",
    color: 'from-blue-500/20 to-purple-500/20',
    disabled: true,
    genreId: 'experiment'
  },
 
  {
    id: 'GeoGuesser',
    title: 'Geo Guesser',
    description: "Geo Guesser. Coming soon and will unlock with a specific amount of gold trophies.",
    color: 'from-blue-500/20 to-purple-500/20',
    disabled: true,
    genreId: 'experiment'
  }
  // {
  //   id: 'simon',
  //   title: 'Simon',
  //   description: "Test your memory skills",
  //   color: 'from-purple-500/20 to-pink-500/20',
  //   genreId: 'arcade'
  // },
  // {
  //   id: 'objects',
  //   title: 'Objects',
  //   description: "Find objects using your camera",
  //   color: 'from-green-500/20 to-teal-500/20',
  //   genreId: 'experiment'
  // },
  // {
  //   id: 'hoops',
  //   title: 'Hoops',
  //   description: "Test your basketball skills",
  //   color: 'from-orange-500/20 to-red-500/20',
  //   genreId: 'experiment'
  // },
  // {
  //   id: 'Stars',
  //   title: 'Bots, dots and stars',
  //   description: "Collect stars fast enough",
  //   color: 'from-orange-500/20 to-red-500/20',
  //   genreId: 'experiment'
  // },
  // {
  //   id: 'rps',
  //   title: 'Rock Paper Scissors',
  //   description: "Classic hand game of strategy",
  //   color: 'from-blue-500/20 to-purple-500/20',
  //   genreId: 'classic'
  // }
];