import { Trophy, X } from 'lucide-react';
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '@/hooks/use-auth';
import { useGameState } from '@/hooks/use-game-state';
import { useTrophies } from '@/hooks/use-trophies';
import { TrophyStats } from './trophy-stats';

const trophies = [
  { color: 'text-amber-500', label: 'Gold' },
  { color: 'text-gray-400', label: 'Silver' },
  { color: 'text-amber-700', label: 'Bronze' },
  { color: 'text-red-500', label: 'Incorrect', icon: X }
];

export function ActivitiesHeader() {
  const [showTrophyStats, setShowTrophyStats] = useState(false);
  const { logout } = useAuth();
  const { selectedGame, gameState, winner, countdown } = useGameState();
  const { bronzeTrophies, silverTrophies, goldTrophies, incorrectAnswers, fetchTrophies } = useTrophies();

  useEffect(() => {
    fetchTrophies();
  }, [fetchTrophies]);

  return (
    <>
      <div className="h-[50px] flex items-center justify-between px-4 border-b border-gray-800">
        <h2 className="text-base font-normal leading-none">Activities</h2>
        <div className="flex items-center gap-2">
          <motion.button
            onClick={() => setShowTrophyStats(true)}
            className="flex items-center gap-3 mr-2 px-3 py-1.5 rounded-lg hover:bg-gray-800 transition-colors group"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {trophies.map((trophy, index) => (
              <motion.div
                key={trophy.label}
                className="flex items-center gap-0.5"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -2 }}
              >
                {trophy.icon ? (
                  <trophy.icon size={14} className={`${trophy.color} transition-transform group-hover:scale-110`} />
                ) : (
                  <Trophy size={14} className={`${trophy.color} transition-transform group-hover:scale-110`} />
                )}
                <span className="text-sm">
                  {index === 0 ? goldTrophies : 
                    index === 1 ? silverTrophies : 
                    index === 2 ? bronzeTrophies :
                    incorrectAnswers}
                </span>
              </motion.div>
            ))}
          </motion.button>
        </div>
      </div>

      <TrophyStats 
        isOpen={showTrophyStats}
        onClose={() => setShowTrophyStats(false)}
      />
    </>
  );
}