import { useEffect, useRef, useState, useCallback } from 'react';

// Audio context singleton for cross-platform compatibility
let audioContext: AudioContext | null = null;

export function useAudio(url: string) {
  const audio = useRef<HTMLAudioElement>();
  const isLoaded = useRef(false);
  const [isLoadedState, setIsLoadedState] = useState(false);
  const source = useRef<AudioBufferSourceNode | null>(null);
  const gainNode = useRef<GainNode | null>(null);
  const buffer = useRef<AudioBuffer | null>(null);
  const isPlaying = useRef(false);

  useEffect(() => {
    // Load audio file
    const loadAudio = async () => {
      try {
        // Lazy initialize audio context
        if (!audioContext) {
          audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        }

        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        buffer.current = await audioContext.decodeAudioData(arrayBuffer);
        setIsLoadedState(true);
        isLoaded.current = true;
      } catch (err) {
        console.warn('Failed to load audio:', err);
      }
    };

    // Load audio immediately
    loadAudio();
    
    return () => {
      if (source.current) {
        source.current.stop();
        source.current.disconnect();
      }
      if (gainNode.current) {
        gainNode.current.disconnect();
      }
      isLoaded.current = false;
      buffer.current = null;
      isPlaying.current = false;
    };
  }, [url]);

  const play = useCallback(async () => {
    if (!audioContext || !buffer.current || !isLoaded.current) return;
    
    // Don't start new playback if already playing
    if (isPlaying.current) return;

    try {
      // Resume audio context if suspended
      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      // Stop any currently playing sound
      if (source.current) {
        source.current.stop();
        source.current.disconnect();
      }

      // Create new source and gain nodes
      source.current = audioContext.createBufferSource();
      gainNode.current = audioContext.createGain();
      
      // Connect nodes
      source.current.buffer = buffer.current;
      source.current.connect(gainNode.current);
      gainNode.current.connect(audioContext.destination);
      
      // Set volume
      gainNode.current.gain.value = 1;
      
      // Set playing flag
      isPlaying.current = true;
      
      // Play sound
      source.current.start(0);
      
      // Clear playing flag when sound ends
      source.current.onended = () => {
        isPlaying.current = false;
      };
    } catch (err) {
      console.warn('Audio playback failed:', err);
      isPlaying.current = false;
    }
  }, []);

  return { play, isLoaded: isLoadedState };
}