import { useState, useEffect } from 'react';

// Global image cache
const imageCache = new Map<string, boolean>();

export function useImagePreloader(imageUrl: string) {
  const [isLoaded, setIsLoaded] = useState(imageCache.get(imageUrl) || false);

  useEffect(() => {
    if (imageCache.has(imageUrl)) {
      setIsLoaded(true);
      return;
    }

    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      imageCache.set(imageUrl, true);
      setIsLoaded(true);
    };
  }, [imageUrl]);

  return isLoaded;
}