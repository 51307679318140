import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { useChatStore } from '@/store/chat-store';
import styled from 'styled-components';

// Import fonts
const FontImport = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Heebo:900&display=swap');
`;

// Styled components for the featured content
const FeaturedContent = styled.section`
  position: relative;
`;

const Feature = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--yellow);
  
  @media (min-width: 800px) {
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
    height: 100vh;
  }
  
  &:nth-child(2n) {
    background-color: var(--pink);
  }
  
  &:nth-child(3n) {
    background-color: var(--white);
  }
  
  &:nth-child(odd) {
    @media (min-width: 800px) {
      .feature-inner {
        flex-direction: row-reverse;
      }
    
      .feature-image-container {
        margin-right: 4rem;
        transform: translateX(-2rem);
      }
      
      &.show-feature .feature-image-container {
        transform: translateX(0%);
      }
    }
  }
  
  &:nth-child(even) {      
    @media (min-width: 800px) {
      .feature-inner {
        flex-direction: row;
      }
    
      .feature-image-container {
        margin-left: 4rem;
        transform: translateX(2rem);
      }
      
      &.show-feature .feature-image-container {
        transform: translateX(0%);
      }
    }
  }
`;

const FeatureInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  max-width: 1450px;
  
  @media (min-width: 800px) {
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100vh;
  }
  
  > * {
    flex: 0 1 50%;
  }
`;

const FeatureImageContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  opacity: 0;
  transition:
    opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  
  .show-feature & {
    opacity: 1;
  }
  
  @media (min-width: 800px) {
    margin-top: 0;
    max-width: 70vmin;
  }
  
  img {
    position: relative;
    width: 100%;
    vertical-align: bottom;
    z-index: 2;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
`;

const FeatureCopy = styled.div`
  position: relative;
  z-index: 2;
  opacity: 0;
  transform: translateY(2rem);
  transition: 
    opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  
  .show-feature & {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FeatureHeadline = styled.h2`
  font-size: calc(1rem + 5vmin);
  font-family: var(--heading-font-family);
  letter-spacing: var(--heading-letter-spacing);
  text-transform: uppercase;
`;

const FeatureSubheadline = styled.h3`
  margin-top: 0.5rem;
  font-size: calc(1rem + 2.5vmin);
  font-family: var(--heading-font-family);
  letter-spacing: var(--heading-letter-spacing);
  text-transform: uppercase;
`;

const FeatureText = styled.p`
  margin-top: 2rem;
  font-size: calc(1rem + 0.5vmin);
  font-family: var(--font-secondary);
  line-height: 1.4;
  
  a {
    color: var(--blue);
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    padding-bottom: 0.1em;
    transition: color 0.2s ease;
    
    &:hover {
      color: #2563eb;
    }
  }
`;

const GlobalStyle = styled.div`
  --black: rgb(20, 20, 20);
  --blue: rgb(57, 32, 194);
  --yellow: rgb(241, 243, 112);
  --pink: rgb(245, 194, 191);
  --white: rgb(238, 232, 218);
  
  --color-text: var(--black);
  --color-body: var(--white);
  --font-primary: 'Heebo', sans-serif;
  --font-secondary: 'Poppins', sans-serif;
  --heading-letter-spacing: 0.025em;
  --heading-font-family: var(--font-primary);
  
  color: var(--color-text);
  font-family: var(--font-secondary);
`;

const sections = [
  {
    headline: 'WEEK 1',
    subheadline: 'INTRODUCTION',
    text: 'Welcome to the TAMS Employment Hub. This platform is designed to help you explore career opportunities and develop essential skills.',
    image: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?w=1200&q=80'
  },
  {
    headline: 'COURSE',
    subheadline: 'TECH Playground & Fun',
    text: 'Explore interactive learning modules and hands-on activities to build your technical skills and knowledge.',
    image: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?w=1200&q=80'
  },
  {
    headline: 'Career Development',
    subheadline: 'Build Your Future',
    text: 'Access resources and tools to help you plan and develop your career path. Learn about different industries and job roles.',
    image: 'https://images.unsplash.com/photo-1552664730-d307ca884978?w=1200&q=80'
  },
  {
    headline: 'Job Search',
    subheadline: 'Find Opportunities',
    text: 'Use our advanced search tools to find job opportunities that match your skills and interests.',
    image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?w=1200&q=80'
  },
  {
    headline: 'Skill Development',
    subheadline: 'Grow Your Abilities',
    text: 'Enhance your skills through interactive exercises, quizzes, and games designed to make learning engaging and effective.',
    image: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?w=1200&q=80'
  },
  {
    headline: 'Community',
    subheadline: 'Connect & Collaborate',
    text: 'Join a community of learners and professionals to share experiences, ask questions, and collaborate on projects.',
    image: 'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?w=1200&q=80'
  }
];

export function IntroductionPage() {
  const { setShowIntroduction } = useChatStore();

  useEffect(() => {
    // Set up intersection observer for animations
    const features = document.querySelectorAll('.feature');
    
    const observer = new IntersectionObserver(sections => {
      sections.forEach(section => {
        if (section.intersectionRatio > 0.4) {
          section.target.classList.add('show-feature');
        } else {
          section.target.classList.remove('show-feature');
        }
      });
    }, { threshold: 0.4 });
    
    features.forEach(feature => observer.observe(feature));
    
    return () => {
      features.forEach(feature => observer.unobserve(feature));
    };
  }, []);

  return (
    <motion.div 
      className="fixed inset-0 z-[9999] bg-black overflow-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <FontImport />
      <GlobalStyle>
        {/* Close Button */}
        <motion.button
          className="fixed top-4 right-4 z-[10000] p-2 bg-gray-800/80 hover:bg-gray-700 rounded-xl backdrop-blur-sm"
          onClick={() => setShowIntroduction(false)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <X size={24} />
        </motion.button>

        {/* Featured Content */}
        <FeaturedContent className="featured-content">
          {sections.map((section, index) => (
            <Feature key={index} className="feature">
              <FeatureInner className="feature-inner">
                <FeatureCopy className="feature-copy">
                  <FeatureHeadline className="feature-headline">{section.headline}</FeatureHeadline>
                  <FeatureSubheadline className="feature-subheadline">{section.subheadline}</FeatureSubheadline>
                  <FeatureText className="feature-text">{section.text}</FeatureText>
                </FeatureCopy>
                <FeatureImageContainer className="feature-image-container">
                  <img className="feature-image" src={section.image} alt={section.subheadline} />
                </FeatureImageContainer>
              </FeatureInner>
            </Feature>
          ))}
        </FeaturedContent>
      </GlobalStyle>
    </motion.div>
  );
}