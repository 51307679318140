import { motion } from 'framer-motion';
import { Gamepad2 } from 'lucide-react';

interface GamePickProps {
  onSelect: (difficulty: 'easy' | 'medium' | 'hard') => void;
}

export function GamePick({ onSelect }: GamePickProps) {
  const difficulties = [
    {
      id: 'easy',
      title: 'Beginner',
      description: 'Easier questions',
      color: 'from-green-500/20 to-emerald-500/20'
    },
    {
      id: 'medium',
      title: 'Intermediate',
      description: 'Medium questions',
      color: 'from-blue-500/20 to-indigo-500/20'
    },
    {
      id: 'hard',
      title: 'Expert',
      description: 'Harder questions',
      color: 'from-orange-500/20 to-red-500/20'
    }
  ];

  return (
    <div className="space-y-3">
      {difficulties.map((difficulty) => (
        <motion.button
          key={difficulty.id}
          className={`w-full p-4 rounded-xl bg-gradient-to-r ${difficulty.color} relative group overflow-hidden`}
          onClick={() => onSelect(difficulty.id as 'easy' | 'medium' | 'hard')}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <div className="absolute inset-0 bg-gray-900/90 transition-opacity group-hover:opacity-80" />
          <div className="relative flex items-center gap-3">
            <div className="p-2 rounded-lg bg-gray-800/50 flex-shrink-0">
              <Gamepad2 size={20} className="text-blue-400" />
            </div>
            <div className="flex-1 text-left">
              <h3 className="font-medium mb-1">{difficulty.title}</h3>
              <p className="text-sm text-gray-400">{difficulty.description}</p>
            </div>
          </div>
        </motion.button>
      ))}
    </div>
  );
}