export const locations = [
  { label: 'Napier', value: 'napier' },
  { label: 'Hastings', value: 'hastings' },
  { label: 'Flaxmere', value: 'flaxmere' }
];

export const jobTypes = [
  { label: 'Part-time', value: 'part-time' },
  { label: 'Full-time', value: 'full-time' }
];

export const jobFields = [
  { label: 'Construction', value: 'construction' },
  { label: 'Music', value: 'music' },
  { label: 'Teaching', value: 'teaching' },
  { label: 'Healthcare', value: 'healthcare' },
  { label: 'Hospitality', value: 'hospitality' },
  { label: 'IT & Technology', value: 'technology' },
  { label: 'Retail', value: 'retail' },
  { label: 'Administration', value: 'administration' },
  { label: 'Trade Services', value: 'trade' }
];