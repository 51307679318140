import { motion } from 'framer-motion';
import { Search } from 'lucide-react';

export function SearchingAnimation() {
  return (
    <motion.div
      className="flex flex-col items-center justify-center h-[60vh] text-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div
        className="w-16 h-16 rounded-full bg-blue-500/20 flex items-center justify-center mb-4"
        animate={{ 
          scale: [1, 1.1, 1],
          rotate: [0, 360]
        }}
        transition={{ 
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <Search size={32} className="text-blue-400" />
      </motion.div>
      <motion.h2 
        className="text-2xl font-semibold text-white mb-2"
        animate={{ 
          opacity: [1, 0.7, 1] 
        }}
        transition={{ 
          duration: 1.5,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        SEARCHING FOR JOBS...
      </motion.h2>
      <p className="text-sm text-gray-400 max-w-sm">
        Please wait while we find the best matches
      </p>
    </motion.div>
  );
}