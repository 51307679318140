import { create } from 'zustand';
import { useChatStore } from '@/store/chat-store';

type Tab = 'sources' | 'chat' | 'tasks';

interface MobileTabsStore {
  activeTab: Tab;
  setActiveTab: (tab: Tab) => void;
  isTransitioning: boolean;
}

export const useMobileTabs = create<MobileTabsStore>((set) => ({
  activeTab: 'chat',
  isTransitioning: false,
  setActiveTab: (tab) => {
    set({ isTransitioning: true });
    
    const chatStore = useChatStore.getState();
    
    if (tab !== 'tasks') {
      chatStore.setIsJobSearchActive(false);
    }
    
    if (tab === 'chat') {
      chatStore.clearMessages();
    }

    // Add a small delay to prevent UI jank during transitions
    requestAnimationFrame(() => {
      set({ activeTab: tab });
      setTimeout(() => set({ isTransitioning: false }), 300);
    });
  },
}));