import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { type CourseSection, type CourseModule, courseContent } from '@/data/course-content';
import { useState } from 'react';
import { useChatStore } from '@/store/chat-store';
import { CourseContent } from './course-content';

export function CourseMenu() {
  const [selectedSection, setSelectedSection] = useState<CourseSection | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<{title: string, content: string} | null>(null);
  const { showCourseMenu, setShowCourseMenu } = useChatStore();

  const handleBack = () => {
    if (selectedLesson) {
      setSelectedLesson(null);
    } else if (selectedSection) {
      setSelectedSection(null);
    } else {
      setShowCourseMenu(false);
    }
  };

  const getTitle = () => {
    if (selectedLesson) return selectedLesson.title;
    if (selectedSection) return selectedSection.title;
    return 'Course Content';
  };

  // Flatten modules to get all lessons for a section
  const getSectionLessons = (section: CourseSection) => {
    return section.modules.flatMap(module => module.lessons);
  };

  const handleLessonClick = (lesson: any) => {
    if (lesson.type === 'link' && lesson.url) {
      window.open(lesson.url, '_blank');
    } else {
      setSelectedLesson({
        title: lesson.title,
        content: lesson.content || `
          <p class="text-lg mb-6">
            ${lesson.description}
          </p>
          <div class="space-y-4">
            <h2 class="text-2xl font-bold">Overview</h2>
            <p>This lesson covers essential concepts and practical applications in ${lesson.title.toLowerCase()}.</p>
            
            <h3 class="text-xl font-semibold mt-8">Learning Objectives</h3>
            <ul class="list-disc pl-6 space-y-2">
              <li>Understand core principles of ${lesson.title}</li>
              <li>Apply concepts in real-world scenarios</li>
              <li>Develop practical skills through exercises</li>
            </ul>

            <h3 class="text-xl font-semibold mt-8">Key Topics</h3>
            <div class="grid gap-4 mt-4">
              <div class="p-4 rounded-xl bg-white/5">
                <h4 class="font-medium mb-2">Introduction</h4>
                <p class="text-gray-400">Foundation concepts and terminology</p>
              </div>
              <div class="p-4 rounded-xl bg-white/5">
                <h4 class="font-medium mb-2">Core Concepts</h4>
                <p class="text-gray-400">Detailed exploration of main topics</p>
              </div>
              <div class="p-4 rounded-xl bg-white/5">
                <h4 class="font-medium mb-2">Practical Application</h4>
                <p class="text-gray-400">Hands-on exercises and real-world examples</p>
              </div>
            </div>

            <h3 class="text-xl font-semibold mt-8">Duration</h3>
            <p>${lesson.duration}</p>
          </div>
        `
      });
    }
  };

  return (
    <AnimatePresence>
      {showCourseMenu && (
        <motion.div
          className="fixed inset-0 bg-black/80 backdrop-blur-sm z-[400] flex items-center justify-center p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-gray-900/50 backdrop-blur-md rounded-2xl w-full max-w-md overflow-hidden border border-white/10"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
          >
            {/* Header */}
            <div className="p-4 border-b border-white/10 flex items-center justify-between bg-gray-900/30">
              <div className="flex items-center gap-3">
                <motion.button
                  className={`p-2 hover:bg-white/10 rounded-xl transition-colors ${
                    !selectedSection ? 'opacity-0 pointer-events-none' : ''
                  }`}
                  onClick={handleBack}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <X size={20} className="rotate-45" />
                </motion.button>
                <h3 className="text-xl font-medium">{getTitle()}</h3>
              </div>
              <motion.button
                onClick={() => setShowCourseMenu(false)}
                className="p-2 hover:bg-white/10 rounded-xl transition-colors"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <X size={20} />
              </motion.button>
            </div>

            {/* Content */}
            <div className="p-4">
              <AnimatePresence mode="wait">
                {selectedSection ? (
                  <motion.div
                    key="lessons"
                    className="space-y-3"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                  >
                    {getSectionLessons(selectedSection).map((lesson) => (
                      <motion.button
                        key={lesson.id}
                        className="w-full p-4 rounded-xl bg-white/5 hover:bg-white/10 transition-colors text-left backdrop-blur-sm border border-white/5"
                        onClick={() => handleLessonClick(lesson)}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <div className="flex items-center justify-between mb-1">
                          <h4 className="font-medium">{lesson.title}</h4>
                          <span className="text-sm text-gray-400">{lesson.duration}</span>
                        </div>
                        <p className="text-sm text-gray-400">{lesson.description}</p>
                        <div className="mt-2">
                          <span className="text-xs bg-white/10 px-2 py-1 rounded-full">
                            {lesson.type}
                          </span>
                        </div>
                      </motion.button>
                    ))}
                  </motion.div>
                ) : (
                  <motion.div
                    key="sections"
                    className="space-y-3"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                  >
                    {courseContent.map((section) => (
                      <motion.button
                        key={section.id}
                        className="w-full p-4 rounded-xl bg-white/5 hover:bg-white/10 transition-colors flex items-center gap-3 backdrop-blur-sm border border-white/5"
                        onClick={() => setSelectedSection(section)}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <div className={`p-2 rounded-lg ${section.color}`}>
                          <section.icon size={20} className="text-white" />
                        </div>
                        <div className="text-left">
                          <h4 className="font-medium">{section.title}</h4>
                          <p className="text-sm text-gray-400">{section.description}</p>
                        </div>
                      </motion.button>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>

          <AnimatePresence>
            {selectedLesson && (
              <CourseContent
                title={selectedLesson.title}
                content={selectedLesson.content}
                onClose={() => setSelectedLesson(null)}
              />
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
}