import { useEffect, useState, useCallback, useRef } from "react";
import { QuestionModal } from "../question-modal";
import { memoryQuestions, MemoryQuestion } from "@/data/memory-questions";
import { useTrophies } from "@/hooks/use-trophies";
import { useGameState } from "@/hooks/use-game-state";

interface IFrameProps {
  onClose: () => void;
  title?: string;
  difficulty?: "easy" | "medium" | "hard";
  sectionId?: string;
}

export default function TamsMCFrame({
  onClose,
  title = "TamsMC",
  difficulty: difficultyProp = "easy",
  sectionId,
}: IFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [showQuestion, setShowQuestion] = useState(false);
  const [question, setQuestion] = useState<MemoryQuestion | null>(null);
  const { awardTrophy } = useTrophies();
  const { setGameState, setWinner } = useGameState();
  const lastHandledRef = useRef<number>(0);
  const gameResetInProgressRef = useRef<boolean>(false);

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data?.type === "triggerQuestion" && !showQuestion && !gameResetInProgressRef.current) {
        const now = Date.now();
        if (now - lastHandledRef.current < 2000) return;
        lastHandledRef.current = now;
        gameResetInProgressRef.current = true;
        try {
          setQuestion(getRandomQuestion());
          setShowQuestion(true);
          if (iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage({ type: "disableControls" }, "*");
          }
        } catch {
          gameResetInProgressRef.current = false;
        }
      }
    }

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        onClose();
      }
    }

    window.addEventListener("message", handleMessage);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("message", handleMessage);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showQuestion, onClose]);

  const handleQuestionAnswer = useCallback(
    async (correct: boolean) => {
      try {
        if (correct) {
          const trophyType = {
            easy: "bronze",
            medium: "silver",
            hard: "gold"
          }[difficultyProp] as "bronze" | "silver" | "gold";
          await awardTrophy(trophyType);
          setGameState("won");
          setWinner("You");
        } else {
          setGameState("won");
          setWinner("Computer");
        }
      } catch {}
      finally {
        setShowQuestion(false);
        try {
          if (iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage({ type: "resetCountdown" }, "*");
            setTimeout(() => {
              if (iframeRef.current?.contentWindow) {
                iframeRef.current.contentWindow.postMessage({ type: "enableControls" }, "*");
              }
            }, 500);
          }
        } catch {}
        gameResetInProgressRef.current = false;
      }
    },
    [difficultyProp, awardTrophy, setGameState, setWinner]
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999
      }}
    >
      <iframe
        ref={iframeRef}
        src="https://slither-snake-game.vercel.app/"
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; pointer-lock"
        sandbox="allow-scripts allow-same-origin allow-storage-access-by-user-activation allow-forms allow-pointer-lock"
      />
      {showQuestion && question && (
        <QuestionModal
          onAnswer={handleQuestionAnswer}
          difficulty={difficultyProp}
          sectionId={sectionId}
        />
      )}
    </div>
  );
}

function getRandomQuestion(): MemoryQuestion {
  return memoryQuestions[Math.floor(Math.random() * memoryQuestions.length)];
}
