import { create } from 'zustand';
import { type Job } from '@/data/jobs';
const memoizedState = new Map();
export interface SearchOption {
  label: string;
  value: string;
  isCustom?: boolean;
}
export interface Message {
  id: string;
  content: string;
  type: 'user' | 'bot';
  timestamp: Date;
  options?: SearchOption[];
  selectedOption?: string;
}
interface JobSearch {
  location?: string;
  type?: string;
  field?: string;
}
interface ChatState {
  messages: Message[];
  isSearching: boolean;
  hasResults: boolean;
  jobCount: number;
  searchResults: Job[];
  currentJobSearch: JobSearch;
  isJobSearchActive: boolean;
  searchError: string | null;
  showJobExploration: boolean;
  showProjectCreator: boolean;
  showOption2Menu: boolean;
  showOption3Menu: boolean;
  showOption4Menu: boolean;
  showCourseMenu: boolean;
  showIntroduction: boolean;
  messageHandler: ((message: string) => Promise<void>) | null;
  getActiveState: () => {
    showJobExploration: boolean;
    showProjectCreator: boolean;
    isJobSearchActive: boolean;
    showOption2Menu: boolean;
    showOption3Menu: boolean;
    showOption4Menu: boolean;
    showCourseMenu: boolean;
  };
  addMessage: (content: string, type: 'user' | 'bot', options?: SearchOption[]) => void;
  updateMessageOption: (content: string, options: SearchOption[], selectedOption: string) => void;
  setIsSearching: (value: boolean) => void;
  setHasResults: (value: boolean) => void;
  setJobCount: (count: number) => void;
  setSearchResults: (jobs: Job[]) => void;
  setSearchError: (error: string | null) => void;
  updateJobSearch: (data: Partial<JobSearch>) => void;
  setIsJobSearchActive: (value: boolean) => void;
  setShowJobExploration: (value: boolean) => void;
  setShowProjectCreator: (value: boolean) => void;
  setShowOption2Menu: (value: boolean) => void;
  setShowOption3Menu: (value: boolean) => void;
  setShowOption4Menu: (value: boolean) => void;
  setShowCourseMenu: (value: boolean) => void;
  setShowIntroduction: (value: boolean) => void;
  setMessageHandler: (handler: ((message: string) => Promise<void>) | null) => void;
  handleUserMessage: (message: string) => Promise<void>;
  resetJobSearch: () => void;
  clearMessages: () => void;
}
export const useChatStore = create<ChatState>((set, get) => ({
  messages: [],
  isSearching: false,
  hasResults: false,
  jobCount: 0,
  searchResults: [],
  currentJobSearch: {},
  isJobSearchActive: false,
  searchError: null,
  showJobExploration: false,
  showProjectCreator: false,
  showOption2Menu: false,
  showOption3Menu: false,
  showOption4Menu: false,
  showCourseMenu: false,
  showIntroduction: false,
  messageHandler: null,
  getActiveState: () => {
    const key = 'activeState';
    if (memoizedState.has(key)) {
      return memoizedState.get(key);
    }
    const state = {
      showJobExploration: get().showJobExploration,
      showProjectCreator: get().showProjectCreator,
      isJobSearchActive: get().isJobSearchActive,
      showOption2Menu: get().showOption2Menu,
      showOption3Menu: get().showOption3Menu,
      showOption4Menu: get().showOption4Menu,
      showCourseMenu: get().showCourseMenu
    };
    memoizedState.set(key, state);
    return state;
  },
  addMessage: (content, type, options) => {
    set((state) => ({
      messages: [...state.messages, {
        id: Math.random().toString(36).slice(2),
        content,
        type,
        timestamp: new Date(),
        options
      }]
    }));
    memoizedState.clear();
  },
  updateMessageOption: (content, options, selectedOption) => {
    set((state) => ({
      messages: state.messages.map(msg =>
        msg.content === content ? { ...msg, options, selectedOption } : msg
      )
    }));
    memoizedState.clear();
  },
  setIsSearching: (value) => {
    set({ isSearching: value });
    memoizedState.clear();
  },
  setHasResults: (value) => {
    set({ hasResults: value });
    memoizedState.clear();
  },
  setJobCount: (count) => {
    set({ jobCount: count });
    memoizedState.clear();
  },
  setSearchResults: (jobs) => {
    set({
      searchResults: jobs,
      jobCount: jobs.length,
      hasResults: true,
      isSearching: false,
      searchError: null
    });
    memoizedState.clear();
  },
  setSearchError: (error) => {
    set({
      searchError: error,
      isSearching: false,
      hasResults: false
    });
    memoizedState.clear();
  },
  updateJobSearch: (data) => {
    set((state) => ({
      currentJobSearch: { ...state.currentJobSearch, ...data }
    }));
    memoizedState.clear();
  },
  setIsJobSearchActive: (value) => {
    set({ isJobSearchActive: value });
    memoizedState.clear();
  },
  setShowJobExploration: (value) => {
    if (value) {
      set({
        showJobExploration: true,
        showProjectCreator: false,
        isJobSearchActive: false,
        showOption2Menu: false,
        showOption3Menu: false,
        showOption4Menu: false,
        showCourseMenu: false,
        isSearching: false,
        hasResults: false
      });
    } else {
      set({ showJobExploration: false });
    }
    memoizedState.clear();
  },
  setShowProjectCreator: (value) => {
    if (value) {
      set({
        showProjectCreator: true,
        showJobExploration: false,
        isJobSearchActive: false,
        showOption2Menu: false,
        showOption3Menu: false,
        showOption4Menu: false,
        showCourseMenu: false,
        isSearching: false,
        hasResults: false
      });
    } else {
      set({ showProjectCreator: false });
    }
    memoizedState.clear();
  },
  setShowOption2Menu: (value) => {
    if (value) {
      set({
        showOption2Menu: true,
        showJobExploration: false,
        showProjectCreator: false,
        isJobSearchActive: false,
        showOption3Menu: false,
        showOption4Menu: false,
        showCourseMenu: false
      });
    } else {
      set({ showOption2Menu: false });
    }
    memoizedState.clear();
  },
  setShowOption3Menu: (value) => {
    if (value) {
      set({
        showOption3Menu: true,
        showJobExploration: false,
        showProjectCreator: false,
        isJobSearchActive: false,
        showOption2Menu: false,
        showOption4Menu: false,
        showCourseMenu: false
      });
    } else {
      set({ showOption3Menu: false });
    }
    memoizedState.clear();
  },
  setShowOption4Menu: (value) => {
    if (value) {
      set({
        showOption4Menu: true,
        showJobExploration: false,
        showProjectCreator: false,
        isJobSearchActive: false,
        showOption2Menu: false,
        showOption3Menu: false,
        showCourseMenu: false
      });
    } else {
      set({ showOption4Menu: false });
    }
    memoizedState.clear();
  },
  setShowCourseMenu: (value) => {
    if (value) {
      set({
        showCourseMenu: true,
        showJobExploration: false,
        showProjectCreator: false,
        isJobSearchActive: false,
        showOption2Menu: false,
        showOption3Menu: false,
        showOption4Menu: false
      });
    } else {
      set({ showCourseMenu: false });
    }
    memoizedState.clear();
  },
  setShowIntroduction: (value) => {
    set({ showIntroduction: value });
    memoizedState.clear();
  },
  setMessageHandler: (handler) => {
    set({ messageHandler: handler });
    memoizedState.clear();
  },
  handleUserMessage: async (message: string) => {
    const state = get();
    if (state.messageHandler) {
      state.setIsSearching(true);
      await state.messageHandler(message);
      state.setIsSearching(false);
    }
  },
  resetJobSearch: () => {
    set({
      currentJobSearch: {},
      isJobSearchActive: false,
      isSearching: false,
      hasResults: false,
      jobCount: 0,
      searchResults: [],
      searchError: null,
      messageHandler: null
    });
    memoizedState.clear();
  },
  clearMessages: () => {
    set({
      messages: [],
      messageHandler: null
    });
    memoizedState.clear();
  }
}));
