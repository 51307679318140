import { useEffect, useState, useCallback, useRef } from "react";
import { QuestionModal } from "../question-modal";
import { memoryQuestions, MemoryQuestion } from "@/data/memory-questions";
import { useTrophies } from "@/hooks/use-trophies";
import { useGameState } from "@/hooks/use-game-state";

interface SquidGameFrameProps {
  onClose: () => void;
  title?: string;
  difficulty?: 'easy' | 'medium' | 'hard';
  onRestart?: () => void;
  sectionId?: string;
}

export default function SquidGameFrame({
  onClose,
  title = 'Squid Game',
  difficulty: difficultyProp = 'easy',
  onRestart,
  sectionId
}: SquidGameFrameProps) {
  console.log("SquidGame - Title received:", title);
  console.log("SquidGame - Difficulty received:", difficultyProp);
  console.log("SquidGame - Section ID received:", sectionId);
  
  const { awardTrophy } = useTrophies();
  const { setGameState, setWinner } = useGameState();
  
  const [showQuestion, setShowQuestion] = useState(false);
  const [question, setQuestion] = useState<MemoryQuestion | null>(null);
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">(difficultyProp);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  
  useEffect(() => {
    setDifficulty(difficultyProp);
  }, [difficultyProp]);
  
  useEffect(() => {
    if (showQuestion && iframeRef.current?.contentWindow) {
      iframeRef.current.contentWindow.postMessage({
        type: "modalShown"
      }, "*");
    }
  }, [showQuestion]);
  
  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data?.type === "closeGame") {
        onClose();
      }
      
      if (event.data?.type === "gameResult") {
        console.log("Game result received:", event.data);
        
        if (event.data.result === "lost") {
          setQuestion(getRandomQuestion());
          setShowQuestion(true);
        } else if (event.data.result === "won") {
          setGameState("won");
          setWinner("You");
          
          const trophyType = {
            easy: "bronze",
            medium: "silver",
            hard: "gold",
          }[difficulty] as "bronze" | "silver" | "gold";
          
          awardTrophy(trophyType);
          console.log(`Trophy awarded (${trophyType}) for section: ${sectionId}`);
        } else if (event.data.result === "replay") {
          if (onRestart) {
            onRestart();
          }
        }
      }
    }
    
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        console.log("ESC key pressed - closing game");
        onClose();
      }
    }
    
    window.addEventListener("message", handleMessage);
    window.addEventListener("keydown", handleKeyDown);
    
    return () => {
      window.removeEventListener("message", handleMessage);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setGameState, setWinner, onClose, sectionId, difficulty, awardTrophy, onRestart]);

  const handleQuestionAnswer = useCallback(async (correct: boolean) => {
    if (correct) {
      const trophyType = {
        easy: "bronze",
        medium: "silver",
        hard: "gold",
      }[difficulty] as "bronze" | "silver" | "gold";
      
      await awardTrophy(trophyType);
      setGameState("won");
      setWinner("You");
      console.log(`Trophy awarded (${trophyType}) for section: ${sectionId}`);
    } else {
      setGameState("won");
      setWinner("Computer");
      console.log(`Game lost for section: ${sectionId}`);
    }
    
    setShowQuestion(false);
    
    if (iframeRef.current?.contentWindow) {
      iframeRef.current.contentWindow.postMessage({
        type: "modalClosed",
        restart: true
      }, "*");
    }
  }, [difficulty, awardTrophy, setGameState, setWinner, sectionId]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999,
      }}
    >
      <iframe
        ref={iframeRef}
        src={`/SquidGame/index.html?difficulty=${difficulty}&section=${sectionId || ''}`}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title={title}
        sandbox="allow-scripts allow-same-origin"
      />
      
      {showQuestion && question && (
        <QuestionModal 
          question={question} 
          onAnswer={handleQuestionAnswer} 
          title={title}
          difficulty={difficulty}
          sectionId={sectionId}
        />
      )}
    </div>
  );
}

function getRandomQuestion(): MemoryQuestion {
  return memoryQuestions[Math.floor(Math.random() * memoryQuestions.length)];
}