import { type Job } from '@/data/jobs';

interface JobSearchParams {
  location: string;
  type: string;
  field: string;
  count?: number;
}

interface JobResult {
  id?: string;
  title?: string;
  location?: string;
  description?: string;
  jobLink?: string;
}

interface PerplexityError {
  error?: {
    message?: string;
    type?: string;
    code?: string;
  };
}

export async function searchJobs(params: JobSearchParams): Promise<Job[]> {
  console.log('Searching jobs with params:', JSON.stringify(params, null, 2));

  try {
    const response = await fetch('https://hphbo.com/aiserverurl/search', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `${params.type} ${params.field} jobs`,
        location: params.location
      })
    });

    if (!response.ok) {
      let errorDetails: PerplexityError = {};
      let errorMessage: string;

      try {
        errorDetails = await response.json();
        errorMessage = errorDetails.error?.message || `API error (${response.status})`;
      } catch {
        errorMessage = `API error (${response.status}) - please try again later`;
      }

      throw new Error(errorMessage);
    }

    const data = await response.json();
    if (!data.results || !Array.isArray(data.results)) {
      throw new Error('Invalid response from search service');
    }

    if (data.results.length === 0) {
      throw new Error('No jobs found matching your criteria.');
    }

    return data.results.map((job: JobResult) => ({
      id: job.id || crypto.randomUUID(),
      title: job.title || 'Unknown Position',
      location: job.location || params.location,
      description: job.description || 'No description available',
      jobLink: job.jobLink || 'No link provided'
    }));

  } catch (error) {
    console.error('Job search error:', error);
    throw error;
  }
}
