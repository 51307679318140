import { create } from 'zustand';

interface ActivitiesHighlightStore {
  isHighlighted: boolean;
  setHighlighted: (value: boolean) => void;
}

export const useActivitiesHighlight = create<ActivitiesHighlightStore>((set) => ({
  isHighlighted: false,
  setHighlighted: (value) => set({ isHighlighted: value }),
}));