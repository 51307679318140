import { motion, AnimatePresence } from 'framer-motion';
import { AlertCircle } from 'lucide-react';
import { useChatStore } from '@/store/chat-store';
import { chatActions } from '@/store/chat-actions';

export function SearchStatus() {
  const { isSearching, jobCount, searchError } = useChatStore();
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <AnimatePresence mode="wait">
        {isSearching ? (
          <motion.div
            key="searching"
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <div className="spinner mb-4" />
            <h2 className="text-2xl font-semibold text-white whitespace-nowrap mb-8">
              SEARCHING FOR JOBS...
            </h2>
          </motion.div>
        ) : searchError ? (
          <motion.div
            key="error"
            className="text-center max-w-sm"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <div className="w-16 h-16 rounded-full bg-red-500/20 flex items-center justify-center mx-auto mb-4">
              <AlertCircle size={32} className="text-red-500" />
            </div>
            <h2 className="text-xl font-semibold text-red-500 mb-2">
              {searchError.includes('No jobs found') ? 'No Results Found' : 'Search Error'}
            </h2>
            <p className="text-gray-400 mb-6">
              {searchError.includes('No jobs found') ? (
                <>
                  We couldn't find any jobs matching your criteria. Try:
                  <ul className="mt-2 text-left text-sm space-y-1">
                    <li>• Using different search terms</li>
                    <li>• Expanding your location</li>
                    <li>• Checking different job types</li>
                  </ul>
                </>
              ) : (
                searchError
              )}
            </p>
            <div className="flex gap-3 justify-center">
              <motion.button
                className="px-6 py-2.5 bg-gray-800 hover:bg-gray-700 rounded-xl text-sm font-medium transition-colors whitespace-nowrap"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={chatActions.handleSearchJobs}
              >
                TRY AGAIN
              </motion.button>
              <motion.button
                className="px-6 py-2.5 bg-blue-600 hover:bg-blue-700 rounded-xl text-sm font-medium transition-colors whitespace-nowrap"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={chatActions.returnToChat}
              >
                RETURN TO CHAT
              </motion.button>
            </div>
          </motion.div>
        ) : jobCount > 0 ? (
          <motion.div
            key="results"
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <h2 className="text-2xl font-semibold text-white whitespace-nowrap mb-8">
              FOUND {jobCount} JOBS
            </h2>
            <div className="flex gap-3">
              <motion.button
                className="px-6 py-2.5 bg-gray-800 hover:bg-gray-700 rounded-xl text-sm font-medium transition-colors whitespace-nowrap"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={chatActions.handleSearchJobs}
              >
                TRY AGAIN
              </motion.button>
              <motion.button
                className="px-6 py-2.5 bg-blue-600 hover:bg-blue-700 rounded-xl text-sm font-medium transition-colors whitespace-nowrap"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={chatActions.returnToChat}
              >
                RETURN TO CHAT
              </motion.button>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}
