import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ShieldCheck, Loader2, Trophy, Award, Medal, AlertCircle, Save, FileText, UserPlus, Clock, Users, ChevronLeft } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { useMaintenanceStore } from '@/store/maintenance-store';
import { CreateMemberForm } from './create-member-form.tsx';
import { createClient } from '@supabase/supabase-js';
import { interviewQuestions } from '@/data/activities';

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

interface AdminDashboardProps {
  isOpen: boolean;
  onClose: () => void;
}

interface LoginCode {
  id: string;
  name: string | null;
  created_at: string;
  bronze_trophies: number;
  silver_trophies: number;
  gold_trophies: number;
  job_searches: number;
  last_search_at: string | null;
}

interface UserProfile {
  id: string;
  name: string;
  job_searches: number;
  last_search_at: string | null;
  created_at: string;
  bronze_trophies: number;
  silver_trophies: number;
  gold_trophies: number;
  incorrect_answers: number;
  description?: string;
  description_updated_at?: string | null;
}

interface QuestionScore {
  question_id: string;
  score: number;
  feedback: string;
  created_at: string;
}

type TabType = 'members' | 'settings';
type ProfileTabType = 'overview' | 'interviews' | 'notes';

export function AdminDashboard({ isOpen, onClose }: AdminDashboardProps) {
  const [codes, setCodes] = useState<LoginCode[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [userScores, setUserScores] = useState<QuestionScore[]>([]);
  const [userNotes, setUserNotes] = useState<{ [key: string]: string }>({});
  const [isSaving, setIsSaving] = useState(false);
  const { isInMaintenance, setMaintenance } = useMaintenanceStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showCreateMember, setShowCreateMember] = useState(false);
  const [activeTab, setActiveTab] = useState<TabType>('members');
  const [profileTab, setProfileTab] = useState<ProfileTabType>('overview');
  const { toast } = useToast();

  useEffect(() => {
    if (isOpen) {
      fetchCodes();
    }
  }, [isOpen]);

  const getQuestionText = (questionId: string) => {
    const allQuestions = [
      ...interviewQuestions.background,
      ...interviewQuestions.situational
    ];
    return allQuestions.find(q => q.id === questionId)?.text || 'Unknown Question';
  };

  const getScoreColor = (score: number) => {
    if (score >= 80) return 'text-green-400';
    if (score >= 60) return 'text-yellow-400';
    return 'text-red-400';
  };

  const formatDate = (date: string | null) => {
    if (!date) return 'Never';
    return new Date(date).toLocaleString('en-NZ', {
      timeZone: 'Pacific/Auckland',
      dateStyle: 'medium',
      timeStyle: 'short'
    });
  };

  const fetchUserScores = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('question_scores')
        .select('question_id, score, feedback, created_at')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setUserScores(data || []);
    } catch (err) {
      console.error('Error fetching user scores:', err);
    }
  };

  const handleUserSelect = async (user: UserProfile) => {
    setSelectedUser(user);
    setProfileTab('overview');
    await fetchUserScores(user.id);
  };

  const handleCreateMemberSuccess = () => {
    setShowCreateMember(false);
    fetchCodes();
    toast({
      title: "Member created",
      description: "New member has been added successfully.",
      duration: 3000
    });
  };

  const handleSaveNotes = async () => {
    if (!selectedUser) return;
    
    setIsSaving(true);
    try {
      const { error } = await supabase
        .from('user_notes')
        .upsert({
          user_id: selectedUser.id,
          content: userNotes[selectedUser.id] ?? '',
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id'
        });
        
      if (error) throw error;
      
      toast({
        title: "Notes saved",
        description: "Teacher notes have been updated successfully.",
        duration: 3000
      });
    } catch (err) {
      console.error('Error saving notes:', err);
      toast({
        title: "Error saving notes",
        description: "Please try again.",
        variant: "destructive",
        duration: 3000
      });
    } finally {
      setIsSaving(false);
    }
  };

  const fetchCodes = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { data: users, error: usersError } = await supabase
        .from('login_codes')
        .select(`
          id,
          name,
          job_searches,
          last_search_at,
          created_at,
          bronze_trophies,
          silver_trophies,
          gold_trophies,
          incorrect_answers
        `)
        .not('name', 'is', null)
        .order('created_at', { ascending: false });
      
      if (usersError) throw usersError;
      
      // Fetch notes for all users
      const { data: notes, error: notesError } = await supabase
        .from('user_notes')
        .select('user_id, content');

      if (notesError) throw notesError;

      // Create notes map
      const notesMap = (notes || []).reduce((acc, note) => ({
        ...acc,
        [note.user_id]: note.content || ''
      }), {} as Record<string, string>);

      setUserNotes(notesMap);
      setCodes(users || []);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const tabs = [
    { id: 'members', label: 'Members', icon: Users },
    { id: 'settings', label: 'Settings', icon: ShieldCheck }
  ] as const;

  const profileTabs = [
    { id: 'overview', label: 'Overview' },
    { id: 'interviews', label: 'Interview Scores' },
    { id: 'notes', label: 'Teacher Notes' }
  ] as const;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-gray-900/95 backdrop-blur-sm z-[200]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="h-full flex flex-col">
            {/* Header */}
            <div className="flex items-center justify-between p-6 border-b border-gray-800">
              <div className="flex items-center gap-3">
                <div className="p-2 rounded-xl bg-blue-500/20">
                  <ShieldCheck size={20} className="text-blue-400" />
                </div>
                <div>
                  <h2 className="text-xl font-medium">Admin Dashboard</h2>
                  <p className="text-sm text-gray-400">Manage members and view statistics</p>
                </div>
              </div>
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            {/* Tabs */}
            <div className="flex border-b border-gray-800">
              {tabs.map(({ id, label, icon: Icon }) => (
                <button
                  key={id}
                  className={`flex-1 p-4 text-sm font-medium relative ${
                    activeTab === id ? 'text-blue-500' : 'text-gray-400'
                  }`}
                  onClick={() => setActiveTab(id as TabType)}
                >
                  <div className="flex items-center justify-center gap-2">
                    <Icon size={16} />
                    <span>{label}</span>
                  </div>
                  {activeTab === id && (
                    <motion.div
                      layoutId="activeAdminTab"
                      className="absolute bottom-0 inset-x-0 h-0.5 bg-blue-500"
                      transition={{ type: "spring", stiffness: 380, damping: 30 }}
                    />
                  )}
                </button>
              ))}
            </div>

            {/* Content */}
            <div className="flex-1 overflow-auto">
              <AnimatePresence mode="wait">
                {activeTab === 'members' && (
                  <motion.div
                    key="members"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="p-6"
                  >
                    <div className="flex justify-between items-center mb-6">
                      <h3 className="text-xl font-medium">Members</h3>
                      <motion.button
                        onClick={() => setShowCreateMember(true)}
                        className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg text-sm font-medium transition-colors flex items-center gap-2"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <UserPlus size={16} />
                        <span>Create Member</span>
                      </motion.button>
                    </div>

                    {loading ? (
                      <div className="flex items-center justify-center py-12">
                        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                      </div>
                    ) : error ? (
                      <div className="flex flex-col items-center justify-center py-12 text-center">
                        <AlertCircle className="w-12 h-12 text-red-500 mb-4" />
                        <p className="text-red-500 font-medium mb-2">Failed to Load Data</p>
                        <p className="text-sm text-gray-400 mb-6">{error}</p>
                        <motion.button
                          onClick={fetchCodes}
                          className="px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors"
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                        >
                          Try Again
                        </motion.button>
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 gap-3">
                        {codes.map((user) => (
                          <motion.div
                            key={user.id}
                            className="bg-gray-800/50 hover:bg-gray-800 rounded-xl p-4 sm:p-6 transition-colors cursor-pointer group"
                            onClick={() => handleUserSelect(user)}
                            whileHover={{ scale: 1.01 }}
                            whileTap={{ scale: 0.99 }}
                          >
                            <div className="flex items-center gap-3 sm:gap-6">
                              <div className="p-2 sm:p-3 rounded-xl bg-gray-700/50 group-hover:bg-gray-700 transition-colors">
                                <Trophy size={20} className="text-amber-500 sm:w-6 sm:h-6" />
                              </div>
                              <div className="flex-1 min-w-0">
                                <h3 className="text-base sm:text-lg font-medium mb-1 truncate">{user.name}</h3>
                                <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-3 text-sm text-gray-400">
                                  <div className="truncate">{user.job_searches} job searches</div>
                                  <span className="hidden sm:inline">•</span>
                                  <div className="truncate">Last active: {formatDate(user.last_search_at)}</div>
                                  <span className="hidden sm:inline">•</span>
                                  <div className="truncate">Created: {formatDate(user.created_at)}</div>
                                </div>
                              </div>
                            </div>
                          </motion.div>
                        ))}
                      </div>
                    )}
                  </motion.div>
                )}

                {activeTab === 'settings' && (
                  <motion.div
                    key="settings"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="p-6"
                  >
                    <h3 className="text-xl font-medium mb-6">Settings</h3>
                    <div className="space-y-6">
                      <div className="bg-gray-800/50 rounded-xl p-6">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="text-lg font-medium mb-2">Maintenance Mode</h4>
                            <p className="text-sm text-gray-400">
                              Enable maintenance mode to prevent access to the application
                            </p>
                          </div>
                          <motion.button
                            onClick={() => setMaintenance(!isInMaintenance)}
                            className={`px-6 py-2.5 rounded-lg transition-colors flex items-center justify-center gap-2.5 text-sm font-medium ${
                              isInMaintenance 
                                ? 'bg-red-500/20 text-red-500 hover:bg-red-500/30' 
                                : 'bg-green-500/20 text-green-500 hover:bg-green-500/30'
                            }`}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                          >
                            {isInMaintenance ? 'Disable Maintenance' : 'Enable Maintenance'}
                          </motion.button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <AnimatePresence>
            {selectedUser && (
              <motion.div
                className="fixed inset-0 bg-black/80 backdrop-blur-sm z-[300]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <motion.div
                  className="h-full bg-gray-800 md:rounded-2xl md:m-8 overflow-hidden"
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.9, opacity: 0 }}
                >
                  <div className="p-6 border-b border-gray-700">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => setSelectedUser(null)}
                          className="p-2 hover:bg-gray-700 rounded-lg transition-colors"
                        >
                          <ChevronLeft size={20} />
                        </button>
                        <h3 className="text-xl font-medium">{selectedUser.name}'s Profile</h3>
                      </div>
                      <div className="flex items-center gap-2">
                        {profileTabs.map((tab) => (
                          <button
                            key={tab.id}
                            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                              profileTab === tab.id
                                ? 'bg-blue-500/20 text-blue-400'
                                : 'hover:bg-gray-700'
                            }`}
                            onClick={() => setProfileTab(tab.id)}
                          >
                            {tab.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="overflow-y-auto h-[calc(100vh-5rem)] md:h-[calc(100vh-10rem-5rem)]">
                    <div className="p-6 space-y-6">
                      <AnimatePresence mode="wait">
                        {profileTab === 'overview' && (
                          <motion.div
                            key="overview"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="space-y-6"
                          >
                            {/* Trophy Collection */}
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <div className="text-sm text-gray-400 mb-1">Bronze</div>
                                <div className="text-2xl font-medium text-amber-700 flex items-center gap-2">
                                  <div className="p-2 rounded-lg bg-amber-700/20">
                                    <Medal className="w-5 h-5" />
                                  </div>
                                  <span>{selectedUser.bronze_trophies}</span>
                                </div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-400 mb-1">Silver</div>
                                <div className="text-2xl font-medium text-gray-400 flex items-center gap-2">
                                  <div className="p-2 rounded-lg bg-gray-400/20">
                                    <Award className="w-5 h-5" />
                                  </div>
                                  <span>{selectedUser.silver_trophies}</span>
                                </div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-400 mb-1">Gold</div>
                                <div className="text-2xl font-medium text-amber-500 flex items-center gap-2">
                                  <div className="p-2 rounded-lg bg-amber-500/20">
                                    <Trophy className="w-5 h-5" />
                                  </div>
                                  <span>{selectedUser.gold_trophies}</span>
                                </div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-400 mb-1">Incorrect</div>
                                <div className="text-2xl font-medium text-red-500 flex items-center gap-2">
                                  <div className="p-2 rounded-lg bg-red-500/20">
                                    <AlertCircle className="w-5 h-5" />
                                  </div>
                                  <span>{selectedUser.incorrect_answers}</span>
                                </div>
                              </div>
                            </div>

                            {/* Activity Overview */}
                            <div className="bg-gray-800/50 rounded-xl p-6">
                              <h4 className="text-lg font-medium mb-4 flex items-center gap-2">
                                <Clock size={20} className="text-blue-400" />
                                Activity Overview
                              </h4>
                              <div className="space-y-4">
                                <div>
                                  <div className="text-sm text-gray-400 mb-1">Total Job Searches</div>
                                  <div className="text-2xl font-medium">{selectedUser.job_searches}</div>
                                </div>
                                <div>
                                  <div className="text-sm text-gray-400 mb-1">Last Search</div>
                                  <div className="text-lg">{formatDate(selectedUser.last_search_at)}</div>
                                </div>
                                <div>
                                  <div className="text-sm text-gray-400 mb-1">Member Since</div>
                                  <div className="text-lg">{formatDate(selectedUser.created_at)}</div>
                                </div>
                              </div>
                            </div>
                          </motion.div>
                        )}

                        {profileTab === 'interviews' && (
                          <motion.div
                            key="interviews"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="space-y-4"
                          >
                            <h4 className="text-lg font-medium">Interview Scores</h4>
                            {userScores.length > 0 ? (
                              userScores.map((score, index) => (
                                <div key={index} className="bg-gray-800/50 rounded-xl p-4">
                                  <div className="flex items-center justify-between mb-2">
                                    <h4 className="font-medium text-lg">
                                      {getQuestionText(score.question_id)}
                                    </h4>
                                    <div className={`text-lg font-medium ${getScoreColor(score.score)}`}>
                                      {score.score}/100
                                    </div>
                                  </div>
                                  {/* <div className="text-sm text-gray-400 whitespace-pre-wrap">
                                    {score.feedback}
                                  </div> */}
                                  <div className="text-xs text-gray-500 mt-2">
                                    {formatDate(score.created_at)}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="text-center text-gray-400 py-8">
                                No interview scores yet
                              </div>
                            )}
                          </motion.div>
                        )}

                        {profileTab === 'notes' && (
                          <motion.div
                            key="notes"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="space-y-4"
                          >
                            <div className="flex items-center justify-between">
                              <h4 className="text-lg font-medium flex items-center gap-2">
                                <FileText size={20} className="text-blue-400" />
                                Teacher Notes
                              </h4>
                              <motion.button
                                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg text-sm font-medium transition-colors flex items-center gap-2"
                                onClick={handleSaveNotes}
                                disabled={isSaving}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                              >
                                {isSaving ? (
                                  <>
                                    <Loader2 size={16} className="animate-spin" />
                                    <span>Saving...</span>
                                  </>
                                ) : (
                                  <>
                                    <Save size={16} />
                                    <span>Save Notes</span>
                                  </>
                                )}
                              </motion.button>
                            </div>
                            <textarea
                              value={userNotes[selectedUser.id] ?? ''}
                              onChange={(e) => setUserNotes(prev => ({
                                ...prev,
                                [selectedUser.id]: e.target.value
                              }))}
                              placeholder="Add notes about this student..."
                              className="w-full h-48 bg-gray-700/50 rounded-lg p-4 text-sm resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <div className="text-xs text-gray-500">
                              Last updated: {selectedUser.description_updated_at ? 
                                formatDate(selectedUser.description_updated_at) : 
                                'Never'}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {showCreateMember && (
              <CreateMemberForm 
                onClose={() => setShowCreateMember(false)}
                onSuccess={handleCreateMemberSuccess}
              />
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
}