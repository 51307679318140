import { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Wand2, RotateCcw, X } from 'lucide-react';

interface TextSimplifierProps {
  selectedText: string;
  onClose: () => void;
  onReplace: (newText: string) => void;
  onRevert?: () => void;
  position: { x: number; y: number };
  isModified?: boolean;
}

export function TextSimplifier({ 
  selectedText, 
  onClose, 
  onReplace, 
  onRevert,
  position,
  isModified = false
}: TextSimplifierProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const originalText = useRef(selectedText);

  const handleSimplify = async () => {
    setIsProcessing(true);
    try {
        console.log('Simplifying text:', selectedText);
        
        const prompt = `Simplify the following text: "${selectedText}"`;
        const chatGPTUrl = `https://chatgpt.com/?q=${encodeURIComponent(prompt)}`;

        window.open(chatGPTUrl, '_blank'); // Opens ChatGPT with the prompt in a new tab
        onClose();
    } catch (error) {
        console.error('Error opening ChatGPT:', error);
    } finally {
        setIsProcessing(false);
    }
};

  const handleRevert = () => {
    if (onRevert) {
      console.log('Reverting text to:', originalText.current);
      onRevert();
      onClose();
    }
  };

  const isMobile = window.innerWidth < 768;

  return (
    <motion.div
      className={`fixed z-[600] ${isMobile ? 'left-4 right-4 bottom-24' : ''}`}
      initial={{ opacity: 0, scale: 0.9, y: isMobile ? 20 : 0 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.9, y: isMobile ? 20 : 0 }}
      style={isMobile ? undefined : {
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: 'translate(-50%, -100%)'
      }}
    >
      <div className="bg-gray-900/95 backdrop-blur-lg rounded-xl shadow-xl border border-white/10 overflow-hidden">
        <div className="p-3 flex items-center gap-2">
          {isModified ? (
            <motion.button
              className="px-3 py-1.5 bg-orange-500/20 text-orange-400 rounded-lg text-sm font-medium flex items-center gap-1.5 hover:bg-orange-500/30 transition-colors"
              onClick={handleRevert}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <RotateCcw size={14} />
              <span>Revert</span>
            </motion.button>
          ) : (
            <motion.button

              className="px-3 py-1.5 bg-blue-500/20 text-blue-400 rounded-lg text-sm font-medium flex items-center gap-1.5 hover:bg-blue-500/30 transition-colors"
              onClick={handleSimplify}
             
              disabled={isProcessing}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Wand2 size={14} />
              {isProcessing ? (
                <div className="flex items-center gap-1">
                  <div className="w-1 h-1 rounded-full bg-blue-400 animate-bounce" style={{ animationDelay: '0ms' }} />
                  <div className="w-1 h-1 rounded-full bg-blue-400 animate-bounce" style={{ animationDelay: '150ms' }} />
                  <div className="w-1 h-1 rounded-full bg-blue-400 animate-bounce" style={{ animationDelay: '300ms' }} />
                </div>
              ) : (
                <span>Simplify</span>
              )}
            </motion.button>
          )}
          <motion.button
            className="p-1.5 hover:bg-white/10 rounded-lg transition-colors"
            onClick={onClose}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <X size={14} />
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
}