import { create } from 'zustand';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

interface MaintenanceStore {
  isInMaintenance: boolean;
  isLoading: boolean;
  setMaintenance: (value: boolean) => void;
  checkMaintenanceStatus: () => Promise<boolean>;
}

export const useMaintenanceStore = create<MaintenanceStore>((set) => ({
  isInMaintenance: false,
  isLoading: true,
  
  setMaintenance: (value) => set({ isInMaintenance: value }),
  
  checkMaintenanceStatus: async () => {
    try {
      set({ isLoading: true });

      const { data, error } = await supabase
        .from('system_settings') 
        .select('maintenance_mode')
        .eq('id', 1)
        .single();
      
      if (error) {
        // Handle database errors gracefully
        set({ isLoading: false });
        return false;
      }

      const isInMaintenance = Boolean(data?.maintenance_mode);
      
      set({ 
        isInMaintenance,
        isLoading: false 
      });
      
      return isInMaintenance;
    } catch (err) {
      // Fail gracefully
      set({ isInMaintenance: false, isLoading: false });
      return false;
    }
  }
}));