import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

// Memoization cache
const memoCache = new Map<string, any>();

export const cn = (...inputs: ClassValue[]) => {
  const key = JSON.stringify(inputs);
  if (memoCache.has(key)) {
    return memoCache.get(key);
  }
  const result = twMerge(clsx(inputs));
  memoCache.set(key, result);
  return result;
};

export const debounce = <T extends (...args: any[]) => any>(fn: T, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const throttle = <T extends (...args: any[]) => any>(fn: T, ms = 300) => {
  let isThrottled = false;
  return function (this: any, ...args: Parameters<T>) {
    if (isThrottled) return;
    fn.apply(this, args);
    isThrottled = true;
    setTimeout(() => {
      isThrottled = false;
    }, ms);
  };
};

// Image preloading with caching
const imageCache = new Map<string, Promise<void>>();

export const preloadImage = (src: string): Promise<void> => {
  if (imageCache.has(src)) {
    return imageCache.get(src)!;
  }

  const promise = new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = reject;
    img.src = src;
  });

  imageCache.set(src, promise);
  return promise;
};

// Batch DOM updates
export const batchDOMUpdates = (updates: (() => void)[]) => {
  requestAnimationFrame(() => {
    const fragment = document.createDocumentFragment();
    updates.forEach(update => update.call(fragment));
    document.body.appendChild(fragment);
  });
};

// Clear caches when memory pressure is high
if ('memory' in performance) {
  const clearCaches = () => {
    if ((performance as any).memory.usedJSHeapSize > 100_000_000) { // 100MB
      memoCache.clear();
      imageCache.clear();
    }
  };
  setInterval(clearCaches, 30000); // Check every 30 seconds
}