import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@/lib/utils";
import { games, interviews } from '@/data/activities';
import { games as genreGames } from '@/data/game-genres';
import { useChatStore } from "@/store/chat-store";
import { useActivitiesHighlight } from "@/hooks/use-activities-highlight";
import { ActivitiesHeader } from "./activities-header";
import { GamePick } from "@/components/games/game-pick";
import { GameWrapper } from "@/components/games";
import { ActivityCard } from "./activity-card";
import NewInterviewUI from '@/components/interview/NewInterviewUI';
import { GenreDropdown } from "@/components/games/genre-dropdown";
import { GameCard } from "@/components/games/game-card";
import { useGameState } from "@/hooks/use-game-state";
import { ChevronLeft } from "lucide-react";

interface ActivitiesPanelProps {
  className?: string;
  onRestart?: () => void;
}

type GameState = {
  showGames: boolean;
  selectedGame: string | null;
  difficulty: 'easy' | 'medium' | 'hard' | null;
  selectedGenre: string | null;
  sectionId: string | null;
};

export function ActivitiesPanel({ className, onRestart }: ActivitiesPanelProps) {
  const { isJobSearchActive } = useChatStore();
  const { isHighlighted } = useActivitiesHighlight();
  const [showInterview, setShowInterview] = useState(false);
  const [interviewId, setInterviewId] = useState<string | null>(null);
  const [interviewType, setInterviewType] = useState<'background' | 'situational' | null>(null);
  const [gameState, setGameState] = useState<GameState>({
    showGames: false,
    selectedGame: null,
    difficulty: null,
    selectedGenre: null,
    sectionId: null
  });
  const { setSelectedGame, setGameState: setGamePlayState } = useGameState();

  const handleActivityClick = (activityId: string) => {
    setGameState({ 
      showGames: true, 
      selectedGame: null, 
      difficulty: null,
      selectedGenre: null,
      sectionId: activityId
    });
  };

  const handleInterviewClick = (id: string, type: 'background' | 'situational') => {
    setInterviewId(id);
    setInterviewType(type);
    setShowInterview(true);
  };

  const handleGenreSelect = (genreId: string | null) => {
    setGameState(prev => ({
      ...prev,
      selectedGenre: genreId
    }));
  };

  const handleGameSelect = (gameId: string) => {
    setGameState(prev => ({ 
      ...prev,
      showGames: false, 
      selectedGame: gameId, 
      difficulty: null
    }));
    setSelectedGame(gameId);
  };

  const handleClose = () => {
    setGameState({
      showGames: false,
      selectedGame: null,
      difficulty: null,
      selectedGenre: null,
      sectionId: null
    });
    setSelectedGame(null);
    if (onRestart) onRestart();
  };

  const handleDifficultySelect = (difficulty: 'easy' | 'medium' | 'hard') => {
    setGameState(prev => ({ 
      ...prev, 
      showGames: false, 
      difficulty 
    }));
    setGamePlayState('playing');
  };

  const handleBackFromGames = () => {
    setGameState(prev => ({
      ...prev,
      showGames: false,
      selectedGame: null,
      difficulty: null,
      selectedGenre: null,
      sectionId: null
    }));
  };

  const handleBackFromDifficulty = () => {
    setGameState(prev => ({
      ...prev,
      showGames: true,
      selectedGame: null
    }));
  };

  const filteredGames = genreGames.filter((game: { genreId: string | null }) => 
    !gameState.selectedGenre || game.genreId === gameState.selectedGenre
  );

  return (
    <div 
      className={cn(
        "flex flex-col h-full border-r border-gray-800 relative bg-gray-900 z-[100] overflow-hidden",
        isJobSearchActive && "opacity-50 pointer-events-none",
        isHighlighted && "border-2 border-blue-500 shadow-[0_0_15px_rgba(59,130,246,0.5)]",
        className
      )}
      data-panel="activities"
    >
      <ActivitiesHeader />
      <div className="flex-1 overflow-y-auto scrollbar-activities">
        <AnimatePresence mode="wait">
          {gameState.difficulty ? (
            <GameWrapper
              gameId={gameState.selectedGame!}
              difficulty={gameState.difficulty}
              onRestart={handleClose}
              sectionId={gameState.sectionId || undefined}
            />
          ) : gameState.showGames ? (
            <motion.div
              key="games"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="p-4 space-y-6"
            >
              <button 
                onClick={handleBackFromGames}
                className="flex items-center gap-1 text-gray-400 hover:text-white transition-colors mb-2"
              >
                <ChevronLeft size={16} />
                <span className="text-sm">Back to Activities</span>
              </button>
              <div>
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium">Games</h3>
                  <div className="w-48">
                    <GenreDropdown
                      selectedGenre={gameState.selectedGenre}
                      onSelect={handleGenreSelect}
                    />
                  </div>
                </div>
                <div className="grid gap-3">
                  {filteredGames.map((game: any) => (
                    <GameCard
                      key={game.id}
                      id={game.id}
                      title={game.title}
                      description={game.description}
                      color={game.color}
                      disabled={game.disabled}
                      onClick={() => !game.disabled && handleGameSelect(game.id)}
                    />
                  ))}
                </div>
              </div>
            </motion.div>
          ) : gameState.selectedGame ? (
            <motion.div
              key="difficulty"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="p-4"
            >
              <button 
                onClick={handleBackFromDifficulty}
                className="flex items-center gap-1 text-gray-400 hover:text-white transition-colors mb-6"
              >
                <ChevronLeft size={16} />
                <span className="text-sm">Back to Games</span>
              </button>
              <GamePick onSelect={handleDifficultySelect} />
            </motion.div>
          ) : (
            <motion.div
              key="activities"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="p-4 space-y-6"
            >
              <div>
                <h3 className="text-sm font-medium text-gray-400 mb-3">Games</h3>
                <div className="grid grid-cols-2 gap-3">
                  {games.map((game) => (
                    <ActivityCard 
                      key={game.id} 
                      {...game}
                      disabled={game.disabled || isJobSearchActive}
                      onClick={() => handleActivityClick(game.id)}
                      type="game"
                    />
                  ))}
                </div>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-400 mb-3">Interview</h3>
                <div className="space-y-3">
                  <div>
                    <h4 className="text-xs font-medium text-gray-500 mb-2 ml-1">Text</h4>
                    <div className="grid grid-cols-2 gap-3">
                      {interviews.text.map((interview) => (
                        <ActivityCard 
                          key={interview.id} 
                          {...interview}
                          disabled={interview.disabled || isJobSearchActive}
                          onClick={() => !interview.disabled && handleInterviewClick(interview.id, interview.id as 'background' | 'situational')}

                          type="interview"
                        />
                      ))}
                    </div>
                  </div>
                  <div>
                    <h4 className="text-xs font-medium text-gray-500 mb-2 ml-1">Voice</h4>
                    <div className="grid grid-cols-2 gap-3">
                      {interviews.voice.map((interview) => (
                        <ActivityCard 
                          key={interview.id} 
                          {...interview}
                          disabled={interview.disabled || isJobSearchActive}
                          onClick={() => {
                            if (!interview.disabled) {
                              if (interview.id === 'voice-practice') {
                                window.open(interview.url, '_blank');
                              } else {
                                handleInterviewClick(interview.id, 'situational');
                              }
                            }
                          }}
                          type="interview"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {showInterview && interviewId && interviewType && (
          <NewInterviewUI 
            interviewId={interviewId}
            type={interviewType}
            onClose={() => {
              setShowInterview(false);
              setInterviewId(null);
              setInterviewType(null);
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
