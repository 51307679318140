import { useRef, useEffect, useCallback, useState } from "react";
import { motion } from "framer-motion";
import { MessageSquare, ShieldCheck, ArrowLeft, LogOut } from "lucide-react";
import { PanelHeader } from "@/components/panel-header";
import { ChatInput } from "./chat-input";
import { SourceSuggestions } from "@/components/source-suggestions";
import { SearchStatus } from "./search-status";
import { AdminDashboard } from "@/components/activities/admin-dashboard";
import { AdminLogin } from "@/components/activities/admin-login";
import { cn } from "@/lib/utils";
import { useChatStore } from "@/store/chat-store";
import { chatActions } from "@/store/chat-actions";
import { useMobileTabs } from "@/hooks/use-mobile-tabs";
import { useAuth } from "@/hooks/use-auth";
import { createClient } from '@supabase/supabase-js';
import { ChatMessage } from "./chat-message";
import { SearchingAnimation } from "./searching-animation";

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

interface ChatPanelProps {
  className?: string;
}

export function ChatPanel({ className }: ChatPanelProps) {
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [showAdminLogin, setShowAdminLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { messages, isSearching, hasResults, messageHandler, clearMessages, isJobSearchActive, setIsJobSearchActive } = useChatStore();
  const { activeTab } = useMobileTabs();
  const { codeId, logout, userName } = useAuth();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const jobSearchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (!isJobSearchActive) return;
      const target = event.target as Node;
      const panels = document.querySelectorAll('[data-panel]');
      const clickedOnOtherPanel = Array.from(panels).some(panel => {
        const panelType = panel.getAttribute('data-panel');
        return panel.contains(target) && panelType !== 'chat';
      });
      const isWithinJobSearch = jobSearchRef.current?.contains(target);
      if (clickedOnOtherPanel && !isWithinJobSearch) {
        setIsJobSearchActive(false);
        clearMessages();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isJobSearchActive, setIsJobSearchActive, clearMessages]);

  // Set admin status immediately based on userName
  useEffect(() => {
    // Quick initial check - if userName is 'Facilitator', they're an admin
    if (userName === 'Facilitator') {
      setIsAdmin(true);
      return;
    }
    
    // Fall back to DB check only if needed
    const checkAdminStatus = async () => {
      if (!codeId) return;
      try {
        const { data, error } = await supabase
          .from('login_codes')
          .select('code')
          .eq('id', codeId)
          .single();

        if (error) {
          if (error.message?.includes('Failed to fetch')) {
            console.warn('Please connect to Supabase to check admin status');
          } else {
            console.error('Error checking admin status:', error);
          }
          setIsAdmin(false);
          return;
        }
        
        setIsAdmin(data.code === '1111');
      } catch (err) {
        console.error('Error checking admin status:', err);
        setIsAdmin(false);
      }
    };

    if (!isAdmin && codeId) {
      checkAdminStatus();
    }
  }, [codeId, userName, isAdmin]);

  const scrollToLatestQuestion = useCallback(() => {
    if (chatContainerRef.current && messagesEndRef.current) {
      const container = chatContainerRef.current;
      const lastMessage = messagesEndRef.current;
      const containerHeight = container.clientHeight;
      const lastMessageTop = lastMessage.offsetTop;
      const idealScrollPosition = lastMessageTop - (containerHeight / 3);
      
      requestAnimationFrame(() => {
        container.scrollTo({
          top: idealScrollPosition,
          behavior: "smooth"
        });
      });
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'chat') {
      const store = useChatStore.getState();
      store.clearMessages();
    }
  }, [activeTab]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.content === "What field of job?") {
      scrollToLatestQuestion();
    }
  }, [messages, scrollToLatestQuestion]);

  const handleSendMessage = useCallback(async (message: string) => {
    const store = useChatStore.getState();
    await store.handleUserMessage(message);
  }, []);

  const handleBack = () => {
    if (messageHandler) {
      clearMessages();
    }
  };

  const handleLogout = () => {
    if (confirm("Are you sure you want to log out?")) {
      logout();
    }
  };

  return (
    <div className={cn("flex flex-col h-full min-h-0 bg-gray-900 relative", className)} data-panel="chat">
      <div className="h-[50px] flex items-center justify-between px-4 border-b border-gray-800">
        <div className="flex items-center gap-3">
          {messageHandler && (
            <motion.button
              className="p-2 hover:bg-gray-800 rounded-lg transition-colors flex items-center gap-2 text-gray-400 hover:text-white"
              onClick={handleBack}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <ArrowLeft size={16} />
              <span className="text-sm">Back</span>
            </motion.button>
          )}
          <h2 className="text-base font-normal leading-none">Chat</h2>
        </div>
        <div className="flex items-center gap-2">
          <motion.button
            onClick={handleLogout}
            className="w-8 h-8 flex items-center justify-center hover:bg-gray-800 rounded-lg transition-colors text-red-400"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            title="Logout"
          >
            <LogOut size={16} />
          </motion.button>
          {isAdmin && (
            <motion.button
              onClick={() => setShowAdminLogin(true)}
              className="w-8 h-8 flex items-center justify-center hover:bg-gray-800 rounded-lg transition-colors text-blue-500"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              title="Facilitator Dashboard"
            >
              <ShieldCheck size={16} />
            </motion.button>
          )}
        </div>
      </div>
      
      <div ref={chatContainerRef} className="flex-1 overflow-y-auto scrollbar-activities">
        <div className="p-4">
          {messages.length === 0 ? (
            <SourceSuggestions />
          ) : isSearching ? (
            <div ref={jobSearchRef}>
              <SearchingAnimation />
            </div>
          ) : hasResults ? (
            <div ref={jobSearchRef}>
              <SearchStatus />
            </div>
          ) : (
            <div ref={jobSearchRef} className="flex-1">
              {messages.map((message) => (
                <ChatMessage key={message.id} message={message} />
              ))}
              <div ref={messagesEndRef} />
            </div>
          )}
        </div>
      </div>

      <ChatInput onSend={handleSendMessage} className="sticky bottom-0" />
      
      <AdminLogin
        isOpen={showAdminLogin}
        onClose={() => setShowAdminLogin(false)}
        onSuccess={() => {
          setShowAdminLogin(false);
          setIsAdminOpen(true);
        }}
      />
      
      <AdminDashboard 
        isOpen={isAdminOpen}
        onClose={() => setIsAdminOpen(false)}
      />
    </div>
  );
}