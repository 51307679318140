import { motion } from 'framer-motion';
import { SearchOption } from './search-option';
import { type SearchOption as SearchOptionType } from '@/store/chat-store';

interface SearchStepProps {
  question: string;
  options: SearchOptionType[];
  onSelect: (value: string, isCustom?: boolean) => void;
  onCustomInput: (value: string) => void;
  selectedValue?: string;
}

export function SearchStep({ 
  question, 
  options, 
  onSelect,
  onCustomInput,
  selectedValue 
}: SearchStepProps) {
  return (
    <motion.div
      className="w-full max-w-md text-center"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <h2 className="text-2xl font-medium text-white mb-8">
        {question}
      </h2>
      
      <div className="grid gap-3">
        {options.map((option) => (
          <SearchOption
            key={option.value}
            {...option}
            isSelected={selectedValue === option.value}
            onSelect={() => onSelect(option.value, option.isCustom)}
          />
        ))}
      </div>

      <div className="mt-4 text-sm text-gray-400">
        Or type your own response in the chat below
      </div>
    </motion.div>
  );
}