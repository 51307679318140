import { motion } from 'framer-motion';
import { useMaintenanceStore } from '@/store/maintenance-store';

export function MaintenanceOverlay() {
  const { isInMaintenance, isLoading } = useMaintenanceStore();
  
  if (isLoading || !isInMaintenance) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-gray-900/95 backdrop-blur-sm z-[9999] flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="relative">
        <motion.div
          className="text-center"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <h1 className="relative text-6xl sm:text-8xl font-black mb-4 select-none">
            {/* Shadow layers for 3D effect */}
            <span className="absolute inset-0 bg-gradient-to-br from-blue-600 to-purple-600 bg-clip-text text-transparent blur-sm transform translate-y-[0.15em] translate-x-[0.15em] -z-10">
              TAMS 2025
            </span>
            <span className="absolute inset-0 bg-gradient-to-br from-blue-700 to-purple-700 bg-clip-text text-transparent blur-sm transform translate-y-[0.2em] translate-x-[0.2em] -z-20">
              TAMS 2025
            </span>
            {/* Main text with gradient */}
            <span className="relative bg-gradient-to-br from-blue-400 via-blue-500 to-purple-500 bg-clip-text text-transparent">
              TAMS 2025
            </span>
            {/* Highlight layer */}
            <span className="absolute inset-0 bg-gradient-to-t from-transparent via-white/10 to-white/30 bg-clip-text text-transparent">
              TAMS 2025
            </span>
          </h1>
        </motion.div>
      </div>
    </motion.div>
  );
}