import { MessageSquare, Gamepad2, Briefcase } from "lucide-react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { useMobileTabs } from "@/hooks/use-mobile-tabs";
import { useChatStore } from "@/store/chat-store";
import { useCallback } from "react";

const tabs = [
  { id: 'sources', icon: Gamepad2, label: 'Activities' },
  { id: 'chat', icon: MessageSquare, label: 'Chat' },
  { id: 'tasks', icon: Briefcase, label: 'Tasks' }
] as const;

// Memoize tab icons to prevent unnecessary re-renders
const memoizedIcons = new Map();

const getTabIcon = (id: string) => {
  if (!memoizedIcons.has(id)) {
    const icon = tabs.find(tab => tab.id === id)?.icon;
    if (icon) {
      memoizedIcons.set(id, icon);
    }
  }
  return memoizedIcons.get(id);
};

export function MobileNav() {
  const { activeTab, setActiveTab } = useMobileTabs();
  const { showJobExploration, setShowJobExploration } = useChatStore();

  // Memoize handlers
  const handleTabClick = useCallback((id: typeof tabs[number]['id']) => {
    if (showJobExploration) {
      setShowJobExploration(false);
    }
    setActiveTab(id);
  }, [showJobExploration, setShowJobExploration, setActiveTab]);

  return (
    <nav className="flex items-center border-t border-gray-800 bg-gray-900/80 backdrop-blur-lg md:hidden relative z-[200]">
      {tabs.map(({ id, label }) => {
        const Icon = getTabIcon(id);
        return (
          <button
            key={id}
            onClick={() => handleTabClick(id)}
            className={cn(
              "flex-1 h-14 flex flex-col items-center justify-center relative",
              "text-gray-400 hover:text-gray-300 transition-colors",
              activeTab === id && "text-white"
            )}
          >
            <div className="flex flex-col items-center gap-1.5">
              {Icon && <Icon size={20} />}
              <span className="text-xs leading-none">{label}</span>
            </div>
            {activeTab === id && (
              <motion.div
                className="absolute top-0 left-0 right-0 h-0.5 bg-blue-500"
                layoutId="activeTab"
                transition={{ type: "spring", stiffness: 380, damping: 30 }}
              />
            )}
          </button>
        );
      })}
    </nav>
  );
}