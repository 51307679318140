import { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Clock } from '@/components/auth/clock';
import { PinPad } from '@/components/auth/pin-pad';
import { BackgroundImage } from '@/components/auth/background-image';
import { useAuth } from '@/hooks/use-auth';
import { useAudio } from '@/lib/hooks/useAudio';
import { useImagePreloader } from '@/lib/hooks/useImagePreloader';

const SUCCESS_SOUND = 'https://assets.mixkit.co/active_storage/sfx/2018/2018-preview.mp3';
const ERROR_SOUND = 'https://assets.mixkit.co/active_storage/sfx/955/955.wav';
const BACKGROUND_IMAGE = 'https://images.unsplash.com/photo-1541701494587-cb58502866ab?q=80&w=1920&auto=format&fit=crop';

const preloadPoppinsFont = () => {
  const link = document.createElement('link');
  link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

export function LoginPage() {
  const [showPin, setShowPin] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [username, setUsername] = useState(() => {
    try {
      const storedAuth = localStorage.getItem('tams-auth-storage');
      if (storedAuth) {
        const parsedAuth = JSON.parse(storedAuth);
        if (parsedAuth && parsedAuth.userName) {
          return parsedAuth.userName;
        }
      }
    } catch (e) {
      console.error('Error parsing stored auth data:', e);
    }
    return '';
  });
  const [error, setError] = useState('');
  const { login, isAuthenticated } = useAuth();
  const { play: playSuccess } = useAudio(SUCCESS_SOUND);
  const { play: playError } = useAudio(ERROR_SOUND);
  const isImageLoaded = useImagePreloader(BACKGROUND_IMAGE);

  useEffect(() => {
    preloadPoppinsFont();
  }, []);

  useEffect(() => {
    if (isImageLoaded) {
      setIsReady(true);
    }
  }, [isImageLoaded]);

  const handleUsernameSubmit = useCallback((e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (!username.trim()) {
      setError('Please enter your username');
      return;
    }

    setError('');
    setShowPin(true);
  }, [username]);

  const handlePinComplete = useCallback(async (pin: string) => {
    try {
      const success = await login(pin, username);
      
      if (success) {
        playSuccess();
        await new Promise(resolve => setTimeout(resolve, 1500));
        return true;
      } else {
        playError();
        setError('Invalid username or code');
        setShowPin(false);
        return false;
      }
    } catch (err) {
      console.error('Login error:', err);
      playError();
      setError('An error occurred during login');
      setShowPin(false);
      return false;
    }
  }, [login, playSuccess, playError, username]);

  if (isAuthenticated) {
    return (
      <div className="fixed inset-0 bg-gray-900 flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <motion.div 
      className="relative h-screen w-screen overflow-hidden bg-black"
      initial={{ opacity: 0 }}
      animate={{ opacity: isReady ? 1 : 0 }}
      transition={{ duration: 0.3 }}
    >
      {isReady && <BackgroundImage imageUrl={BACKGROUND_IMAGE} />}

      <div className="relative z-10 h-full flex flex-col items-center justify-center">
        <AnimatePresence mode="wait">
          {!showPin ? (
            <motion.div
              key="username"
              className="flex flex-col items-center justify-center w-full max-w-md px-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <div className="w-full flex justify-center items-center mb-8">
                <Clock />
              </div>
              <motion.form
                className="w-full"
                onSubmit={handleUsernameSubmit}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <div className="relative">
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      setError('');
                    }}
                    placeholder="Enter username"
                    className="w-full px-6 py-4 rounded-2xl bg-white/10 backdrop-blur-sm border-2 border-white/20 focus:border-blue-500/50 focus:outline-none text-center text-xl font-medium placeholder:text-white/30 transition-colors text-white select-text"
                    style={{ WebkitUserSelect: 'text', userSelect: 'text' }}
                    autoFocus
                  />
                  <AnimatePresence>
                    {error && (
                      <motion.p
                        className="absolute -bottom-8 left-0 right-0 text-red-400 text-sm text-center"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                      >
                        {error}
                      </motion.p>
                    )}
                  </AnimatePresence>
                </div>

                <motion.button
                  type="submit"
                  className="mt-12 w-full px-6 py-4 rounded-2xl bg-gradient-to-r from-blue-500 to-purple-500 font-medium text-lg disabled:opacity-50 disabled:cursor-not-allowed transition-opacity"
                  disabled={!username.trim()}
                  // disabled
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                 LOGIN
                </motion.button>
              </motion.form>
            </motion.div>
          ) : (
            <motion.div
              key="pinpad"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              onClick={(e) => e.stopPropagation()}
            >
              <PinPad onComplete={handlePinComplete} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}