import { motion } from 'framer-motion';
import { Gamepad2 } from 'lucide-react';
import { cn } from '@/lib/utils';

interface GameCardProps {
  id: string;
  title: string;
  description: string;
  color: string;
  disabled?: boolean;
  onClick: () => void;
}

export function GameCard({ 
  title, 
  description, 
  color, 
  disabled,
  onClick 
}: GameCardProps) {
  return (
    <motion.button
      className={cn(
        "w-full p-4 rounded-xl bg-gradient-to-r relative group overflow-hidden",
        color,
        disabled && "opacity-60 cursor-not-allowed"
      )}
      onClick={onClick}
      whileHover={disabled ? undefined : { scale: 1.02 }}
      whileTap={disabled ? undefined : { scale: 0.98 }}
      disabled={disabled}
    >
      <div className="absolute inset-0 bg-gray-900/90 transition-opacity group-hover:opacity-80" />
      <div className="relative flex items-center gap-3">
        <div className="p-2 rounded-lg bg-gray-800/50 flex-shrink-0">
          <Gamepad2 size={20} className="text-blue-400" />
        </div>
        <div className="flex-1 text-left min-w-0">
          <h3 className="font-medium mb-1">{title}</h3>
          <p className="text-sm text-gray-400">{description}</p>
        </div>
        
      </div>
    </motion.button>
  );
}