import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MobileKeypad } from './mobile-keypad';

interface PinPadProps {
  onComplete: (pin: string) => Promise<boolean>;
}

export function PinPad({ onComplete }: PinPadProps) {
  const [pin, setPin] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handleKeyboardPress = (e: KeyboardEvent) => {
      if (isProcessing) return;
      
      const key = e.key;
      if (/^\d$/.test(key)) {
        handlePinChange(pin + key);
      } else if (key === 'Backspace') {
        handlePinChange(pin.slice(0, -1));
      }
    };

    window.addEventListener('keydown', handleKeyboardPress);
    return () => window.removeEventListener('keydown', handleKeyboardPress);
  }, [pin, isProcessing]);

  const handlePinChange = (newPin: string) => {
    if (isProcessing || success) return;
    
    if (newPin.length <= 4) {
      setPin(newPin);
      
      if (newPin.length === 4) {
        handleSubmit(newPin);
      }
    }
  };

  const handleSubmit = async (pinToSubmit: string) => {
    setIsProcessing(true);
    
    try {
      const result = await onComplete(pinToSubmit);
      
      if (result) {
        setSuccess(true);
        // Keep success state visible for 2 seconds after sound finishes
        await new Promise(resolve => setTimeout(resolve, 2000));
      } else {
        setError(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setError(false);
        setPin('');
      }
    } catch (err) {
      console.error('Error submitting pin:', err);
      setError(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      setError(false);
      setPin('');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="w-full max-w-sm px-4">
      <motion.div 
        className="flex justify-center gap-4 sm:gap-6 mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {[...Array(4)].map((_, i) => (
          <motion.div
            key={i}
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-2xl"
            initial={{ scale: 0.8 }}
            animate={{
              scale: pin.length > i ? 1 : 0.8,
              backgroundColor: error 
                ? '#ef4444' 
                : success 
                  ? '#22c55e'
                  : pin.length > i 
                    ? '#ffffff' 
                    : '#ffffff33'
            }}
            transition={{ 
              type: "spring", 
              stiffness: 400, 
              damping: 25,
              backgroundColor: { duration: 0.5 }
            }}
          />
        ))}
      </motion.div>

      <div>
        <MobileKeypad 
          onKeyPress={key => handlePinChange(pin + key)}
          onBackspace={() => handlePinChange(pin.slice(0, -1))}
          disabled={isProcessing}
        />
      </div>
    </div>
  );
}