import { Gamepad2, Users, MessageSquare, Mic } from "lucide-react";
import { Mail, HelpCircle, Sparkles, Wrench, FileText, Settings, Zap, PenTool as Tool, BookOpen, GraduationCap, School } from "lucide-react";

export const games = [
  {
    id: 'Readiness',
    title: 'Readiness',
    description: 'Readiness',
    icon: Gamepad2,
    color: 'bg-purple-500',
    glowColor: 'bg-purple-500/30',
    type: 'game',
    disabled: false
  },
  {
    id: 'Workplace',
    title: 'Workplace',
    description: 'Workplace',
    icon: Gamepad2,
    color: 'bg-blue-500',
    glowColor: 'bg-blue-500/30',
    type: 'game',
    disabled: false
  },
  {
    id: 'Scenarios',
    title: 'Scenarios',
    description: 'Scenarios',
    icon: Gamepad2,
    color: 'bg-green-500',
    glowColor: 'bg-green-500/30',
    type: 'game',
    disabled: false
  },
  {
    id: 'Digital',
    title: 'Digital',
    description: 'Digital',
    icon: Gamepad2,
    color: 'bg-orange-500',
    glowColor: 'bg-orange-500/30',
    type: 'game',
    disabled: false
  }
];

export const interviews = {
  text: [
    {
      id: 'background',
      title: 'Timed',
      description: 'Text Interview',
      icon: MessageSquare,
      color: 'bg-purple-500',
      glowColor: 'bg-purple-500/30',
      type: 'interview',
      disabled: false
    },
    {
      id: 'situational',
      title: 'Paced',
      description: 'Text Interview',
      icon: MessageSquare,
      color: 'bg-yellow-500',
      glowColor: 'bg-yellow-500/30',
      type: 'interview',
      disabled: false
    }
  ],
  voice: [
    {
      id: 'voice-practice',
      title: 'Voice Practice',
      description: 'Voice Interview',
      icon: Mic,
      color: 'bg-blue-500',
      glowColor: 'bg-blue-500/30',
      type: 'interview',
      disabled: false,
      url: 'https://www.cloudskillsboost.google/interview_warmup/category/general'
    },
    {
      id: 'voice-practice',
      title: 'Voice Analysis',
      description: 'Emotion & Tone',
      icon: Mic,
      color: 'bg-blue-500',
      glowColor: 'bg-blue-500/30',
      type: 'interview',
      disabled: false,
      url: 'https://hume-evi-next-js-starter.vercel.app/'
    }
  ]
};

export const prompts = [
  {
    id: 'custom-prompt',
    title: 'Custom Prompt',
    description: 'Create your own ChatGPT prompt',
    icon: FileText,
    color: 'bg-emerald-500',
    glowColor: 'bg-emerald-500/30',
    type: 'prompt',
    disabled: false,
    hasSubmenu: true
  },
  {
    id: 'prompt-settings',
    title: 'Prompt Settings',
    description: 'Configure prompt behavior',
    icon: Settings,
    color: 'bg-violet-500',
    glowColor: 'bg-violet-500/30',
    type: 'prompt',
    disabled: false
  },
  {
    id: 'quick-prompts',
    title: 'Quick Prompts',
    description: 'Pre-made prompt templates',
    icon: Zap,
    color: 'bg-amber-500',
    glowColor: 'bg-amber-500/30',
    type: 'prompt',
    disabled: false
  },
  {
    id: 'prompt-tools',
    title: 'Prompt Tools',
    description: 'Advanced prompt utilities',
    icon: Tool,
    color: 'bg-pink-500',
    glowColor: 'bg-pink-500/30',
    type: 'prompt',
    disabled: false
  }
];

export const courseOptions = [
  {
    id: 'option1',
    title: 'Option 1',
    description: 'First option',
    icon: BookOpen,
    color: 'bg-blue-500',
    glowColor: 'bg-blue-500/30'
  },
  {
    id: 'option2', 
    title: 'Option 2',
    description: 'Second option',
    icon: GraduationCap,
    color: 'bg-green-500',
    glowColor: 'bg-green-500/30'
  },
  {
    id: 'option3',
    title: 'Option 3',
    description: 'Third option', 
    icon: School,
    color: 'bg-purple-500',
    glowColor: 'bg-purple-500/30'
  }
];

// Interview questions by type
export const interviewQuestions = {
  background: [
    {
      id: 'bg1',
      text: 'Please tell me why you would be a good fit for this role.'
    },
    {
      id: 'bg2',
      text: 'Can you please tell me a bit about yourself?'
    },
    {
      id: 'bg3',
      text: 'What are your career goals for the next five years?'
    },
    {
      id: 'bg4',
      text: 'Please tell me about some of your strengths and weaknesses.'
    },
    {
      id: 'bg5',
      text: 'What are you looking for in your next job?'
    },
    {
      id: 'bg6',
      text: 'How would you describe your working style?'
    }
  ],
  situational: [
    {
      id: 'sit1',
      text: 'Please share a time when you set a goal for yourself and achieved it. How did you go about that?'
    },
    {
      id: 'sit2',
      text: 'Tell me about a time when you delivered results despite a challenging environment or context. What was the situation, what was your goal, and what were the results?'
    },
    {
      id: 'sit3',
      text: 'Describe a situation when you disagreed with someone at work. What did you do, and what was the result?'
    },
    {
      id: 'sit4',
      text: 'Tell me about a time when you had to develop a new skill. How did you approach the learning process?'
    },
    {
      id: 'sit5',
      text: 'Tell me about a time you had to deliver on multiple competing priorities. What did you do, and what were the results?'
    },
    {
      id: 'sit6',
      text: 'Tell me about a time when you had to act quickly but didn\'t have a lot of data to inform your decision. What did you do, and what was the outcome?'
    },
    {
      id: 'sit7',
      text: 'Tell me about a time you made a mistake. How did you communicate that mistake?'
    },
    {
      id: 'sit8',
      text: 'When do you try to push through and solve a problem on your own, and when do you involve others on your team?'
    }
  ]
};