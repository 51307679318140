// src/components/activities/QuestionModal.tsx
import { motion } from "framer-motion";
import { Gamepad } from "lucide-react";
import { useState, useEffect, useRef, useCallback } from "react";
import { useTrophies } from "@/hooks/use-trophies";
import successSound from "../../../public/success.mp3";
import incorrectSound from "../../../public/incorrect.mp3";

interface QuestionOption {
  text: string;
  value: string;
}

interface Question {
  id: string;
  sectionId: string;
  difficulty: "easy" | "medium" | "hard";
  question: string;
  options: QuestionOption[];
  correctAnswer: string;
}

const allQuestions: Question[] = [
  {
    id: "r1", sectionId: "readiness", difficulty: "easy", question: "When attending a job interview, what's generally MOST important to bring?",
    options: [ { text: "Your passport or driver's licence for identification purposes only.", value: "A" }, { text: "A detailed list of your salary demands and desired benefits.", value: "B" }, { text: "Multiple copies of your CV, a pen, and potentially prepared questions.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r2", sectionId: "readiness", difficulty: "easy", question: "To make a good impression, how early should you aim to arrive for an in-person interview?",
    options: [ { text: "Precisely at the scheduled time to demonstrate good punctuality.", value: "A" }, { text: "A practical window of about 10-15 minutes before the start time.", value: "B" }, { text: "Approximately 30-40 minutes early to allow plenty of buffer time.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r6", sectionId: "readiness", difficulty: "easy", question: "What is generally the safest guideline for interview attire if unsure of the company culture?",
    options: [ { text: "The most expensive designer outfit that you currently own.", value: "A" }, { text: "Clean, professional attire leaning towards smart casual or formal.", value: "B" }, { text: "Your most comfortable and casual clothes to feel relaxed.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r9", sectionId: "readiness", difficulty: "easy", question: "What is the best practice regarding your mobile phone during an interview?",
    options: [ { text: "Turn it completely off or silent and ensure it is kept out of sight.", value: "A" }, { text: "Keep it in your pocket on silent mode, checking only if urgent.", value: "B" }, { text: "Set the phone to vibrate mode and place it visibly on the table.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "r10", sectionId: "readiness", difficulty: "easy", question: "In the context of job applications, what does 'CV' typically stand for?",
    options: [ { text: "The standard abbreviation for 'Curriculum Vitae'.", value: "A" }, { text: "A term meaning 'Company Vacancy' information.", value: "B" }, { text: "An acronym for 'Candidate Verification' documents.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "r11", sectionId: "readiness", difficulty: "easy", question: "Which of these is a very common opening question in job interviews?",
    options: [ { text: "Can you describe your biggest weaknesses in detail first?", value: "A" }, { text: "Do you have any immediate questions prepared for me?", value: "B" }, { text: "Could you start by telling me a little bit about yourself?", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r12", sectionId: "readiness", difficulty: "easy", question: "What is a standard professional way to greet an interviewer upon meeting them?",
    options: [ { text: "Offer a polite greeting, firm handshake if appropriate, and good eye contact.", value: "A" }, { text: "Wait silently without speaking until they initiate the formal conversation.", value: "B" }, { text: "Give a casual wave across the room or offer a relaxed fist bump.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "r21", sectionId: "readiness", difficulty: "easy", question: "What is the primary function of a CV in a job application?",
    options: [ { text: "To showcase your unique personality using creative document formatting.", value: "A" }, { text: "To offer a concise summary of relevant skills, experience, and education.", value: "B" }, { text: "To primarily provide the contact details for professional references.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r22", sectionId: "readiness", difficulty: "easy", question: "Regarding references on your CV during the initial application stage, what is common practice?",
    options: [ { text: "Listing at least three professional references with their full contact details.", value: "A" }, { text: "Including personal character references like friends or family members.", value: "B" }, { text: "Stating 'References available upon request' or omitting the section entirely.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r23", sectionId: "readiness", difficulty: "easy", question: "Which action best demonstrates active listening during an interview?",
    options: [ { text: "Regularly finishing the interviewer's sentences to show comprehension.", value: "A" }, { text: "Mentally planning your next detailed answer while they are speaking.", value: "B" }, { text: "Nodding occasionally, maintaining eye contact, and giving relevant responses.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r24", sectionId: "readiness", difficulty: "easy", question: "If you don't fully understand an interviewer's question, is it acceptable to ask for clarification?",
    options: [ { text: "Yes, politely ask for clarification to ensure you answer appropriately.", value: "A" }, { text: "No, attempt to guess their meaning to avoid appearing unprepared.", value: "B" }, { text: "Only if you have strong reason to suspect it is a trick question.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "r3", sectionId: "readiness", difficulty: "medium", question: "The STAR method (Situation, Task, Action, Result) is particularly useful for structuring answers to which type of interview question?",
    options: [ { text: "Queries testing your detailed knowledge about the company history.", value: "A" }, { text: "Behavioural questions asking for specific examples from your past.", value: "B" }, { text: "Direct questions regarding your future long-term career goals.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r4", sectionId: "readiness", difficulty: "medium", question: "Why is researching a company before an interview considered crucial?",
    options: [ { text: "Mainly to discover the exact annual profit figures they reported.", value: "A" }, { text: "To show genuine interest, tailor answers, and prepare relevant questions.", value: "B" }, { text: "Primarily to check if any of your current friends work there now.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r7", sectionId: "readiness", difficulty: "medium", question: "When invited to ask questions at the end of an interview, which type is generally MOST effective?",
    options: [ { text: "Direct questions about salary or time off before receiving an offer.", value: "A" }, { text: "Basic questions easily answered on their website's 'About Us' page.", value: "B" }, { text: "Thoughtful questions on role specifics, team dynamics, or culture.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r13", sectionId: "readiness", difficulty: "medium", question: "What is the interviewer primarily assessing when they ask, 'Do you have any questions for us?'",
    options: [ { text: "Your confidence in asking potentially challenging questions.", value: "A" }, { text: "Your level of interest, engagement, and critical thought.", value: "B" }, { text: "Your ability to recall specific details mentioned earlier.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r14", sectionId: "readiness", difficulty: "medium", question: "What is an effective strategy for preparing for common interview questions?",
    options: [ { text: "Memorise perfectly scripted answers for every possible question.", value: "A" }, { text: "Rely solely on your CV details and answer questions spontaneously.", value: "B" }, { text: "Prepare specific examples using STAR and practice articulating them.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r15", sectionId: "readiness", difficulty: "medium", question: "Which combination best describes positive and engaged body language during an interview?",
    options: [ { text: "Constant fidgeting, frequently looking around, and using large gestures.", value: "A" }, { text: "Leaning far back with arms crossed and avoiding direct eye contact.", value: "B" }, { text: "Sitting upright, leaning slightly forward, maintaining good eye contact.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r16", sectionId: "readiness", difficulty: "medium", question: "For a successful online/video interview, what technical checks are essential beforehand?",
    options: [ { text: "Have snacks and drinks ready and visible on camera during the call.", value: "A" }, { text: "Ensure your background looks interesting and shows your personality.", value: "B" }, { text: "Check your internet, camera, microphone, and platform are working well.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r25", sectionId: "readiness", difficulty: "medium", question: "How should you ideally address a significant gap in your employment history during an interview?",
    options: [ { text: "Briefly explain honestly and focus on skills gained during that time.", value: "A" }, { text: "Mention it very briefly and quickly change the subject.", value: "B" }, { text: "Invent a fictional short-term role or project to fill the gap.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "r26", sectionId: "readiness", difficulty: "medium", question: "Beyond a standard face-to-face meeting, what are other common interview formats?",
    options: [ { text: "Group interviews where candidates directly compete on tasks.", value: "A" }, { text: "Only one-on-one meetings held in the company's main office.", value: "B" }, { text: "Phone screens, video calls, panel interviews, or assessment centres.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r27", sectionId: "readiness", difficulty: "medium", question: "When an interviewer asks why you left your previous job, what are they likely trying to understand?",
    options: [ { text: "Specifically if you resigned voluntarily or were dismissed.", value: "A" }, { text: "Your career motivations and professionalism in leaving roles.", value: "B" }, { text: "Whether you will speak negatively about your former employer.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r28", sectionId: "readiness", difficulty: "medium", question: "Why is it often recommended to tailor your CV for each specific job application?",
    options: [ { text: "Simply to make your overall CV appear significantly longer.", value: "A" }, { text: "To highlight skills and experiences most relevant to that specific role.", value: "B" }, { text: "Mainly because it demonstrates you possess ample free time.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r5", sectionId: "readiness", difficulty: "hard", question: "When asked about your greatest weakness, what represents the MOST effective and genuine approach?",
    options: [ { text: "Confidently claim that you do not possess any real weaknesses.", value: "A" }, { text: "Mention a genuine minor weakness and explain steps you take to improve.", value: "B" }, { text: "Describe an essential job skill as a weakness you are addressing.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "r8", sectionId: "readiness", difficulty: "hard", question: "During a video interview, how can you best convey enthusiasm and engagement?",
    options: [ { text: "Use exaggerated facial expressions and nod continuously throughout.", value: "A" }, { text: "Ensure your background clearly shows items reflecting your interests.", value: "B" }, { text: "Maintain camera focus, use natural smiles, vary tone, and listen actively.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r17", sectionId: "readiness", difficulty: "hard", question: "What is a recommended professional action immediately following a job interview?",
    options: [ { text: "Send connection requests to interviewers on personal social media.", value: "A" }, { text: "Call the HR department early the next day asking for a decision.", value: "B" }, { text: "Send a brief, personalised thank-you email or note within 24-48 hours.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r18", sectionId: "readiness", difficulty: "hard", question: "If asked about salary expectations early in the interview process, what is a common strategy?",
    options: [ { text: "Politely defer or provide a researched range, noting it is negotiable.", value: "A" }, { text: "Give a precise, high salary number that is non-negotiable.", value: "B" }, { text: "State the absolute lowest salary figure you would ever accept.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "r19", sectionId: "readiness", difficulty: "hard", question: "How should you respond if an interviewer asks a question you believe is inappropriate or illegal (e.g., about age, marital status, plans for children)?",
    options: [ { text: "Refuse to answer the question and immediately end the interview.", value: "A" }, { text: "Answer the question honestly but state that you felt uncomfortable.", value: "B" }, { text: "Politely deflect, question relevance, or state you won't answer personal questions.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r20", sectionId: "readiness", difficulty: "hard", question: "Which combination of strategies is most effective for managing interview nerves?",
    options: [ { text: "Avoid thinking about the interview until just before it starts.", value: "A" }, { text: "Concentrate solely on making the best possible impression.", value: "B" }, { text: "Prepare thoroughly, practice answers, use relaxation techniques.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r29", sectionId: "readiness", difficulty: "hard", question: "What is a professional and constructive way to handle rejection after an interview?",
    options: [ { text: "Request detailed reasons why other candidates were preferred.", value: "A" }, { text: "Delete the rejection message and avoid future company contact.", value: "B" }, { text: "Respond politely, thank them, and optionally ask for feedback.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r30", sectionId: "readiness", difficulty: "hard", question: "What does 'company culture' primarily refer to, and why is assessing it important during your job search?",
    options: [ { text: "The average age demographic and typical employee tenure length.", value: "A" }, { text: "The overall aesthetic design and physical office layout features.", value: "B" }, { text: "Shared values, beliefs, behaviours, and work environment; key for fit.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r31", sectionId: "readiness", difficulty: "hard", question: "After receiving a job offer, how should you approach salary or benefits negotiation?",
    options: [ { text: "Accept the first offer immediately via email to show keenness.", value: "A" }, { text: "Delay your response for several days to imply other offers.", value: "B" }, { text: "Express enthusiasm, then politely ask if terms are negotiable.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "r32", sectionId: "readiness", difficulty: "hard", question: "Asking about professional development or training opportunities during an interview primarily demonstrates what to the employer?",
    options: [ { text: "An underlying feeling that you lack the necessary job skills.", value: "A" }, { text: "Ambition, commitment to growth, and interest in a future there.", value: "B" }, { text: "An expectation the company will fund external certifications.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "w1", sectionId: "workplace", difficulty: "easy", question: "If you're given a task at work but are unsure about the first step, what should you do?",
    options: [ { text: "Ask your supervisor or a colleague for clarification/guidance.", value: "A" }, { text: "Put the task aside and wait until someone notices you need help.", value: "B" }, { text: "Make your best educated guess to demonstrate initiative.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w2", sectionId: "workplace", difficulty: "easy", question: "In most workplaces, what does 'being punctual' primarily entail?",
    options: [ { text: "Arriving precisely at your start time or slightly after.", value: "A" }, { text: "Consistently ensuring you leave work exactly on time daily.", value: "B" }, { text: "Being ready for work at your start time and on time for meetings.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w6", sectionId: "workplace", difficulty: "easy", question: "If you anticipate being late for work, what is the BEST way to handle it?",
    options: [ { text: "Wait until you arrive at work then explain the main reason.", value: "A" }, { text: "Inform your supervisor directly ASAP with an honest ETA.", value: "B" }, { text: "Quickly message a coworker asking them to inform the manager.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "w9", sectionId: "workplace", difficulty: "easy", question: "Unless a specific uniform or dress code is stated, what is a safe assumption for workplace attire?",
    options: [ { text: "Any type of clothing is generally acceptable if it is clean.", value: "A" }, { text: "Attire should be clean, neat, professional, and role-appropriate.", value: "B" }, { text: "Standard practice is casual clothes like jeans and t-shirts.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "w10", sectionId: "workplace", difficulty: "easy", question: "What is the most considerate approach for handling necessary personal phone calls during work hours?",
    options: [ { text: "Step away to a private area and keep the personal call brief.", value: "A" }, { text: "Use headphones for privacy and speak softly at your desk.", value: "B" }, { text: "Take the call quietly at your desk if you expect it to be brief.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w11", sectionId: "workplace", difficulty: "easy", question: "What is a key aspect of etiquette when using shared office equipment (e.g., printer, microwave)?",
    options: [ { text: "Use respectfully, clean up after yourself, and report issues.", value: "A" }, { text: "Assume a designated person handles all refills and cleaning.", value: "B" }, { text: "Use it very quickly, even if it results in leaving a small mess.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w20", sectionId: "workplace", difficulty: "easy", question: "What elements contribute to a professional phone greeting when answering your work line?",
    options: [ { text: "Simply stating your first name clearly when answering.", value: "A" }, { text: "Greeting, company/dept name, your name, offer assistance.", value: "B" }, { text: "Answering with 'Hello?' then waiting for the caller to speak first.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "w21", sectionId: "workplace", difficulty: "easy", question: "Beyond personal preference, why is maintaining a reasonably tidy workspace generally encouraged?",
    options: [ { text: "It strongly discourages colleagues from borrowing your items.", value: "A" }, { text: "It helps give the appearance you have less work to do.", value: "B" }, { text: "It projects professionalism, aids organisation, and meets policy.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w22", sectionId: "workplace", difficulty: "easy", question: "Where is the most appropriate place to find official information about company policies (e.g., leave, expenses)?",
    options: [ { text: "Searching online forums for general employment advice.", value: "A" }, { text: "Asking the colleague who possesses the longest tenure.", value: "B" }, { text: "Check handbook, intranet/portal, or ask HR/your manager.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w23", sectionId: "workplace", difficulty: "easy", question: "What are good practices before leaving work at the end of the day?",
    options: [ { text: "Leave helpful sticky notes on your desk for the morning.", value: "A" }, { text: "Check emails, tidy desk, secure info, and log out properly.", value: "B" }, { text: "Shutting down your computer precisely at the closing time.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "w3", sectionId: "workplace", difficulty: "medium", question: "What is the most productive way to react to constructive criticism from your manager?",
    options: [ { text: "Listen, ask questions, thank them, and reflect on improving.", value: "A" }, { text: "Politely explain in detail why their criticism is inaccurate.", value: "B" }, { text: "Acknowledge it briefly then disregard it if you disagree.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w4", sectionId: "workplace", difficulty: "medium", question: "Which behaviour is most indicative of effective teamwork?",
    options: [ { text: "Taking charge and directing others even if not the leader.", value: "A" }, { text: "Open communication, listening, supporting colleagues, sharing info.", value: "B" }, { text: "Ensuring your tasks are perfect, regardless of team progress.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "w7", sectionId: "workplace", difficulty: "medium", question: "How does maintaining a generally positive and professional attitude benefit you and the workplace?",
    options: [ { text: "It improves morale, collaboration, problem-solving, and resilience.", value: "A" }, { text: "It essentially guarantees you receive faster promotions.", value: "B" }, { text: "It helps prevent other colleagues from taking advantage of you.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w12", sectionId: "workplace", difficulty: "medium", question: "What does it mean to 'take initiative' in a workplace setting?",
    options: [ { text: "Consistently starting your assigned work exactly on time daily.", value: "A" }, { text: "Regularly volunteering to organise the team's social events.", value: "B" }, { text: "Identifying opportunities or problems and acting without being asked.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w13", sectionId: "workplace", difficulty: "medium", question: "When communicating important work-related information to colleagues, what approach is generally best?",
    options: [ { text: "Communicate clearly, concisely, using the appropriate channel.", value: "A" }, { text: "Mentioning it casually in conversation hoping they remember.", value: "B" }, { text: "Send extremely detailed emails covering every possible angle.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w14", sectionId: "workplace", difficulty: "medium", question: "Which of the following demonstrates good professional email etiquette?",
    options: [ { text: "Adding many colleagues to the CC line for every minor update sent.", value: "A" }, { text: "Flagging every single email message sent as 'Urgent' priority.", value: "B" }, { text: "Using clear subjects, proofreading carefully, responding promptly.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w15", sectionId: "workplace", difficulty: "medium", question: "If you realise you've made a mistake at work, what is generally the most professional course of action?",
    options: [ { text: "Acknowledge it, inform relevant parties, take responsibility.", value: "A" }, { text: "Subtly try to shift the blame onto a systemic issue or colleague.", value: "B" }, { text: "Wait to see if anyone important notices before saying anything.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w24", sectionId: "workplace", difficulty: "medium", question: "Which is a recognised technique for effective time management and task prioritisation?",
    options: [ { text: "Completing all the quickest, easiest tasks first for momentum.", value: "A" }, { text: "Focus intensely on one task until absolutely perfect.", value: "B" }, { text: "Use lists/calendars and prioritise by urgency and importance.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w25", sectionId: "workplace", difficulty: "medium", question: "What is a sensible guideline for interacting with colleagues on personal social media?",
    options: [ { text: "Maintain professionalism, be mindful posts can be seen widely.", value: "A" }, { text: "Freely share any workplace gossip and daily frustrations online.", value: "B" }, { text: "Only ever connect with colleagues you are close friends with.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w26", sectionId: "workplace", difficulty: "medium", question: "Why is reviewing a meeting's agenda beforehand considered important?",
    options: [ { text: "Primarily to decide whether the meeting is worth your attendance.", value: "A" }, { text: "Mainly to accurately plan your specific verbal contributions.", value: "B" }, { text: "To understand purpose, prepare information, and participate well.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w27", sectionId: "workplace", difficulty: "medium", question: "Being 'proactive' in the workplace generally means:",
    options: [ { text: "Making sure you are highly visible and vocal in team meetings.", value: "A" }, { text: "Consistently responding very quickly to all emails and requests.", value: "B" }, { text: "Anticipating needs/issues and taking steps before being asked.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w5", sectionId: "workplace", difficulty: "hard", question: "You observe a coworker engaging in behaviour that seems to violate a significant company policy (e.g., safety, data security). What is the MOST appropriate initial response?",
    options: [ { text: "Address directly if safe, or report via designated confidential channels.", value: "A" }, { text: "Confront the coworker loudly in public view to deter the behaviour.", value: "B" }, { text: "Secretly document their behaviour waiting for a pattern to emerge.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w8", sectionId: "workplace", difficulty: "hard", question: "What is a key strategy for proactively managing your workload and preventing potential burnout?",
    options: [ { text: "Prioritise tasks, politely negotiate requests, communicate limits.", value: "A" }, { text: "Consistently working significantly longer hours to complete all tasks.", value: "B" }, { text: "Delegate your most time-consuming tasks to junior colleagues.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w16", sectionId: "workplace", difficulty: "hard", question: "What is generally the recommended first step when handling a conflict with a coworker over a work-related issue?",
    options: [ { text: "Discuss the ongoing conflict with other colleagues to get support.", value: "A" }, { text: "Document the full issue then immediately escalate to your manager.", value: "B" }, { text: "Attempt to discuss it privately and respectfully with the coworker first.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w17", sectionId: "workplace", difficulty: "hard", question: "Maintaining confidentiality in a professional context primarily involves:",
    options: [ { text: "Protecting company, client, and employee data from unauthorised access.", value: "A" }, { text: "Only ever sharing truly sensitive information with your direct manager.", value: "B" }, { text: "Carefully avoiding participation in any office gossip or rumours.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w18", sectionId: "workplace", difficulty: "hard", question: "If you strongly disagree with a decision made by your direct manager, what is the most constructive way to express your concerns?",
    options: [ { text: "Implement the decision but subtly express reservations to colleagues.", value: "A" }, { text: "Request private meeting, explain perspective respectfully, accept final call.", value: "B" }, { text: "Voice disagreement in a team meeting hoping others share your view.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "w19", sectionId: "workplace", difficulty: "hard", question: "Which action most effectively contributes to fostering a positive and inclusive work environment?",
    options: [ { text: "Organising regular team-building activities focused on shared interests.", value: "A" }, { text: "Treating every single employee exactly the same by ignoring differences.", value: "B" }, { text: "Respect diverse perspectives, ensure people feel heard, challenge bias.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w28", sectionId: "workplace", difficulty: "hard", question: "What is the typical role and benefit of having a mentor in your professional development?",
    options: [ { text: "A mentor advocates directly for your next promotion to management.", value: "A" }, { text: "Mentor provides guidance, shares experience, offers advice, aids growth.", value: "B" }, { text: "A mentor mainly helps complete your most difficult assigned tasks.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "w29", sectionId: "workplace", difficulty: "hard", question: "You accidentally receive an email containing highly confidential information clearly not intended for you. What is the most ethical and professional response?",
    options: [ { text: "Read the confidential information carefully in case it is relevant.", value: "A" }, { text: "Forward the complete email to your manager for their awareness.", value: "B" }, { text: "Discreetly notify sender, do not share contents, delete securely.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "w30", sectionId: "workplace", difficulty: "hard", question: "What are common challenges in cross-departmental collaboration, and how can they typically be mitigated?",
    options: [ { text: "Differing priorities/styles; mitigate via clear goals, roles, communication.", value: "A" }, { text: "Main challenge is resource competition; mitigate via budget approval.", value: "B" }, { text: "Lack of major challenges; collaboration is usually seamless always.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "w31", sectionId: "workplace", difficulty: "hard", question: "How can you provide constructive feedback upwards (e.g., to your manager) effectively and professionally?",
    options: [ { text: "Gather colleague support before presenting feedback as group concern.", value: "A" }, { text: "Use anonymous feedback channels as direct feedback is poorly received.", value: "B" }, { text: "Choose private setting, focus on specific observations/impact, be respectful.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s1", sectionId: "scenarios", difficulty: "easy", question: "A customer contacts you, clearly angry and upset about a problem with a product or service. What's the best initial approach?",
    options: [ { text: "Transfer their call directly to a manager without explanation.", value: "A" }, { text: "Immediately explain the relevant company policy to them.", value: "B" }, { text: "Listen calmly, acknowledge their feelings, apologise for issue.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s4", sectionId: "scenarios", difficulty: "easy", question: "You've completed all your assigned tasks slightly before the end of your workday. What's a proactive and professional action to take?",
    options: [ { text: "Start quietly packing up your belongings to leave on time.", value: "A" }, { text: "Use the remaining time for personal tasks or web Browse.", value: "B" }, { text: "Ask supervisor or colleague if they need help with anything.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s7", sectionId: "scenarios", difficulty: "easy", question: "You notice the shared office printer is out of paper while trying to print. What should you do?",
    options: [ { text: "Use a different printer assuming someone else will fix it.", value: "A" }, { text: "Refill it if paper is available, or notify the designated person.", value: "B" }, { text: "Leave a polite note on the printer asking someone to refill it.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s8", sectionId: "scenarios", difficulty: "easy", question: "A visitor arrives for a scheduled meeting with your manager, but your manager is briefly delayed. What is the appropriate action?",
    options: [ { text: "Greet politely, offer seat/drink, inform manager guest has arrived.", value: "A" }, { text: "Point them towards the waiting area without speaking directly.", value: "B" }, { text: "Politely suggest that they should come back later in 15 minutes.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "s9", sectionId: "scenarios", difficulty: "easy", question: "You answer the phone at work, and the caller asks for a colleague who is currently unavailable. What should you offer to do?",
    options: [ { text: "Offer to provide the colleague's personal mobile phone number.", value: "A" }, { text: "Politely explain unavailability and offer to take detailed message.", value: "B" }, { text: "Inform the caller the colleague is unavailable, suggest calling back.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s10", sectionId: "scenarios", difficulty: "easy", question: "You need to use a stapler quickly but yours is missing. What's the most appropriate action?",
    options: [ { text: "Submit a formal IT service request for a replacement stapler.", value: "A" }, { text: "Ask nearby colleague if you could borrow theirs briefly.", value: "B" }, { text: "Silently borrow one from the nearest unoccupied desk space.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s19", sectionId: "scenarios", difficulty: "easy", question: "A new colleague asks you where common office supplies like coffee mugs are kept.",
    options: [ { text: "Tell them supplies are usually near the main coffee machine.", value: "A" }, { text: "Suggest that they should ask the designated office manager.", value: "B" }, { text: "Politely show them the exact location or give clear directions.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s20", sectionId: "scenarios", difficulty: "easy", question: "You notice a small amount of rubbish (e.g., a used paper towel) on the floor in the shared office kitchen.",
    options: [ { text: "Pick the piece of rubbish up and place it in the nearest bin.", value: "A" }, { text: "Assume the professional cleaners will deal with it later on.", value: "B" }, { text: "Casually mention the rubbish to a colleague hoping they act.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "s21", sectionId: "scenarios", difficulty: "easy", question: "You need to make several copies using the shared photocopier, but a colleague is already using it for a large job.",
    options: [ { text: "Go back to your desk and complain about the long copier wait.", value: "A" }, { text: "Wait patiently, or ask how long they might be and return later.", value: "B" }, { text: "Interrupt politely and ask if you can make your copies first.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s22", sectionId: "scenarios", difficulty: "easy", question: "Your computer mouse suddenly stops working. What should be your initial steps?",
    options: [ { text: "Swap your mouse with colleague's while they are away.", value: "A" }, { text: "Check connection, restart computer, then contact IT if needed.", value: "B" }, { text: "Immediately submit a request to IT for a brand new mouse.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s2", sectionId: "scenarios", difficulty: "medium", question: "You realise you have accidentally deleted an important file from a shared drive. What should be your IMMEDIATE action?",
    options: [ { text: "Attempt to restore the deleted file from the recycle bin first.", value: "A" }, { text: "Inform your supervisor or IT immediately, explaining what happened.", value: "B" }, { text: "Ask several colleagues if anyone has a recent copy saved locally.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s5", sectionId: "scenarios", difficulty: "medium", question: "A colleague asks for your help with a task that isn't urgent, but you are busy working towards an important deadline. How should you respond professionally?",
    options: [ { text: "Agree to help immediately even if it risks your own deadline.", value: "A" }, { text: "Briefly state 'Sorry, I cannot help right now' and resume working.", value: "B" }, { text: "Explain deadline focus, offer help later, or suggest someone else.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s11", sectionId: "scenarios", difficulty: "medium", question: "You receive instructions from two different managers that seem to conflict. What is the best way to handle this?",
    options: [ { text: "Choose the specific instruction that seems easiest or most logical.", value: "A" }, { text: "Politely seek clarification promptly from supervisor or both managers.", value: "B" }, { text: "Simply follow the instructions from your direct line manager.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s12", sectionId: "scenarios", difficulty: "medium", question: "You notice a colleague seems overwhelmed and is falling behind on their share of a team project.",
    options: [ { text: "Focus on completing your own project tasks extra quickly.", value: "A" }, { text: "Discreetly offer help/support, or suggest they talk to supervisor.", value: "B" }, { text: "Privately inform your manager the colleague is not keeping up.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s13", sectionId: "scenarios", difficulty: "medium", question: "You are invited to an important team meeting, but you have a prior, immovable commitment (e.g., client appointment).",
    options: [ { text: "Accept meeting invite but don't attend, assume updates later.", value: "A" }, { text: "Inform organiser ASAP, explain briefly, ask how to catch up.", value: "B" }, { text: "Simply decline the meeting invitation without any explanation.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s14", sectionId: "scenarios", difficulty: "medium", question: "Your computer crashes while you're in the middle of working on an important document you haven't saved for a while.",
    options: [ { text: "Restart, check for auto-recovery files, then contact IT support.", value: "A" }, { text: "Complain directly to the IT department about faulty equipment.", value: "B" }, { text: "Immediately assume all the recent work is lost and start over.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "s23", sectionId: "scenarios", difficulty: "medium", question: "You are assigned a task that you have no prior experience with and feel requires skills you haven't developed yet.",
    options: [ { text: "Try to delegate the assigned task to a more experienced colleague.", value: "A" }, { text: "Be honest with manager, express willingness, ask for guidance.", value: "B" }, { text: "Attempt the task using online tutorials without informing anyone.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s24", sectionId: "scenarios", difficulty: "medium", question: "While reviewing a report your team is about to submit, you find a significant error.",
    options: [ { text: "Immediately bring the error to the attention of your team lead/manager.", value: "A" }, { text: "Make a personal note to mention the error after submission.", value: "B" }, { text: "Silently correct the error yourself hoping your version gets used.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "s25", sectionId: "scenarios", difficulty: "medium", question: "A specific colleague consistently arrives 5-10 minutes late for internal team meetings, delaying the start.",
    options: [ { text: "Complain about their consistent lateness to other team members.", value: "A" }, { text: "Raise pattern gently privately or suggest leader reinforces rules.", value: "B" }, { text: "Always start the meeting on time letting them catch up alone.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s26", sectionId: "scenarios", difficulty: "medium", question: "You find yourself overloaded with tasks and genuinely struggling to meet multiple deadlines.",
    options: [ { text: "Focus only on most interesting tasks, missing other deadlines.", value: "A" }, { text: "Communicate proactively with manager, explain load, ask for help.", value: "B" }, { text: "Work longer hours quietly until you eventually catch up.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s3", sectionId: "scenarios", difficulty: "hard", question: "Your project team has reached a deadlock, with strong disagreements on the best technical approach to move forward. What is a constructive role you could play?",
    options: [ { text: "Quickly align with the majority opinion to break deadlock.", value: "A" }, { text: "Propose compromise satisfying some points even if suboptimal.", value: "B" }, { text: "Facilitate discussion on pros/cons, seek common ground/data.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s6", sectionId: "scenarios", difficulty: "hard", question: "Your manager gives you instructions for a task that makes you feel ethically uncomfortable or seems potentially non-compliant.",
    options: [ { text: "Refuse the task citing personal ethics without more discussion.", value: "A" }, { text: "Seek clarification, express concerns, consult policy/ethics channels.", value: "B" }, { text: "Carry out the task but document all your concerns in writing.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s15", sectionId: "scenarios", difficulty: "hard", question: "A major client is demanding a service or concession that clearly goes against established company policy and could set a bad precedent.",
    options: [ { text: "Make a special exception for this client to keep them happy.", value: "A" }, { text: "Refer the demanding client directly to your line manager.", value: "B" }, { text: "Explain policy/reasons, explore alternative compliant solutions.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s16", sectionId: "scenarios", difficulty: "hard", question: "You discover a potentially costly error made by a respected senior colleague in a recently completed project.",
    options: [ { text: "Anonymously report the specific error via compliance hotline.", value: "A" }, { text: "Discuss error with other colleagues first to confirm their views.", value: "B" }, { text: "Discreetly raise issue with colleague or manager focusing on problem.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s17", sectionId: "scenarios", difficulty: "hard", question: "Your project is suffering from 'scope creep' - continuous small additions to the requirements without adjustments to resources or deadlines.",
    options: [ { text: "Politely push back strongly on all newly requested additions.", value: "A" }, { text: "Document changes/impact, discuss with manager to assess/adjust.", value: "B" }, { text: "Quietly absorb extra work hoping for recognition later on.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "s18", sectionId: "scenarios", difficulty: "hard", question: "A usually reliable team member is consistently missing deadlines on a critical project, impacting everyone. What's a constructive approach?",
    options: [ { text: "Speak privately first to understand issues, offer support, escalate if needed.", value: "A" }, { text: "Highlight missed deadlines publicly during the next team meeting.", value: "B" }, { text: "Silently take over the colleague's tasks to avoid delaying project.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "s27", sectionId: "scenarios", difficulty: "hard", question: "You consistently witness a colleague subtly taking credit for another team member's ideas during meetings.",
    options: [ { text: "Ignore the behaviour completely as interfering can be risky.", value: "A" }, { text: "Publicly correct the colleague in the meeting whenever it happens.", value: "B" }, { text: "Tactfully amplify original contributor; consider private talk or manager.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s28", sectionId: "scenarios", difficulty: "hard", question: "Your manager provides vague positive feedback like 'Good job' or vague negative feedback like 'Needs improvement'. How can you get more useful information?",
    options: [ { text: "Assume 'good job' means perfect, 'needs improvement' means failure.", value: "A" }, { text: "Ask colleagues later if they know what the manager really meant.", value: "B" }, { text: "Thank them for feedback, then politely ask for specific examples.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s29", sectionId: "scenarios", difficulty: "hard", question: "A colleague asks you to cover for them by slightly misrepresenting their progress on a task to the manager.",
    options: [ { text: "Immediately report the colleague's inappropriate request to HR.", value: "A" }, { text: "Agree to help them out this time as good teamwork requires it.", value: "B" }, { text: "Politely refuse to be dishonest, but offer genuine help instead.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "s30", sectionId: "scenarios", difficulty: "hard", question: "You've been given full responsibility for delivering a project outcome, but lack the necessary authority (e.g., over budget or resources) to ensure its success.",
    options: [ { text: "Proceed as if you have authority, informing relevant parties later.", value: "A" }, { text: "Meet manager to discuss authority gap, outline needs, propose solutions.", value: "B" }, { text: "Simply do your best within the current constraints and hope.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d1", sectionId: "digital", difficulty: "easy", question: "When setting passwords for your work accounts, what is a basic security principle?",
    options: [ { text: "Use one simple password for all work accounts for easy recall.", value: "A" }, { text: "Use guessable information like start date or department name.", value: "B" }, { text: "Create strong, unique passwords using mix of characters/numbers.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d4", sectionId: "digital", difficulty: "easy", question: "In a work email, when should you use the 'CC' (Carbon Copy) field?",
    options: [ { text: "For every single recipient included on the email message.", value: "A" }, { text: "To include people needing info but not required to act directly.", value: "B" }, { text: "To secretly inform your direct manager about the conversation.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d7", sectionId: "digital", difficulty: "easy", question: "When working on an important document on your work computer, what should you do frequently?",
    options: [ { text: "Regularly change the document font size to track progress.", value: "A" }, { text: "Constantly check your email inbox for any new messages.", value: "B" }, { text: "Save your work often using the 'Save' command or shortcut.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d8", sectionId: "digital", difficulty: "easy", question: "When saving a shared project document, what's a good practice for the file name?",
    options: [ { text: "Use clear name including project, type, and date/version info.", value: "A" }, { text: "Save the document using only your own first name identifier.", value: "B" }, { text: "Use a very simple generic name like 'Document1' or 'Final'.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "d9", sectionId: "digital", difficulty: "easy", question: "When should you use the 'Reply All' button in a work email chain?",
    options: [ { text: "Never use it; always reply only directly back to the sender.", value: "A" }, { text: "Only when your reply is relevant to ALL original recipients.", value: "B" }, { text: "Use it for every reply to ensure all recipients stay informed.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d10", sectionId: "digital", difficulty: "easy", question: "You receive an email asking you to click a link to update your company portal password urgently. What might this be?",
    options: [ { text: "A potential 'phishing' scam trying to steal login details.", value: "A" }, { text: "An important automated system notification you must obey.", value: "B" }, { text: "A completely standard security update procedure from IT.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "d20", sectionId: "digital", difficulty: "easy", question: "When should you use the 'BCC' (Blind Carbon Copy) field in a work email?",
    options: [ { text: "Whenever you are CC'ing your manager on an outgoing email.", value: "A" }, { text: "Each time you send an email containing one or more attachments.", value: "B" }, { text: "When sending to large lists where addresses shouldn't be shared.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d21", sectionId: "digital", difficulty: "easy", question: "What is the common keyboard shortcut for 'Copy' text or files on most work computers?",
    options: [ { text: "The combination Ctrl + V key press (or Cmd + V on Mac OS).", value: "A" }, { text: "The combination Ctrl + X key press (or Cmd + X on Mac OS).", value: "B" }, { text: "The combination Ctrl + C key press (or Cmd + C on Mac OS).", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d22", sectionId: "digital", difficulty: "easy", question: "What is the common keyboard shortcut for 'Paste' text or files on most work computers?",
    options: [ { text: "The combination Ctrl + V key press (or Cmd + V on Mac OS).", value: "A" }, { text: "The combination Ctrl + C key press (or Cmd + C on Mac OS).", value: "B" }, { text: "The combination Ctrl + P key press (or Cmd + P on Mac OS).", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "d23", sectionId: "digital", difficulty: "easy", question: "Why might a colleague send you a document as a PDF file?",
    options: [ { text: "Mainly because PDF files always have much smaller file sizes.", value: "A" }, { text: "To ensure formatting looks same for everyone, regardless of software.", value: "B" }, { text: "Primarily because PDF files are significantly easier to edit.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d2", sectionId: "digital", difficulty: "medium", question: "You receive an unexpected email at work with an attachment (e.g., 'Invoice.zip') from an unknown sender. What should you do?",
    options: [ { text: "Open the attachment quickly to check if it's relevant work.", value: "A" }, { text: "Forward the email directly to IT asking if it is safe to open.", value: "B" }, { text: "Be cautious, don't open attachment (could be malware), delete/report.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d5", sectionId: "digital", difficulty: "medium", question: "Why is it important to log out or lock your computer when leaving your desk, even for a short time?",
    options: [ { text: "It automatically clears the computer's short-term memory cache.", value: "A" }, { text: "To prevent unauthorised access to your work, data, or account.", value: "B" }, { text: "It helps the computer save electricity and run more efficiently.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d11", sectionId: "digital", difficulty: "medium", question: "Besides suspicious links or attachments, what's another common sign of a phishing email in a workplace context?",
    options: [ { text: "The email includes the official company logo in the signature.", value: "A" }, { text: "The email message is received during normal work hours.", value: "B" }, { text: "Generic greetings, urgent tone, requests for info, odd sender address.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d12", sectionId: "digital", difficulty: "medium", question: "When using a shared team drive (like Google Drive or SharePoint), why is it important to follow agreed folder structures?",
    options: [ { text: "Primarily to limit access permissions for certain sensitive files.", value: "A" }, { text: "So team members can find files easily, avoid duplicates, manage versions.", value: "B" }, { text: "It doesn't matter where files are saved if they are on the drive.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d13", sectionId: "digital", difficulty: "medium", question: "During a professional video conference call, what is considered good etiquette regarding your microphone?",
    options: [ { text: "Keep microphone unmuted for the entire meeting duration always.", value: "A" }, { text: "Mute your microphone when not speaking to reduce background noise.", value: "B" }, { text: "Assume others will inform you if your background becomes noisy.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d14", sectionId: "digital", difficulty: "medium", question: "What is a key risk of using easily guessable passwords (like 'Password123' or your name) for work accounts?",
    options: [ { text: "They can be easily cracked, potentially giving access to sensitive data.", value: "A" }, { text: "That you might forget these simple passwords more easily.", value: "B" }, { text: "They may not meet the minimum length requirement of IT policy.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "d24", sectionId: "digital", difficulty: "medium", question: "When using workplace instant messaging (like Slack or Teams), what's a key aspect of professional etiquette?",
    options: [ { text: "Contacting colleagues outside work hours for any urgent matters.", value: "A" }, { text: "Use direct messages always to avoid cluttering team channels.", value: "B" }, { text: "Be concise, professional, mindful of tone, respect status indicators.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d25", sectionId: "digital", difficulty: "medium", question: "You need to share a large file with a colleague. What is generally a better method than emailing it as an attachment?",
    options: [ { text: "Uploading it via a personal free online file-sharing website.", value: "A" }, { text: "Printing out a physical copy of the file for them to review.", value: "B" }, { text: "Using a company-approved cloud storage or shared drive link.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d26", sectionId: "digital", difficulty: "medium", question: "In a typical office role, basic proficiency in which types of software is often expected?",
    options: [ { text: "Word processing, spreadsheet software, and standard email clients.", value: "A" }, { text: "Advanced statistical analysis packages like SPSS or R Studio.", value: "B" }, { text: "Complex video game development software and related engines.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "d27", sectionId: "digital", difficulty: "medium", question: "Why is it generally advisable to save work files onto company network drives or approved cloud storage rather than just your local computer's desktop?",
    options: [ { text: "It uses significantly less storage space on your local hard drive.", value: "A" }, { text: "Saving files locally helps make your computer operate faster.", value: "B" }, { text: "Network/cloud files are usually backed up regularly by the company.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d3", sectionId: "digital", difficulty: "hard", question: "Your workplace policy prohibits installing unauthorised software. What is the primary reason for this rule?",
    options: [ { text: "Mainly to help save on the company's overall IT support costs.", value: "A" }, { text: "To prevent malware, ensure license compliance, maintain system security.", value: "B" }, { text: "To strictly ensure all employees are using identical software versions.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d6", sectionId: "digital", difficulty: "hard", question: "Your company implements Multi-Factor Authentication (MFA) for logging into work systems. What does this mean for you?",
    options: [ { text: "You can likely only log in successfully from specific company devices.", value: "A" }, { text: "Need password plus second step (e.g., app code, SMS) for identity check.", value: "B" }, { text: "You will now need to set a much more complex account password.", value: "C" } ],
    correctAnswer: "B",
  },
  {
    id: "d15", sectionId: "digital", difficulty: "hard", question: "You need to send a report containing sensitive client data to an external partner. According to typical data privacy practices, what should you confirm first?",
    options: [ { text: "That the intended recipient's email address is spelled correctly.", value: "A" }, { text: "That the final report file size is not too large to email easily.", value: "B" }, { text: "Company policy and the approved secure method for external transfer.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d16", sectionId: "digital", difficulty: "hard", question: "Why is it often against company policy to use personal USB drives on work computers?",
    options: [ { text: "Because personal USB drives can be very easily lost or misplaced.", value: "A" }, { text: "They are usually not technically compatible with most work computers.", value: "B" }, { text: "Can introduce malware, bypass security, allow unauthorised data transfer.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d17", sectionId: "digital", difficulty: "hard", question: "When working remotely, using a company-provided VPN (Virtual Private Network) primarily helps to:",
    options: [ { text: "Establish secure, encrypted connection to company network, protect data.", value: "A" }, { text: "Allow access social media sites normally restricted on company net.", value: "B" }, { text: "Noticeably increase your normal home internet connection speed.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "d18", sectionId: "digital", difficulty: "hard", question: "How can your activity on personal social media potentially impact your professional life, even if settings are 'private'?",
    options: [ { text: "Screenshots, shares, unprofessional posts may still surface/affect reputation.", value: "A" }, { text: "Potential employers usually only ever check professional network sites.", value: "B" }, { text: "It has absolutely no impact as long as your profile is set to private.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "d19", sectionId: "digital", difficulty: "hard", question: "Your company has a 'clear desk and clear screen' policy. What does the 'clear screen' part typically require?",
    options: [ { text: "Closing down all open software applications before leaving work.", value: "A" }, { text: "Mandates using an approved privacy screen filter on your monitor.", value: "B" }, { text: "Logging off or locking computer when leaving desk to protect info.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d28", sectionId: "digital", difficulty: "hard", question: "Your workplace requires strong, complex passwords that must be changed frequently. What is the BEST practice for managing these securely?",
    options: [ { text: "Write work passwords down carefully in a notebook kept at desk.", value: "A" }, { text: "Use sequential password variations for easy recall (e.g., PassW1, PassW2).", value: "B" }, { text: "Use reputable password manager app to generate/store unique passwords.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d29", sectionId: "digital", difficulty: "hard", question: "You find a useful resource online (e.g., an image, report) you want to use in a work presentation. What digital citizenship aspect should you consider?",
    options: [ { text: "Copyright/licensing; ensure permission/license allows usage, attribute.", value: "A" }, { text: "How quickly and easily you can copy and paste it directly.", value: "B" }, { text: "Whether the online resource appears sufficiently professional.", value: "C" } ],
    correctAnswer: "A",
  },
  {
    id: "d30", sectionId: "digital", difficulty: "hard", question: "What type of cyber threat involves tricking employees into making unauthorised payments or revealing sensitive data by impersonating senior management or trusted vendors via email?",
    options: [ { text: "A standard 'ransomware' attack designed to encrypt local files.", value: "A" }, { text: "A typical 'Denial-of-Service' (DoS) network attack pattern.", value: "B" }, { text: "'Business Email Compromise' (BEC) or specific 'CEO fraud' attempt.", value: "C" } ],
    correctAnswer: "C",
  },
  {
    id: "d31", sectionId: "digital", difficulty: "hard", question: "How can using features like version history and track changes in collaborative software (e.g., Google Docs, Microsoft Word) benefit teamwork?",
    options: [ { text: "It significantly complicates the overall document editing process.", value: "A" }, { text: "It automatically merges all different saved document versions.", value: "B" }, { text: "Allows seeing changes, reverting versions, improves transparency/accountability.", value: "C" } ],
    correctAnswer: "C",
  },
];


// const allQuestions: Question[] = [
//   {
//     id: "r1", sectionId: "readiness", difficulty: "easy", question: "When attending a job interview, what's generally MOST important to bring?",
//     options: [ { text: "A list of demands for the role", value: "A" }, { text: "Multiple copies of your CV, a pen, and prepared questions", value: "B" }, { text: "Only your identification documents", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r2", sectionId: "readiness", difficulty: "easy", question: "To make a good impression, how early should you aim to arrive for an in-person interview?",
//     options: [ { text: "Exactly on time, to show precision", value: "A" }, { text: "More than 30 minutes early, to be safe", value: "B" }, { text: "About 10-15 minutes early", value: "C" } ],
//     correctAnswer: "C",
//   },
//   {
//     id: "r6", sectionId: "readiness", difficulty: "easy", question: "What is generally the safest guideline for interview attire if unsure of the company culture?",
//     options: [ { text: "Your most comfortable casual clothes", value: "A" }, { text: "Clean, professional attire leaning towards smart casual or formal", value: "B" }, { text: "The most expensive designer outfit you own", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r9", sectionId: "readiness", difficulty: "easy", question: "What is the best practice regarding your mobile phone during an interview?",
//     options: [ { text: "Turn it completely off or on silent mode and keep it out of sight", value: "A" }, { text: "Set it to vibrate and place it on the table", value: "B" }, { text: "Keep it in your pocket on silent, checking it only if urgent", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r10", sectionId: "readiness", difficulty: "easy", question: "In the context of job applications, what does 'CV' typically stand for?",
//     options: [ { text: "Candidate Verification", value: "A" }, { text: "Curriculum Vitae", value: "B" }, { text: "Company Vacancy", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r11", sectionId: "readiness", difficulty: "easy", question: "Which of these is a very common opening question in job interviews?",
//     options: [ { text: "What are your biggest weaknesses?", value: "A" }, { text: "Can you tell me about yourself?", value: "B" }, { text: "Do you have any questions for me?", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r12", sectionId: "readiness", difficulty: "easy", question: "What is a standard professional way to greet an interviewer upon meeting them?",
//     options: [ { text: "With a polite greeting, firm handshake (if offered/appropriate), and good eye contact", value: "A" }, { text: "Wait silently for them to initiate the conversation", value: "B" }, { text: "Offer a fist bump or a casual wave", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r21", sectionId: "readiness", difficulty: "easy", question: "What is the primary function of a CV in a job application?",
//     options: [ { text: "To provide contact details for references", value: "A" }, { text: "To offer a concise summary of your relevant skills, experience, and education", value: "B" }, { text: "To showcase your personality through creative formatting", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r22", sectionId: "readiness", difficulty: "easy", question: "Regarding references on your CV during the initial application stage, what is common practice?",
//     options: [ { text: "Always list at least three professional references with full contact details", value: "A" }, { text: "State 'References available upon request' or omit the section entirely", value: "B" }, { text: "Include personal references like friends or family members", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r23", sectionId: "readiness", difficulty: "easy", question: "Which action best demonstrates active listening during an interview?",
//     options: [ { text: "Planning your next answer while the interviewer is speaking", value: "A" }, { text: "Nodding occasionally, maintaining eye contact, and providing relevant responses", value: "B" }, { text: "Finishing the interviewer's sentences to show you understand", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r24", sectionId: "readiness", difficulty: "easy", question: "If you don't fully understand an interviewer's question, is it acceptable to ask for clarification?",
//     options: [ { text: "No, try to guess what they meant to avoid looking unprepared", value: "A" }, { text: "Yes, it's better to politely ask them to rephrase or clarify than to answer incorrectly", value: "B" }, { text: "Only if you suspect it's a trick question", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r3", sectionId: "readiness", difficulty: "medium", question: "The STAR method (Situation, Task, Action, Result) is particularly useful for structuring answers to which type of interview question?",
//     options: [ { text: "Questions about your career goals", value: "A" }, { text: "Behavioural questions asking for specific examples (e.g., 'Tell me about a time when...')", value: "B" }, { text: "Questions about your knowledge of the company", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r4", sectionId: "readiness", difficulty: "medium", question: "Why is researching a company before an interview considered crucial?",
//     options: [ { text: "To know how much profit they made last year", value: "A" }, { text: "To demonstrate genuine interest, tailor your answers, and prepare relevant questions", value: "B" }, { text: "To find out if any of your friends work there", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r7", sectionId: "readiness", difficulty: "medium", question: "When invited to ask questions at the end of an interview, which type is generally MOST effective?",
//     options: [ { text: "Thoughtful questions about the role specifics, team dynamics, or company culture", value: "A" }, { text: "Questions easily answered by a quick look at their website's 'About Us' page", value: "B" }, { text: "Asking about salary, benefits, or time off before receiving an offer", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r13", sectionId: "readiness", difficulty: "medium", question: "What is the interviewer primarily assessing when they ask, 'Do you have any questions for us?'",
//     options: [ { text: "Whether you were paying attention during the interview", value: "A" }, { text: "Your level of interest, engagement, and critical thinking about the role/company", value: "B" }, { text: "Your ability to ask difficult or challenging questions", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r14", sectionId: "readiness", difficulty: "medium", question: "What is an effective strategy for preparing for common interview questions?",
//     options: [ { text: "Write out and memorise perfect answers for every possible question", value: "A" }, { text: "Reflect on your experiences, prepare specific examples (using STAR), and practice articulating them clearly", value: "B" }, { text: "Assume your CV speaks for itself and answer questions spontaneously", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r15", sectionId: "readiness", difficulty: "medium", question: "Which combination best describes positive and engaged body language during an interview?",
//     options: [ { text: "Leaning back with arms crossed, minimal eye contact", value: "A" }, { text: "Sitting upright, leaning slightly forward, maintaining good eye contact, and nodding appropriately", value: "B" }, { text: "Constant fidgeting, looking around the room, and using overly animated gestures", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r16", sectionId: "readiness", difficulty: "medium", question: "For a successful online/video interview, what technical checks are essential beforehand?",
//     options: [ { text: "Ensure your internet connection, camera, microphone, and interview platform are working correctly", value: "A" }, { text: "Check that your background is visually interesting and shows personality", value: "B" }, { text: "Make sure you have snacks and drinks readily available on camera", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r25", sectionId: "readiness", difficulty: "medium", question: "How should you ideally address a significant gap in your employment history during an interview?",
//     options: [ { text: "Gloss over it quickly and hope the interviewer doesn't notice", value: "A" }, { text: "Briefly and honestly explain the reason (if appropriate) and focus on any skills gained or productive activities undertaken", value: "B" }, { text: "Invent a short-term role or project to fill the gap", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r26", sectionId: "readiness", difficulty: "medium", question: "Beyond a standard face-to-face meeting, what are other common interview formats?",
//     options: [ { text: "Interviews are almost always one-on-one in person", value: "A" }, { text: "Initial phone screenings, video interviews, panel interviews, assessment centres, or technical tests", value: "B" }, { text: "Group interviews where candidates compete against each other", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r27", sectionId: "readiness", difficulty: "medium", question: "When an interviewer asks why you left your previous job, what are they likely trying to understand?",
//     options: [ { text: "If you have negative things to say about your former employer", value: "A" }, { text: "Your career motivations, decision-making process, and whether you leave roles professionally", value: "B" }, { text: "Whether you were fired or resigned", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r28", sectionId: "readiness", difficulty: "medium", question: "Why is it often recommended to tailor your CV for each specific job application?",
//     options: [ { text: "It shows you have a lot of free time", value: "A" }, { text: "To highlight the skills and experiences most relevant to the specific job description and company needs", value: "B" }, { text: "To make your CV longer and seem more impressive", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r5", sectionId: "readiness", difficulty: "hard", question: "When asked about your greatest weakness, what represents the MOST effective and genuine approach?",
//     options: [ { text: "Claim you don't have any weaknesses", value: "A" }, { text: "Mention a genuine, relatively minor weakness relevant to professional growth, and explain the concrete steps you're taking to improve", value: "B" }, { text: "Choose a skill essential for the job and present it as a weakness you're working on", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r8", sectionId: "readiness", difficulty: "hard", question: "During a video interview, how can you best convey enthusiasm and engagement?",
//     options: [ { text: "Exaggerate facial expressions and nod constantly", value: "A" }, { text: "Maintain focus on the camera (simulating eye contact), use natural smiles, vary vocal tone, and show active listening cues", value: "B" }, { text: "Ensure your background includes posters or items showing your interests", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r17", sectionId: "readiness", difficulty: "hard", question: "What is a recommended professional action immediately following a job interview?",
//     options: [ { text: "Connect with the interviewer on personal social media accounts", value: "A" }, { text: "Send a brief, personalised thank-you email or note to the interviewer(s) within 24-48 hours", value: "B" }, { text: "Call the HR department the next day to ask if a decision has been made", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r18", sectionId: "readiness", difficulty: "hard", question: "If asked about salary expectations early in the interview process, what is a common strategy?",
//     options: [ { text: "State the lowest figure you'd accept to seem flexible", value: "A" }, { text: "Attempt to politely defer the discussion until an offer is made, or provide a well-researched salary range based on the role and your experience, stating it's negotiable", value: "B" }, { text: "Provide a very specific, non-negotiable salary figure", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r19", sectionId: "readiness", difficulty: "hard", question: "How should you respond if an interviewer asks a question you believe is inappropriate or illegal (e.g., about age, marital status, plans for children)?",
//     options: [ { text: "Answer honestly but express discomfort", value: "A" }, { text: "Politely deflect the question by redirecting to your qualifications, question its relevance to the role, or state you're not comfortable answering personal questions", value: "B" }, { text: "Refuse to answer and end the interview immediately", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r20", sectionId: "readiness", difficulty: "hard", question: "Which combination of strategies is most effective for managing interview nerves?",
//     options: [ { text: "Focusing entirely on impressing the interviewer", value: "A" }, { text: "Thorough preparation, practicing answers, using relaxation techniques (like deep breathing), and reframing nervousness as excitement", value: "B" }, { text: "Avoiding thinking about the interview until the last minute", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r29", sectionId: "readiness", difficulty: "hard", question: "What is a professional and constructive way to handle rejection after an interview?",
//     options: [ { text: "Ask for detailed reasons why you weren't chosen compared to the successful candidate", value: "A" }, { text: "Respond politely, thank the interviewer for their time and consideration, and optionally, briefly ask if they have any feedback for your future development", value: "B" }, { text: "Delete the rejection email and avoid any future contact with the company", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r30", sectionId: "readiness", difficulty: "hard", question: "What does 'company culture' primarily refer to, and why is assessing it important during your job search?",
//     options: [ { text: "The aesthetic design of the office space and facilities", value: "A" }, { text: "The shared values, beliefs, behaviours, and overall work environment; crucial for determining your potential fit and job satisfaction", value: "B" }, { text: "The average age and tenure of the employees", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r31", sectionId: "readiness", difficulty: "hard", question: "After receiving a job offer, how should you approach salary or benefits negotiation?",
//     options: [ { text: "Accept the offer immediately via email to show keenness, without discussing terms", value: "A" }, { text: "Express enthusiasm for the offer, then politely inquire if specific terms (like salary) are negotiable, referencing research or your experience", value: "B" }, { text: "Delay responding for several days to imply you have other offers, then state your demands", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r32", sectionId: "readiness", difficulty: "hard", question: "Asking about professional development or training opportunities during an interview primarily demonstrates what to the employer?",
//     options: [ { text: "That you feel you lack necessary skills for the role", value: "A" }, { text: "Your ambition, commitment to growth, and interest in a long-term future with the company", value: "B" }, { text: "That you expect the company to pay for external certifications immediately", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w1", sectionId: "workplace", difficulty: "easy", question: "If you're given a task at work but are unsure about the first step, what should you do?",
//     options: [ { text: "Make your best guess to show initiative", value: "A" }, { text: "Politely ask your supervisor or an experienced colleague for clarification or guidance", value: "B" }, { text: "Put the task aside and wait for someone to notice you're stuck", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w2", sectionId: "workplace", difficulty: "easy", question: "In most workplaces, what does 'being punctual' primarily entail?",
//     options: [ { text: "Arriving exactly at your start time or a minute or two after", value: "A" }, { text: "Being ready to start work at your scheduled time and arriving on time for meetings", value: "B" }, { text: "Ensuring you leave work exactly on time each day", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w6", sectionId: "workplace", difficulty: "easy", question: "If you anticipate being late for work, what is the BEST way to handle it?",
//     options: [ { text: "Send a message to a coworker asking them to let the manager know", value: "A" }, { text: "Inform your supervisor directly as soon as possible, providing an honest estimated time of arrival (ETA)", value: "B" }, { text: "Wait until you arrive at work and then explain the reason for your lateness", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w9", sectionId: "workplace", difficulty: "easy", question: "Unless a specific uniform or dress code is stated, what is a safe assumption for workplace attire?",
//     options: [ { text: "Anything is acceptable as long as it's clean", value: "A" }, { text: "Dress in a way that is clean, neat, generally professional, and appropriate for your specific industry/role", value: "B" }, { text: "Wear casual clothes like jeans and t-shirts daily", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w10", sectionId: "workplace", difficulty: "easy", question: "What is the most considerate approach for handling necessary personal phone calls during work hours?",
//     options: [ { text: "Take the call quietly at your desk if it's brief", value: "A" }, { text: "Step away to a private area (like a break room or outside) and keep the call brief", value: "B" }, { text: "Use headphones and speak softly at your desk", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w11", sectionId: "workplace", difficulty: "easy", question: "What is a key aspect of etiquette when using shared office equipment (e.g., printer, microwave)?",
//     options: [ { text: "Assuming the designated person will refill supplies or clean up", value: "A" }, { text: "Using the equipment respectfully, cleaning up after yourself, and reporting any issues", value: "B" }, { text: "Using it quickly, even if it means leaving a small mess", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w20", sectionId: "workplace", difficulty: "easy", question: "What elements contribute to a professional phone greeting when answering your work line?",
//     options: [ { text: "Simply stating your name", value: "A" }, { text: "A polite greeting, stating the company/department name and your name, and offering assistance", value: "B" }, { text: "Answering with 'Hello?' and waiting for the caller to speak", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w21", sectionId: "workplace", difficulty: "easy", question: "Beyond personal preference, why is maintaining a reasonably tidy workspace generally encouraged?",
//     options: [ { text: "It makes it look like you have less work to do", value: "A" }, { text: "It projects a professional image, helps organisation, and ensures compliance with clear desk policies (if applicable)", value: "B" }, { text: "It prevents colleagues from borrowing your things", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w22", sectionId: "workplace", difficulty: "easy", question: "Where is the most appropriate place to find official information about company policies (e.g., leave, expenses)?",
//     options: [ { text: "Asking the colleague who has been there the longest", value: "A" }, { text: "Consulting the official employee handbook, company intranet/portal, or asking HR/your direct manager", value: "B" }, { text: "Searching online forums for general advice", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w23", sectionId: "workplace", difficulty: "easy", question: "What are good practices before leaving work at the end of the day?",
//     options: [ { text: "Shutting down your computer immediately at closing time", value: "A" }, { text: "Briefly checking for urgent emails/messages, tidying your workspace, and securing sensitive documents/logging out", value: "B" }, { text: "Leaving notes on your desk for the morning shift", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w3", sectionId: "workplace", difficulty: "medium", question: "What is the most productive way to react to constructive criticism from your manager?",
//     options: [ { text: "Politely explain why the criticism is inaccurate", value: "A" }, { text: "Listen actively, ask clarifying questions to ensure understanding, thank them for the feedback, and reflect on how to improve", value: "B" }, { text: "Acknowledge it briefly but disregard it if you disagree", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w4", sectionId: "workplace", difficulty: "medium", question: "Which behaviour is most indicative of effective teamwork?",
//     options: [ { text: "Ensuring your individual tasks are completed perfectly, regardless of team progress", value: "A" }, { text: "Open communication, active listening, offering support to colleagues, and sharing relevant information", value: "B" }, { text: "Taking charge and directing others, even when not the designated leader", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w7", sectionId: "workplace", difficulty: "medium", question: "How does maintaining a generally positive and professional attitude benefit you and the workplace?",
//     options: [ { text: "It prevents colleagues from taking advantage of you", value: "A" }, { text: "It tends to improve morale, foster better collaboration, enhance problem-solving, and build resilience", value: "B" }, { text: "It guarantees faster promotions and salary increases", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w12", sectionId: "workplace", difficulty: "medium", question: "What does it mean to 'take initiative' in a workplace setting?",
//     options: [ { text: "Starting work exactly on time every day", value: "A" }, { text: "Proactively identifying opportunities or potential problems and taking appropriate action without needing to be asked", value: "B" }, { text: "Volunteering to organise social events for the team", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w13", sectionId: "workplace", difficulty: "medium", question: "When communicating important work-related information to colleagues, what approach is generally best?",
//     options: [ { text: "Mentioning it casually in conversation and hoping they remember", value: "A" }, { text: "Communicating clearly, concisely, and using the appropriate channel (e.g., email for record, meeting for discussion) considering the audience", value: "B" }, { text: "Sending a very detailed email covering every possible angle", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w14", sectionId: "workplace", difficulty: "medium", question: "Which of the following demonstrates good professional email etiquette?",
//     options: [ { text: "Marking all emails as 'Urgent' to ensure they are read quickly", value: "A" }, { text: "Using a clear, concise subject line, proofreading for errors, responding within a reasonable timeframe, and maintaining a professional tone", value: "B" }, { text: "Using CC excessively to keep everyone informed about minor details", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w15", sectionId: "workplace", difficulty: "medium", question: "If you realise you've made a mistake at work, what is generally the most professional course of action?",
//     options: [ { text: "Wait to see if anyone notices before saying anything", value: "A" }, { text: "Acknowledge the mistake promptly, inform relevant parties (like your supervisor) if necessary, take responsibility, and focus on correcting it/learning from it", value: "B" }, { text: "Subtly shift blame to a systemic issue or another colleague", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w24", sectionId: "workplace", difficulty: "medium", question: "Which is a recognised technique for effective time management and task prioritisation?",
//     options: [ { text: "Completing the quickest tasks first, regardless of importance", value: "A" }, { text: "Using tools like to-do lists or calendars, and methods like the Eisenhower Matrix (Urgent/Important) to prioritise tasks", value: "B" }, { text: "Focusing on only one task until it's 100% perfect before moving to the next", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w25", sectionId: "workplace", difficulty: "medium", question: "What is a sensible guideline for interacting with colleagues on personal social media?",
//     options: [ { text: "Freely share workplace gossip and frustrations", value: "A" }, { text: "Maintain professionalism, be mindful that posts could be seen widely, avoid controversial or negative comments about work/colleagues", value: "B" }, { text: "Only connect with colleagues you consider close friends outside of work", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w26", sectionId: "workplace", difficulty: "medium", question: "Why is reviewing a meeting's agenda beforehand considered important?",
//     options: [ { text: "To plan your contributions or questions accurately", value: "A" }, { text: "To understand the meeting's purpose, prepare necessary information, and contribute effectively to the discussion", value: "B" }, { text: "To decide if the meeting is worth attending", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w27", sectionId: "workplace", difficulty: "medium", question: "Being 'proactive' in the workplace generally means:",
//     options: [ { text: "Responding quickly to emails and requests", value: "A" }, { text: "Anticipating future needs, challenges, or opportunities and taking steps to address them before being asked or before they become problems", value: "B" }, { text: "Being highly visible and vocal in team meetings", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w5", sectionId: "workplace", difficulty: "hard", question: "You observe a coworker engaging in behaviour that seems to violate a significant company policy (e.g., safety, data security). What is the MOST appropriate initial response?",
//     options: [ { text: "Confront the coworker loudly and publicly to deter the behaviour", value: "A" }, { text: "Assess the situation: if safe and appropriate, address it discreetly with the coworker; otherwise, or if it persists, report it through the designated confidential channels (e.g., manager, HR, ethics hotline) as per policy", value: "B" }, { text: "Document the behaviour secretly and wait for a pattern to emerge before acting", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w8", sectionId: "workplace", difficulty: "hard", question: "What is a key strategy for proactively managing your workload and preventing potential burnout?",
//     options: [ { text: "Consistently working longer hours to get everything done", value: "A" }, { text: "Effectively prioritising tasks (e.g., by urgency/importance), learning to politely decline or negotiate non-essential requests, communicating bandwidth limitations to your manager, and setting realistic deadlines", value: "B" }, { text: "Delegating tasks to junior colleagues whenever possible", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w16", sectionId: "workplace", difficulty: "hard", question: "What is generally the recommended first step when handling a conflict with a coworker over a work-related issue?",
//     options: [ { text: "Document the issue and immediately escalate it to your manager or HR", value: "A" }, { text: "Attempt to discuss the issue privately and respectfully directly with the coworker, focusing on the specific behaviour or problem (not personality) and seeking a mutually agreeable solution", value: "B" }, { text: "Discuss the conflict with other colleagues to get their opinions and support", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w17", sectionId: "workplace", difficulty: "hard", question: "Maintaining confidentiality in a professional context primarily involves:",
//     options: [ { text: "Only sharing sensitive information with your direct manager", value: "A" }, { text: "Protecting proprietary company information, client data, and private employee details from unauthorised access or disclosure, both inside and outside the company", value: "B" }, { text: "Avoiding participation in office gossip or rumours", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w18", sectionId: "workplace", difficulty: "hard", question: "If you strongly disagree with a decision made by your direct manager, what is the most constructive way to express your concerns?",
//     options: [ { text: "Implement the decision but subtly express your reservations to colleagues", value: "A" }, { text: "Request a private meeting, respectfully present your perspective with supporting reasons or data, suggest alternatives if possible, but ultimately respect and support the final decision made", value: "B" }, { text: "Voice your disagreement during a team meeting to see if others share your view", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w19", sectionId: "workplace", difficulty: "hard", question: "Which action most effectively contributes to fostering a positive and inclusive work environment?",
//     options: [ { text: "Treating everyone equally by ignoring individual differences", value: "A" }, { text: "Actively respecting diverse perspectives, ensuring colleagues feel heard and valued, collaborating openly, and constructively challenging non-inclusive behaviour or comments", value: "B" }, { text: "Organising team-building activities focused on shared interests", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w28", sectionId: "workplace", difficulty: "hard", question: "What is the typical role and benefit of having a mentor in your professional development?",
//     options: [ { text: "A mentor primarily helps you complete difficult tasks or projects", value: "A" }, { text: "A mentor provides guidance, shares experience, offers advice on career navigation, helps build networks, and acts as a sounding board, supporting long-term growth", value: "B" }, { text: "A mentor advocates directly for your promotion with senior management", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w29", sectionId: "workplace", difficulty: "hard", question: "You accidentally receive an email containing highly confidential information clearly not intended for you. What is the most ethical and professional response?",
//     options: [ { text: "Read the information carefully in case it's relevant to your work", value: "A" }, { text: "Discreetly notify the sender of the error immediately, do not forward or share the contents, and delete the email securely as per company policy", value: "B" }, { text: "Forward it to your manager for their awareness", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w30", sectionId: "workplace", difficulty: "hard", question: "What are common challenges in cross-departmental collaboration, and how can they typically be mitigated?",
//     options: [ { text: "Lack of challenges; collaboration is usually seamless if project goals are clear", value: "A" }, { text: "Potential challenges include differing priorities, communication styles, or 'silo' mentalities; mitigate through clear shared goals, defined roles/responsibilities, regular communication protocols, and fostering mutual understanding", value: "B" }, { text: "The primary challenge is competition for resources; mitigate by securing budget approval first", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w31", sectionId: "workplace", difficulty: "hard", question: "How can you provide constructive feedback upwards (e.g., to your manager) effectively and professionally?",
//     options: [ { text: "Gather support from colleagues before presenting the feedback as a group concern", value: "A" }, { text: "Choose an appropriate private setting, focus on specific observations and their impact (rather than personal criticism), frame it constructively with potential solutions or suggestions, and maintain a respectful tone", value: "B" }, { text: "Use anonymous feedback channels if available, as direct feedback upwards is often poorly received", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s1", sectionId: "scenarios", difficulty: "easy", question: "A customer contacts you, clearly angry and upset about a problem with a product or service. What's the best initial approach?",
//     options: [ { text: "Explain company policy regarding the issue immediately", value: "A" }, { text: "Listen patiently and calmly to their full complaint, acknowledge their frustration, and apologise for the inconvenience", value: "B" }, { text: "Transfer them to a manager without explanation", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s4", sectionId: "scenarios", difficulty: "easy", question: "You've completed all your assigned tasks slightly before the end of your workday. What's a proactive and professional action to take?",
//     options: [ { text: "Start packing up quietly to leave on time", value: "A" }, { text: "Ask your supervisor or a busy colleague if there's anything small you can assist with before you go", value: "B" }, { text: "Use the time for personal tasks or Browse online", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s7", sectionId: "scenarios", difficulty: "easy", question: "You notice the shared office printer is out of paper while trying to print. What should you do?",
//     options: [ { text: "Leave a note on the printer asking someone to refill it", value: "A" }, { text: "Check if paper is available nearby and refill it yourself if possible; if not, notify the designated person or office admin", value: "B" }, { text: "Use a different printer and assume someone else will handle it", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s8", sectionId: "scenarios", difficulty: "easy", question: "A visitor arrives for a scheduled meeting with your manager, but your manager is briefly delayed. What is the appropriate action?",
//     options: [ { text: "Direct them to the waiting area without speaking to them", value: "A" }, { text: "Greet them politely, offer them a seat and potentially a drink, inform them your manager is slightly delayed, and let the manager know their guest has arrived", value: "B" }, { text: "Suggest they come back in 15 minutes", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s9", sectionId: "scenarios", difficulty: "easy", question: "You answer the phone at work, and the caller asks for a colleague who is currently unavailable. What should you offer to do?",
//     options: [ { text: "Suggest they call back later", value: "A" }, { text: "Politely explain the colleague is unavailable and offer to take a detailed message (name, number, reason for call, urgency)", value: "B" }, { text: "Provide the colleague's personal mobile number", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s10", sectionId: "scenarios", difficulty: "easy", question: "You need to use a stapler quickly but yours is missing. What's the most appropriate action?",
//     options: [ { text: "Borrow one from the nearest empty desk", value: "A" }, { text: "Ask a nearby colleague if you could quickly borrow theirs, and return it promptly", value: "B" }, { text: "Submit an IT request for a replacement stapler", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s19", sectionId: "scenarios", difficulty: "easy", question: "A new colleague asks you where common office supplies like coffee mugs are kept.",
//     options: [ { text: "Tell them it's usually near the coffee machine", value: "A" }, { text: "Politely show them the location or give clear directions", value: "B" }, { text: "Suggest they ask the office manager", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s20", sectionId: "scenarios", difficulty: "easy", question: "You notice a small amount of rubbish (e.g., a used paper towel) on the floor in the shared office kitchen.",
//     options: [ { text: "Assume the cleaners will deal with it during their next round", value: "A" }, { text: "Pick it up and put it in the nearby bin", value: "B" }, { text: "Mention it to a colleague, hoping they will pick it up", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s21", sectionId: "scenarios", difficulty: "easy", question: "You need to make several copies using the shared photocopier, but a colleague is already using it for a large job.",
//     options: [ { text: "Interrupt and ask if you can quickly make your copies first", value: "A" }, { text: "Wait patiently for them to finish, or politely ask how long they might be and come back later if necessary", value: "B" }, { text: "Go back to your desk and complain about the wait to another colleague", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s22", sectionId: "scenarios", difficulty: "easy", question: "Your computer mouse suddenly stops working. What should be your initial steps?",
//     options: [ { text: "Immediately request a brand new mouse from IT", value: "A" }, { text: "Check the connection (cable or battery), try restarting the computer, and if unresolved, contact the IT helpdesk", value: "B" }, { text: "Swap your mouse with a colleague's when they are away from their desk", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s2", sectionId: "scenarios", difficulty: "medium", question: "You realise you have accidentally deleted an important file from a shared drive. What should be your IMMEDIATE action?",
//     options: [ { text: "Try to restore it from the recycle bin/trash folder first", value: "A" }, { text: "Immediately inform your supervisor or the IT department, explain what happened, and ask for assistance in recovery", value: "B" }, { text: "Ask colleagues if anyone has a recent copy saved locally", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s5", sectionId: "scenarios", difficulty: "medium", question: "A colleague asks for your help with a task that isn't urgent, but you are busy working towards an important deadline. How should you respond professionally?",
//     options: [ { text: "Briefly say 'Sorry, I can't right now' and return to your work", value: "A" }, { text: "Politely explain you're currently focused on a deadline, but offer to help later when you're free or suggest someone else who might be available", value: "B" }, { text: "Agree to help them immediately to be supportive, even if it risks your own deadline", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s11", sectionId: "scenarios", difficulty: "medium", question: "You receive instructions from two different managers that seem to conflict. What is the best way to handle this?",
//     options: [ { text: "Follow the instructions from the manager you report to directly", value: "A" }, { text: "Politely and promptly seek clarification, potentially by bringing the conflicting instructions to the attention of your direct supervisor or tactfully raising it with both managers", value: "B" }, { text: "Choose the instruction that seems easiest or most logical to you", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s12", sectionId: "scenarios", difficulty: "medium", question: "You notice a colleague seems overwhelmed and is falling behind on their share of a team project.",
//     options: [ { text: "Inform your manager that the colleague is not keeping up", value: "A" }, { text: "If appropriate and you have capacity, discreetly offer your help or support, or gently encourage them to speak with the supervisor about their workload", value: "B" }, { text: "Focus on completing your own tasks extra quickly to compensate", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s13", sectionId: "scenarios", difficulty: "medium", question: "You are invited to an important team meeting, but you have a prior, immovable commitment (e.g., client appointment).",
//     options: [ { text: "Decline the meeting invite without explanation", value: "A" }, { text: "Inform the meeting organiser as soon as possible, briefly explain the unavoidable conflict, apologise for your absence, and ask how you can best catch up (e.g., minutes, follow-up chat)", value: "B" }, { text: "Accept the invite but don't attend, assuming someone will fill you in later", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s14", sectionId: "scenarios", difficulty: "medium", question: "Your computer crashes while you're in the middle of working on an important document you haven't saved for a while.",
//     options: [ { text: "Assume the work is lost and start over immediately", value: "A" }, { text: "Restart the computer, open the application to check for auto-recovered versions, check temporary file locations, and contact IT support if recovery is unsuccessful", value: "B" }, { text: "Complain to IT about the unreliable equipment", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s23", sectionId: "scenarios", difficulty: "medium", question: "You are assigned a task that you have no prior experience with and feel requires skills you haven't developed yet.",
//     options: [ { text: "Attempt the task using online tutorials without informing anyone", value: "A" }, { text: "Be honest with your manager about your current experience level, express willingness to learn, and ask for specific guidance, resources, or training needed to complete it successfully", value: "B" }, { text: "Delegate the task to a more experienced colleague", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s24", sectionId: "scenarios", difficulty: "medium", question: "While reviewing a report your team is about to submit, you find a significant error.",
//     options: [ { text: "Make a note to mention it after the submission deadline", value: "A" }, { text: "Immediately bring the error to the attention of your team lead or manager, clearly explaining the issue and suggesting a correction if possible", value: "B" }, { text: "Correct the error yourself without informing anyone, hoping your version is used", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s25", sectionId: "scenarios", difficulty: "medium", question: "A specific colleague consistently arrives 5-10 minutes late for internal team meetings, delaying the start.",
//     options: [ { text: "Start the meeting on time and let them catch up when they arrive", value: "A" }, { text: "Consider raising the pattern gently with them privately first, or suggest the team leader reinforces punctuality expectations for everyone", value: "B" }, { text: "Complain about their lateness to other team members after the meeting", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s26", sectionId: "scenarios", difficulty: "medium", question: "You find yourself overloaded with tasks and genuinely struggling to meet multiple deadlines.",
//     options: [ { text: "Work longer hours and weekends until you catch up, without mentioning it", value: "A" }, { text: "Proactively communicate the situation to your manager, explain your current workload and potential conflicts, and ask for help in prioritising or redistributing tasks", value: "B" }, { text: "Focus only on the most interesting tasks and miss deadlines for the others", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s3", sectionId: "scenarios", difficulty: "hard", question: "Your project team has reached a deadlock, with strong disagreements on the best technical approach to move forward. What is a constructive role you could play?",
//     options: [ { text: "Propose a compromise that partially satisfies both sides, even if technically suboptimal", value: "A" }, { text: "Help facilitate a structured discussion: encourage listening, clarify the pros and cons of each option against project goals/constraints, seek objective data, and work towards consensus or clear criteria for a decision", value: "B" }, { text: "Align with the majority opinion to break the deadlock quickly", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s6", sectionId: "scenarios", difficulty: "hard", question: "Your manager gives you instructions for a task that makes you feel ethically uncomfortable or seems potentially non-compliant.",
//     options: [ { text: "Carry out the task but document your concerns in writing to protect yourself", value: "A" }, { text: "Politely seek clarification on the instructions and rationale, express your specific concerns respectfully, and if still unresolved, consult company policy or escalate through designated ethics channels", value: "B" }, { text: "Refuse to do the task, citing personal ethics, without further discussion", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s15", sectionId: "scenarios", difficulty: "hard", question: "A major client is demanding a service or concession that clearly goes against established company policy and could set a bad precedent.",
//     options: [ { text: "Make an exception for this client to maintain the relationship", value: "A" }, { text: "Politely but firmly explain the specific policy and the reasons behind it, acknowledge their request, express regret you cannot meet it, but actively explore alternative, policy-compliant solutions to help them", value: "B" }, { text: "Refer the client directly to your manager to handle the difficult conversation", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s16", sectionId: "scenarios", difficulty: "hard", question: "You discover a potentially costly error made by a respected senior colleague in a recently completed project.",
//     options: [ { text: "Discuss it with other colleagues first to see if they agree it's an error", value: "A" }, { text: "Find an appropriate, discreet time and way to raise the specific issue (focusing on the problem/impact, not the person), perhaps directly with the senior colleague first, or with your manager, depending on the context and potential impact", value: "B" }, { text: "Anonymously report the error through the company's compliance hotline", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s17", sectionId: "scenarios", difficulty: "hard", question: "Your project is suffering from 'scope creep' - continuous small additions to the requirements without adjustments to resources or deadlines.",
//     options: [ { text: "Politely push back on all new requests, stating the original scope is fixed", value: "A" }, { text: "Document the requested changes and their potential impact on timelines, budget, or resources. Discuss these impacts proactively with your manager or project sponsor to formally assess, approve/reject, and adjust plans accordingly", value: "B" }, { text: "Absorb the extra work and try to deliver everything, hoping for recognition later", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s18", sectionId: "scenarios", difficulty: "hard", question: "A usually reliable team member is consistently missing deadlines on a critical project, impacting everyone. What's a constructive approach?",
//     options: [ { text: "Take over parts of their work to ensure the project stays on track", value: "A" }, { text: "Have a private, supportive conversation first to understand if there are underlying issues or blockers you/the team can help with. If the problem persists without resolution, then escalate the performance concern to the project manager or their line manager", value: "B" }, { text: "Raise the issue during a team meeting, highlighting the impact on the project timeline", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s27", sectionId: "scenarios", difficulty: "hard", question: "You consistently witness a colleague subtly taking credit for another team member's ideas during meetings.",
//     options: [ { text: "Publicly correct the colleague in the meeting when it happens", value: "A" }, { text: "Find opportunities to tactfully amplify or attribute the idea to the original contributor ('Building on [original person]'s great point...'). Consider speaking privately to the targeted colleague, the undermining colleague, or a manager if the pattern is clear and damaging", value: "B" }, { text: "Ignore it, as interfering in colleague dynamics can be risky", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s28", sectionId: "scenarios", difficulty: "hard", question: "Your manager provides vague positive feedback like 'Good job' or vague negative feedback like 'Needs improvement'. How can you get more useful information?",
//     options: [ { text: "Assume 'good job' means perfect and 'needs improvement' means you failed", value: "A" }, { text: "Thank them for the feedback, and then politely ask for specific examples or details: 'Thanks! Could you tell me what specifically worked well?' or 'I appreciate the feedback. Could you give me an example of what 'improvement' would look like in this context?'", value: "B" }, { text: "Ask colleagues if they know what the manager likely meant", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s29", sectionId: "scenarios", difficulty: "hard", question: "A colleague asks you to cover for them by slightly misrepresenting their progress on a task to the manager.",
//     options: [ { text: "Agree to help them out, as teamwork sometimes requires flexibility", value: "A" }, { text: "Politely but firmly decline, explaining you're not comfortable being dishonest, but perhaps offer to help them genuinely catch up or discuss the real situation with the manager", value: "B" }, { text: "Report the colleague's request immediately to your manager or HR", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s30", sectionId: "scenarios", difficulty: "hard", question: "You've been given full responsibility for delivering a project outcome, but lack the necessary authority (e.g., over budget or resources) to ensure its success.",
//     options: [ { text: "Do your best within the constraints and hope for a good result", value: "A" }, { text: "Proactively schedule a meeting with your manager to discuss the responsibility/authority gap. Clearly outline the specific authority needed and why it's critical for success, proposing solutions or seeking clarification on decision-making processes", value: "B" }, { text: "Proceed as if you have the authority, making necessary decisions and informing relevant parties afterwards", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d1", sectionId: "digital", difficulty: "easy", question: "When setting passwords for your work accounts, what is a basic security principle?",
//     options: [ { text: "Use the same password for all work accounts for easy recall", value: "A" }, { text: "Create strong, unique passwords for each account, ideally using a mix of characters, numbers, and symbols", value: "B" }, { text: "Use easily guessable information like your start date or department name", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d4", sectionId: "digital", difficulty: "easy", question: "In a work email, when should you use the 'CC' (Carbon Copy) field?",
//     options: [ { text: "To secretly inform your manager about the conversation", value: "A" }, { text: "To include people who need to be informed but aren't required to act directly on the email", value: "B" }, { text: "For all recipients of the email", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d7", sectionId: "digital", difficulty: "easy", question: "When working on an important document on your work computer, what should you do frequently?",
//     options: [ { text: "Change the font size to make it look longer", value: "A" }, { text: "Save your work using the 'Save' command or shortcut (Ctrl+S / Cmd+S)", value: "B" }, { text: "Check your email inbox for new messages", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d8", sectionId: "digital", difficulty: "easy", question: "When saving a shared project document, what's a good practice for the file name?",
//     options: [ { text: "Use a simple name like 'Document1' or 'Final Report'", value: "A" }, { text: "Include a clear, descriptive name with date and/or version number (e.g., 'ProjectX_MarketingPlan_V3_2025-03-31.docx')", value: "B" }, { text: "Save it with your name only", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d9", sectionId: "digital", difficulty: "easy", question: "When should you use the 'Reply All' button in a work email chain?",
//     options: [ { text: "Always, to ensure everyone stays informed", value: "A" }, { text: "Only when your reply is genuinely relevant to ALL recipients on the 'To' and 'CC' lines", value: "B" }, { text: "Never, always reply only to the sender", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d10", sectionId: "digital", difficulty: "easy", question: "You receive an email asking you to click a link to update your company portal password urgently. What might this be?",
//     options: [ { text: "A standard security procedure", value: "A" }, { text: "A potential 'phishing' attempt to steal your login details", value: "B" }, { text: "An automated notification from IT", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d20", sectionId: "digital", difficulty: "easy", question: "When should you use the 'BCC' (Blind Carbon Copy) field in a work email?",
//     options: [ { text: "When CC'ing your manager on an email to a colleague", value: "A" }, { text: "When sending to a large list of external contacts whose emails should not be shared with each other", value: "B" }, { text: "Whenever you send an email with an attachment", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d21", sectionId: "digital", difficulty: "easy", question: "What is the common keyboard shortcut for 'Copy' text or files on most work computers?",
//     options: [ { text: "Ctrl + V (or Cmd + V)", value: "A" }, { text: "Ctrl + C (or Cmd + C)", value: "B" }, { text: "Ctrl + X (or Cmd + X)", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d22", sectionId: "digital", difficulty: "easy", question: "What is the common keyboard shortcut for 'Paste' text or files on most work computers?",
//     options: [ { text: "Ctrl + V (or Cmd + V)", value: "A" }, { text: "Ctrl + P (or Cmd + P)", value: "B" }, { text: "Ctrl + C (or Cmd + C)", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "d23", sectionId: "digital", difficulty: "easy", question: "Why might a colleague send you a document as a PDF file?",
//     options: [ { text: "Because PDF files are easier to edit", value: "A" }, { text: "To ensure the document's formatting looks the same for everyone, regardless of their software", value: "B" }, { text: "Because PDF files are smaller than other types", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d2", sectionId: "digital", difficulty: "medium", question: "You receive an unexpected email at work with an attachment (e.g., 'Invoice.zip') from an unknown sender. What should you do?",
//     options: [ { text: "Open the attachment to see if it's relevant to your work", value: "A" }, { text: "Be very cautious; do not open the attachment as it could contain malware. Delete the email or report it as suspicious according to company policy.", value: "B" }, { text: "Forward it to IT asking if it's safe to open", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d5", sectionId: "digital", difficulty: "medium", question: "Why is it important to log out or lock your computer when leaving your desk, even for a short time?",
//     options: [ { text: "It saves electricity", value: "A" }, { text: "To prevent unauthorised individuals from accessing your work, company data, or sending emails from your account", value: "B" }, { text: "It clears your computer's memory", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d11", sectionId: "digital", difficulty: "medium", question: "Besides suspicious links or attachments, what's another common sign of a phishing email in a workplace context?",
//     options: [ { text: "The email is sent during work hours", value: "A" }, { text: "Generic greetings, urgent requests for login details or personal info, unusual sender address, or poor grammar/spelling", value: "B" }, { text: "The email includes the company logo", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d12", sectionId: "digital", difficulty: "medium", question: "When using a shared team drive (like Google Drive or SharePoint), why is it important to follow agreed folder structures?",
//     options: [ { text: "It doesn't matter where files are saved as long as they are on the drive", value: "A" }, { text: "To ensure all team members can easily find necessary files, avoid duplication, and maintain version control", value: "B" }, { text: "To limit access to certain files", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d13", sectionId: "digital", difficulty: "medium", question: "During a professional video conference call, what is considered good etiquette regarding your microphone?",
//     options: [ { text: "Assume others will tell you if your background is noisy", value: "A" }, { text: "Mute your microphone when you are not actively speaking to minimize background noise for others", value: "B" }, { text: "Keep your microphone unmuted throughout the entire meeting", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d14", sectionId: "digital", difficulty: "medium", question: "What is a key risk of using easily guessable passwords (like 'Password123' or your name) for work accounts?",
//     options: [ { text: "You might forget them easily", value: "A" }, { text: "They can be easily cracked by attackers, potentially giving them access to sensitive company or personal data", value: "B" }, { text: "They might not meet company IT policy requirements", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d24", sectionId: "digital", difficulty: "medium", question: "When using workplace instant messaging (like Slack or Teams), what's a key aspect of professional etiquette?",
//     options: [ { text: "Contacting colleagues outside of work hours for urgent matters", value: "A" }, { text: "Being concise, maintaining a professional tone (avoiding excessive slang/emojis), and respecting colleagues' status indicators (e.g., 'in a meeting')", value: "B" }, { text: "Using direct messages for all communication to avoid cluttering channels", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d25", sectionId: "digital", difficulty: "medium", question: "You need to share a large file with a colleague. What is generally a better method than emailing it as an attachment?",
//     options: [ { text: "Printing it out for them", value: "A" }, { text: "Using a company-approved cloud storage or shared drive link", value: "B" }, { text: "Sending it via a personal file-sharing website", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d26", sectionId: "digital", difficulty: "medium", question: "In a typical office role, basic proficiency in which types of software is often expected?",
//     options: [ { text: "Video game development engines", value: "A" }, { text: "Word processing (e.g., Microsoft Word, Google Docs), spreadsheet software (e.g., Excel, Google Sheets), and email clients (e.g., Outlook, Gmail)", value: "B" }, { text: "Advanced statistical analysis packages", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d27", sectionId: "digital", difficulty: "medium", question: "Why is it generally advisable to save work files onto company network drives or approved cloud storage rather than just your local computer's desktop?",
//     options: [ { text: "It makes your computer run faster", value: "A" }, { text: "Files stored on network/cloud drives are often regularly backed up by the company, protecting against data loss if your computer fails", value: "B" }, { text: "It uses less storage space on your local computer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d3", sectionId: "digital", difficulty: "hard", question: "Your workplace policy prohibits installing unauthorised software. What is the primary reason for this rule?",
//     options: [ { text: "To ensure all employees use the same software versions", value: "A" }, { text: "To prevent the introduction of malware, ensure software license compliance, and maintain system stability and security", value: "B" }, { text: "To save on company IT costs", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d6", sectionId: "digital", difficulty: "hard", question: "Your company implements Multi-Factor Authentication (MFA) for logging into work systems. What does this mean for you?",
//     options: [ { text: "You need to set a more complex password", value: "A" }, { text: "In addition to your password, you'll need a second verification step (e.g., code from an app/SMS, fingerprint) to prove your identity", value: "B" }, { text: "You can only log in from specific company devices", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d15", sectionId: "digital", difficulty: "hard", question: "You need to send a report containing sensitive client data to an external partner. According to typical data privacy practices, what should you confirm first?",
//     options: [ { text: "That the recipient's email address is spelled correctly", value: "A" }, { text: "Your company's policy and approved secure method for transmitting sensitive data externally (e.g., encrypted email, secure portal)", value: "B" }, { text: "That the file size is not too large for email", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d16", sectionId: "digital", difficulty: "hard", question: "Why is it often against company policy to use personal USB drives on work computers?",
//     options: [ { text: "They can easily get lost", value: "A" }, { text: "They can introduce malware from external sources bypass security controls, and facilitate unauthorised data transfer", value: "B" }, { text: "They are not compatible with most work computers", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d17", sectionId: "digital", difficulty: "hard", question: "When working remotely, using a company-provided VPN (Virtual Private Network) primarily helps to:",
//     options: [ { text: "Increase your home internet speed", value: "A" }, { text: "Establish a secure, encrypted connection to the company network, protecting data transmitted over potentially insecure networks", value: "B" }, { text: "Allow you to access social media websites restricted on the company network", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d18", sectionId: "digital", difficulty: "hard", question: "How can your activity on personal social media potentially impact your professional life, even if settings are 'private'?",
//     options: [ { text: "It has no impact as long as your profile is private", value: "A" }, { text: "Screenshots can be taken, 'friends' may share content, and unprofessional posts (e.g., complaining about work, inappropriate content) could still surface and affect your reputation or violate company policy", value: "B" }, { text: "Potential employers only check professional networks like LinkedIn", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d19", sectionId: "digital", difficulty: "hard", question: "Your company has a 'clear desk and clear screen' policy. What does the 'clear screen' part typically require?",
//     options: [ { text: "Using a privacy screen filter on your monitor", value: "A" }, { text: "Logging off or locking your computer whenever you leave your desk to prevent unauthorised viewing of sensitive information", value: "B" }, { text: "Closing all applications before leaving for the day", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d28", sectionId: "digital", difficulty: "hard", question: "Your workplace requires strong, complex passwords that must be changed frequently. What is the BEST practice for managing these securely?",
//     options: [ { text: "Use a reputable password manager application to generate and store unique, complex passwords for each work account", value: "A" }, { text: "Write them down in a notebook kept discreetly at your desk", value: "B" }, { text: "Use a sequential pattern for easy recall (e.g., PasswordSpring25, PasswordSummer25)", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "d29", sectionId: "digital", difficulty: "hard", question: "You find a useful resource online (e.g., an image, report) you want to use in a work presentation. What digital citizenship aspect should you consider?",
//     options: [ { text: "Whether the resource looks professional enough", value: "A" }, { text: "Copyright and licensing terms; ensure you have permission or the license (e.g., company subscription, Creative Commons) allows usage, and attribute correctly if required", value: "B" }, { text: "How quickly you can copy and paste it into your presentation", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d30", sectionId: "digital", difficulty: "hard", question: "What type of cyber threat involves tricking employees into making unauthorised payments or revealing sensitive data by impersonating senior management or trusted vendors via email?",
//     options: [ { text: "Ransomware attack", value: "A" }, { text: "Business Email Compromise (BEC) or CEO fraud", value: "B" }, { text: "Denial-of-Service (DoS) attack", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d31", sectionId: "digital", difficulty: "hard", question: "How can using features like version history and track changes in collaborative software (e.g., Google Docs, Microsoft Word) benefit teamwork?",
//     options: [ { text: "It complicates the document editing process", value: "A" }, { text: "It allows team members to see who made specific changes, revert to previous versions if needed, and understand the evolution of a document, improving transparency and accountability", value: "B" }, { text: "It automatically merges different versions of a document", value: "C" } ],
//     correctAnswer: "B",
//   },
// ];




// const allQuestions: Question[] = [
//   {
//     id: "r1", sectionId: "readiness", difficulty: "easy", question: "What should you usually bring to a job interview?",
//     options: [ { text: "Just yourself", value: "A" }, { text: "Copies of your CV and a pen", value: "B" }, { text: "Your lunch", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r2", sectionId: "readiness", difficulty: "easy", question: "How early should you aim to arrive for an interview?",
//     options: [ { text: "Exactly on time", value: "A" }, { text: "One hour early", value: "B" }, { text: "10-15 minutes early", value: "C" } ],
//     correctAnswer: "C",
//   },
//   {
//     id: "r6", sectionId: "readiness", difficulty: "easy", question: "What is generally appropriate attire for most job interviews?",
//     options: [ { text: "Beachwear", value: "A" }, { text: "Clean, smart casual or formal clothes", value: "B" }, { text: "Your favourite band t-shirt", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r9", sectionId: "readiness", difficulty: "easy", question: "What should you do with your phone during an interview?",
//     options: [ { text: "Turn it completely off or on silent", value: "A" }, { text: "Leave it on loud", value: "B" }, { text: "Text your friends", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r10", sectionId: "readiness", difficulty: "easy", question: "What does 'CV' stand for?",
//     options: [ { text: "Current Venture", value: "A" }, { text: "Curriculum Vitae", value: "B" }, { text: "Company Volunteer", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r11", sectionId: "readiness", difficulty: "easy", question: "What is a common first question in an interview?",
//     options: [ { text: "What are your salary expectations?", value: "A" }, { text: "Tell me about yourself.", value: "B" }, { text: "When can you start?", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r12", sectionId: "readiness", difficulty: "easy", question: "How should you greet the interviewer?",
//     options: [ { text: "With a confident handshake (if appropriate) and eye contact", value: "A" }, { text: "Nod silently", value: "B" }, { text: "High five", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r21", sectionId: "readiness", difficulty: "easy", question: "What is the main purpose of a CV?",
//     options: [ { text: "To list your hobbies", value: "A" }, { text: "To provide a summary of your skills, experience, and education", value: "B" }, { text: "To share your life story", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r22", sectionId: "readiness", difficulty: "easy", question: "Should you include references on your CV when first applying?",
//     options: [ { text: "Yes, always list names and contact details", value: "A" }, { text: "No, usually state 'References available upon request'", value: "B" }, { text: "Include your family members as references", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r23", sectionId: "readiness", difficulty: "easy", question: "What's a simple way to show you're listening during an interview?",
//     options: [ { text: "Interrupt frequently", value: "A" }, { text: "Nod occasionally and maintain eye contact", value: "B" }, { text: "Stare blankly at the wall", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r24", sectionId: "readiness", difficulty: "easy", question: "Is it okay to ask the interviewer to repeat a question?",
//     options: [ { text: "No, never", value: "A" }, { text: "Yes, if you didn't hear or understand it clearly", value: "B" }, { text: "Only if you weren't paying attention", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r3", sectionId: "readiness", difficulty: "medium", question: "What does the STAR method (Situation, Task, Action, Result) help you answer?",
//     options: [ { text: "Questions about your hobbies", value: "A" }, { text: "Behavioural questions ('Tell me about a time when...')", value: "B" }, { text: "Technical skill tests", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r4", sectionId: "readiness", difficulty: "medium", question: "Why is researching a company BEFORE an interview important?",
//     options: [ { text: "To find out office gossip", value: "A" }, { text: "To show genuine interest and tailor your answers", value: "B" }, { text: "To check their stock price", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r7", sectionId: "readiness", difficulty: "medium", question: "What is a good type of question to ask the interviewer at the end?",
//     options: [ { text: "A question about the role specifics or team culture", value: "A" }, { text: "A question about the interviewer's personal life", value: "B" }, { text: "Asking if you got the job yet", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r13", sectionId: "readiness", difficulty: "medium", question: "What's the purpose of asking 'Do you have any questions for us?'",
//     options: [ { text: "To fill time", value: "A" }, { text: "To gauge your interest and engagement", value: "B" }, { text: "To test your memory", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r14", sectionId: "readiness", difficulty: "medium", question: "How can you prepare for common interview questions?",
//     options: [ { text: "Hope for the best", value: "A" }, { text: "Think about examples from your experience and practice answering", value: "B" }, { text: "Memorise generic answers from the internet", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r15", sectionId: "readiness", difficulty: "medium", question: "What does positive body language include during an interview?",
//     options: [ { text: "Slouching and avoiding eye contact", value: "A" }, { text: "Sitting up straight, nodding, and maintaining good eye contact", value: "B" }, { text: "Folding your arms and frowning", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r16", sectionId: "readiness", difficulty: "medium", question: "If an interview is online, what should you check beforehand?",
//     options: [ { text: "Your internet connection, camera, and microphone", value: "A" }, { text: "The weather outside", value: "B" }, { text: "Your social media notifications", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r25", sectionId: "readiness", difficulty: "medium", question: "How can you explain a gap in your employment history?",
//     options: [ { text: "Make up a fictional job", value: "A" }, { text: "Be honest and focus on any skills gained or productive activities during that time", value: "B" }, { text: "Avoid mentioning it", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r26", sectionId: "readiness", difficulty: "medium", question: "What are some different types of interviews you might encounter?",
//     options: [ { text: "Only face-to-face interviews exist", value: "A" }, { text: "Phone screens, video interviews, panel interviews, technical tests", value: "B" }, { text: "Interviews conducted while skydiving", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r27", sectionId: "readiness", difficulty: "medium", question: "Why might an interviewer ask about your reasons for leaving a previous job?",
//     options: [ { text: "To make you feel bad", value: "A" }, { text: "To understand your motivations and see if you left on good terms", value: "B" }, { text: "They are just being nosy", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r28", sectionId: "readiness", difficulty: "medium", question: "How should you tailor your CV for a specific job application?",
//     options: [ { text: "Use the exact same CV for every job", value: "A" }, { text: "Highlight the skills and experiences most relevant to the job description", value: "B" }, { text: "Make the font size much larger", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r5", sectionId: "readiness", difficulty: "hard", question: "If asked about a weakness, what's the MOST effective approach?",
//     options: [ { text: "Disguise a strength as a weakness (e.g., 'I'm a perfectionist')", value: "A" }, { text: "Mention a genuine, relevant weakness and explain how you're working on it", value: "B" }, { text: "Refuse to answer the question", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r8", sectionId: "readiness", difficulty: "hard", question: "How can you best demonstrate enthusiasm during a video interview?",
//     options: [ { text: "Use excessive hand gestures", value: "A" }, { text: "Maintain good eye contact with the camera, smile genuinely, and show active listening", value: "B" }, { text: "Constantly interrupt the interviewer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r17", sectionId: "readiness", difficulty: "hard", question: "What should you do after a job interview?",
//     options: [ { text: "Wait passively for them to contact you", value: "A" }, { text: "Send a thank-you email or note within 24-48 hours", value: "B" }, { text: "Call them multiple times a day", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r18", sectionId: "readiness", difficulty: "hard", question: "How should you respond if asked about salary expectations early on?",
//     options: [ { text: "Give a very high number immediately", value: "A" }, { text: "Try to defer the discussion or provide a researched range, stating it's negotiable", value: "B" }, { text: "Say you'll work for free", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r19", sectionId: "readiness", difficulty: "hard", question: "What if an interviewer asks an inappropriate or illegal question (e.g., about marital status, religion)?",
//     options: [ { text: "You must answer it truthfully", value: "A" }, { text: "Politely deflect, question its relevance, or state you're not comfortable answering", value: "B" }, { text: "Walk out of the interview immediately", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "r20", sectionId: "readiness", difficulty: "hard", question: "How can you handle interview nerves effectively?",
//     options: [ { text: "Avoid interviews altogether", value: "A" }, { text: "Prepare thoroughly, practice deep breathing, and focus on your qualifications", value: "B" }, { text: "Drink lots of caffeine beforehand", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r29", sectionId: "readiness", difficulty: "hard", question: "How should you professionally handle rejection after an interview?",
//     options: [ { text: "Send an angry email", value: "A" }, { text: "Thank the interviewer for their time and potentially ask for feedback to improve", value: "B" }, { text: "Ignore the rejection message", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r30", sectionId: "readiness", difficulty: "hard", question: "What does 'company culture' refer to, and why is it important to consider?",
//     options: [ { text: "The company's logo design", value: "A" }, { text: "The shared values, attitudes, and practices that shape the work environment; important for job satisfaction", value: "B" }, { text: "How often the company holds parties", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r31", sectionId: "readiness", difficulty: "hard", question: "If you receive a job offer, is it acceptable to negotiate the salary or benefits?",
//     options: [ { text: "No, you must accept the first offer", value: "A" }, { text: "Yes, it's often acceptable to professionally negotiate based on research and your value", value: "B" }, { text: "Only if you threaten to leave", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r32", sectionId: "readiness", difficulty: "hard", question: "How can asking about professional development opportunities benefit you in an interview?",
//     options: [ { text: "It makes you look demanding", value: "A" }, { text: "It shows you are ambitious, eager to learn, and interested in growing with the company", value: "B" }, { text: "It's irrelevant to the job", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w1", sectionId: "workplace", difficulty: "easy", question: "If you're unsure how to do a task at work, what should you do first?",
//     options: [ { text: "Guess", value: "A" }, { text: "Ask a colleague or your supervisor for clarification", value: "B" }, { text: "Ignore the task", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w2", sectionId: "workplace", difficulty: "easy", question: "What does 'being punctual' generally mean in a workplace?",
//     options: [ { text: "Arriving whenever you feel like it", value: "A" }, { text: "Arriving on time or slightly early for your shift/meetings", value: "B" }, { text: "Leaving early every day", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w6", sectionId: "workplace", difficulty: "easy", question: "What should you do if you are running late for work?",
//     options: [ { text: "Hope nobody notices", value: "A" }, { text: "Inform your supervisor as soon as possible", value: "B" }, { text: "Blame traffic when you arrive", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w9", sectionId: "workplace", difficulty: "easy", question: "What is the typical expectation for workplace dress code, unless specified otherwise?",
//     options: [ { text: "Casual clothes like trackpants", value: "A" }, { text: "Clean, neat, and appropriate for the industry", value: "B" }, { text: "Costumes", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w10", sectionId: "workplace", difficulty: "easy", question: "Where should you usually take personal phone calls at work?",
//     options: [ { text: "At your desk loudly", value: "A" }, { text: "In a break area or outside, briefly and quietly", value: "B" }, { text: "During important meetings", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w11", sectionId: "workplace", difficulty: "easy", question: "What is important when using shared equipment like printers or microwaves?",
//     options: [ { text: "Leave it messy for the next person", value: "A" }, { text: "Use it respectfully and clean up after yourself", value: "B" }, { text: "Take it home", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w20", sectionId: "workplace", difficulty: "easy", question: "What's a professional way to answer your work phone?",
//     options: [ { text: "'Yeah?'", value: "A" }, { text: "Greeting, company/department name, your name, offer assistance (e.g., 'Good morning, [Company], [Your Name] speaking, how can I help?')", value: "B" }, { text: "Silence", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w21", sectionId: "workplace", difficulty: "easy", question: "Why is it important to keep your workspace relatively tidy?",
//     options: [ { text: "It makes it harder to find things", value: "A" }, { text: "It presents a professional image and helps you stay organised", value: "B" }, { text: "Tidiness is not important", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w22", sectionId: "workplace", difficulty: "easy", question: "If you need information about company policy (e.g., leave), where should you look first?",
//     options: [ { text: "Ask a random colleague", value: "A" }, { text: "Check the company intranet, employee handbook, or ask HR/your manager", value: "B" }, { text: "Search on Google", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w23", sectionId: "workplace", difficulty: "easy", question: "What should you do before leaving work for the day?",
//     options: [ { text: "Run out the door as fast as possible", value: "A" }, { text: "Briefly check emails, tidy your desk, and secure sensitive information", value: "B" }, { text: "Leave your computer logged in", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w3", sectionId: "workplace", difficulty: "medium", question: "How should you best handle constructive criticism from your manager?",
//     options: [ { text: "Get defensive and argue", value: "A" }, { text: "Listen actively, ask clarifying questions, and see it as a chance to improve", value: "B" }, { text: "Complain about your manager to colleagues", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w4", sectionId: "workplace", difficulty: "medium", question: "What is a key characteristic of good teamwork?",
//     options: [ { text: "Focusing only on your individual tasks", value: "A" }, { text: "Communicating effectively and supporting colleagues", value: "B" }, { text: "Trying to outperform everyone else", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w7", sectionId: "workplace", difficulty: "medium", question: "Why is maintaining a positive attitude beneficial at work?",
//     options: [ { text: "It prevents you from getting promoted", value: "A" }, { text: "It improves morale, collaboration, and resilience to challenges", value: "B" }, { text: "It annoys your coworkers", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w12", sectionId: "workplace", difficulty: "medium", question: "What does it mean to 'take initiative' at work?",
//     options: [ { text: "Waiting to be told exactly what to do", value: "A" }, { text: "Identifying opportunities or problems and taking action without being asked", value: "B" }, { text: "Taking long breaks", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w13", sectionId: "workplace", difficulty: "medium", question: "How should you generally communicate important information to your team?",
//     options: [ { text: "Through vague hints", value: "A" }, { text: "Clearly, concisely, and through appropriate channels (email, meeting, etc.)", value: "B" }, { text: "Gossip", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w14", sectionId: "workplace", difficulty: "medium", question: "What is professional email etiquette?",
//     options: [ { text: "Using slang and emojis excessively", value: "A" }, { text: "Using a clear subject line, proofreading, and responding promptly", value: "B" }, { text: "Writing in all capital letters", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w15", sectionId: "workplace", difficulty: "medium", question: "If you make a mistake at work, what is the best course of action?",
//     options: [ { text: "Try to hide it or blame someone else", value: "A" }, { text: "Own up to it, inform your supervisor if necessary, and learn from it", value: "B" }, { text: "Pretend it never happened", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w24", sectionId: "workplace", difficulty: "medium", question: "What are some tools or techniques for managing your time effectively?",
//     options: [ { text: "Working randomly on tasks", value: "A" }, { text: "Using to-do lists, calendars, prioritizing tasks (e.g., Eisenhower Matrix)", value: "B" }, { text: "Procrastinating until the last minute", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w25", sectionId: "workplace", difficulty: "medium", question: "How should you generally interact with colleagues on social media?",
//     options: [ { text: "Overshare personal details and complain about work", value: "A" }, { text: "Maintain professionalism, be mindful of company image, and avoid controversial topics", value: "B" }, { text: "Tag them in embarrassing photos", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w26", sectionId: "workplace", difficulty: "medium", question: "Why is understanding a meeting's agenda important before attending?",
//     options: [ { text: "So you know when the snacks will be served", value: "A" }, { text: "To prepare relevant points, understand the purpose, and participate effectively", value: "B" }, { text: "It's not important to read the agenda", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w27", sectionId: "workplace", difficulty: "medium", question: "What does being 'proactive' mean in a work context?",
//     options: [ { text: "Reacting only when problems occur", value: "A" }, { text: "Anticipating needs or issues and taking steps to address them before they escalate", value: "B" }, { text: "Avoiding work", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w5", sectionId: "workplace", difficulty: "hard", question: "You witness a coworker potentially breaking a safety rule. What's the appropriate response?",
//     options: [ { text: "Film it for social media", value: "A" }, { text: "Address it directly if safe, or report it through official channels as per company policy", value: "B" }, { text: "Join in", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w8", sectionId: "workplace", difficulty: "hard", question: "How can you proactively manage your workload to prevent burnout?",
//     options: [ { text: "Say yes to every request immediately", value: "A" }, { text: "Prioritize tasks using methods like urgency/importance, set realistic deadlines, and communicate bandwidth", value: "B" }, { text: "Work 16 hours every day", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w16", sectionId: "workplace", difficulty: "hard", question: "How should you handle a conflict with a coworker?",
//     options: [ { text: "Gossip about them to others", value: "A" }, { text: "Address the issue privately and respectfully with the coworker first, focusing on behaviour not personality", value: "B" }, { text: "Escalate immediately to the CEO", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w17", sectionId: "workplace", difficulty: "hard", question: "What does 'maintaining confidentiality' mean in a workplace context?",
//     options: [ { text: "Sharing company secrets with competitors", value: "A" }, { text: "Protecting sensitive information about the company, clients, and colleagues", value: "B" }, { text: "Posting private customer data online", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w18", sectionId: "workplace", difficulty: "hard", question: "You disagree with a decision made by your manager. How should you voice your concerns?",
//     options: [ { text: "Complain loudly in the open office", value: "A" }, { text: "Request a private meeting to respectfully explain your perspective and offer alternatives, then accept the final decision", value: "B" }, { text: "Undermine the decision secretly", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w19", sectionId: "workplace", difficulty: "hard", question: "How can you contribute to a positive and inclusive work environment?",
//     options: [ { text: "Exclude colleagues who are different", value: "A" }, { text: "Be respectful, value diverse perspectives, and challenge inappropriate behaviour", value: "B" }, { text: "Make offensive jokes", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "w28", sectionId: "workplace", difficulty: "hard", question: "What is the role of a mentor in professional development?",
//     options: [ { text: "To do your work for you", value: "A" }, { text: "To provide guidance, share experience, offer advice, and support your career growth", value: "B" }, { text: "To criticize your every move", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w29", sectionId: "workplace", difficulty: "hard", question: "You accidentally receive an email with confidential information not meant for you.",
//     options: [ { text: "Forward it to all your friends", value: "A" }, { text: "Discreetly inform the sender, delete the email, and do not share the information", value: "B" }, { text: "Use the information for personal gain", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w30", sectionId: "workplace", difficulty: "hard", question: "What challenges might arise in cross-departmental collaboration, and how can they be addressed?",
//     options: [ { text: "Collaboration is always easy", value: "A" }, { text: "Differing priorities or communication styles; address through clear goals, regular communication, and defined roles", value: "B" }, { text: "Sabotaging the other department's work", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w31", sectionId: "workplace", difficulty: "hard", question: "How can providing constructive feedback upwards (to a manager) be done effectively?",
//     options: [ { text: "Criticize them publicly in a meeting", value: "A" }, { text: "Choose an appropriate time, focus on specific observations/impacts, be solution-oriented, and maintain respect", value: "B" }, { text: "Send anonymous complaints", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s1", sectionId: "scenarios", difficulty: "easy", question: "A customer is angry about a faulty product they bought. What's a good first step?",
//     options: [ { text: "Argue with the customer", value: "A" }, { text: "Listen calmly to their complaint and apologise for the inconvenience", value: "B" }, { text: "Hide from the customer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s4", sectionId: "scenarios", difficulty: "easy", question: "You've finished your assigned tasks for the day. What should you proactively do?",
//     options: [ { text: "Pack up and leave immediately", value: "A" }, { text: "Ask your supervisor or a colleague if they need help with anything", value: "B" }, { text: "Start planning your weekend", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s7", sectionId: "scenarios", difficulty: "easy", question: "The office printer is out of paper. What should you do?",
//     options: [ { text: "Complain about it", value: "A" }, { text: "Find where the paper is stored and refill it, or notify the person responsible", value: "B" }, { text: "Wait for someone else to fix it", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s8", sectionId: "scenarios", difficulty: "easy", question: "A visitor arrives for a meeting with your manager, who is currently busy. What should you do?",
//     options: [ { text: "Ignore the visitor", value: "A" }, { text: "Greet them politely, offer them a seat, and let your manager know they've arrived", value: "B" }, { text: "Tell them to come back later", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s9", sectionId: "scenarios", difficulty: "easy", question: "You answer the phone and the caller asks for someone who isn't available.",
//     options: [ { text: "Hang up", value: "A" }, { text: "Politely take a message, including their name, number, and reason for calling", value: "B" }, { text: "Tell them to call back repeatedly", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s10", sectionId: "scenarios", difficulty: "easy", question: "You need a stapler but can't find yours. What's the best approach?",
//     options: [ { text: "Take one from a colleague's desk without asking", value: "A" }, { text: "Ask a nearby colleague if you could briefly borrow theirs", value: "B" }, { text: "Submit a formal request for a new stapler", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s19", sectionId: "scenarios", difficulty: "easy", question: "Your colleague asks where the coffee mugs are kept.",
//     options: [ { text: "Tell them to find it themselves", value: "A" }, { text: "Politely show them where the mugs are located", value: "B" }, { text: "Hide the mugs", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s20", sectionId: "scenarios", difficulty: "easy", question: "You see some rubbish on the floor in the office kitchen.",
//     options: [ { text: "Step over it", value: "A" }, { text: "Pick it up and put it in the bin", value: "B" }, { text: "Complain about the cleaners", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s21", sectionId: "scenarios", difficulty: "easy", question: "You need to use the photocopier, but a colleague is already using it.",
//     options: [ { text: "Interrupt them and demand they hurry up", value: "A" }, { text: "Wait patiently nearby or ask politely how long they might be", value: "B" }, { text: "Go back to your desk and give up", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s22", sectionId: "scenarios", difficulty: "easy", question: "Your computer mouse stops working.",
//     options: [ { text: "Throw it against the wall", value: "A" }, { text: "Check the connection, try restarting the computer, or ask IT for help", value: "B" }, { text: "Borrow a colleague's without asking", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s2", sectionId: "scenarios", difficulty: "medium", question: "You accidentally delete an important shared file. What is the FIRST thing you should do?",
//     options: [ { text: "Hope no one notices", value: "A" }, { text: "Immediately inform your supervisor or IT department and explain what happened", value: "B" }, { text: "Try to blame the computer system", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s5", sectionId: "scenarios", difficulty: "medium", question: "A colleague asks for help with a non-urgent task, but you have an important deadline. How do you respond politely?",
//     options: [ { text: "Say 'No, I'm too busy' and turn away", value: "A" }, { text: "Explain your deadline but offer to help when you're free, or suggest someone else who might assist", value: "B" }, { text: "Agree to help immediately and risk missing your deadline", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s11", sectionId: "scenarios", difficulty: "medium", question: "You receive conflicting instructions from two different managers. What should you do?",
//     options: [ { text: "Ignore both sets of instructions", value: "A" }, { text: "Politely seek clarification, perhaps by bringing both managers together or asking your direct supervisor", value: "B" }, { text: "Choose the easier instruction", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s12", sectionId: "scenarios", difficulty: "medium", question: "You notice a colleague is struggling and falling behind on their work.",
//     options: [ { text: "Report them to the manager for being slow", value: "A" }, { text: "Offer your help if you have capacity, or suggest they speak to the supervisor", value: "B" }, { text: "Publicly point out their struggles", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s13", sectionId: "scenarios", difficulty: "medium", question: "You are invited to a meeting but have a prior commitment you cannot change.",
//     options: [ { text: "Don't show up and say nothing", value: "A" }, { text: "Inform the meeting organiser as soon as possible, explain briefly, and ask for minutes or updates", value: "B" }, { text: "Accept the invite and then don't attend", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s14", sectionId: "scenarios", difficulty: "medium", question: "Your computer crashes while you are working on an important document you hadn't saved recently.",
//     options: [ { text: "Panic and give up", value: "A" }, { text: "Restart the computer, check for auto-recovery files, and contact IT support if needed", value: "B" }, { text: "Blame IT for the crash", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s23", sectionId: "scenarios", difficulty: "medium", question: "You are asked to complete a task you have never done before and feel unqualified for.",
//     options: [ { text: "Pretend you know how to do it", value: "A" }, { text: "Be honest about your experience level and ask for guidance, training, or resources", value: "B" }, { text: "Refuse to do the task", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s24", sectionId: "scenarios", difficulty: "medium", question: "You find an error in a report that your team is about to submit.",
//     options: [ { text: "Ignore it to avoid delaying the submission", value: "A" }, { text: "Bring the error to the attention of your team lead or manager immediately", value: "B" }, { text: "Secretly correct it without telling anyone", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s25", sectionId: "scenarios", difficulty: "medium", question: "A colleague is often late to team meetings, delaying the start.",
//     options: [ { text: "Complain about them to other colleagues", value: "A" }, { text: "Gently raise the issue with them privately or suggest the team leader address punctuality expectations", value: "B" }, { text: "Start the meeting late every time", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s26", sectionId: "scenarios", difficulty: "medium", question: "You have too many tasks and are struggling to meet all deadlines.",
//     options: [ { text: "Suffer in silence and miss deadlines", value: "A" }, { text: "Communicate proactively with your manager, explain the situation, and discuss potential solutions like reprioritization", value: "B" }, { text: "Delegate your work to colleagues without permission", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s3", sectionId: "scenarios", difficulty: "hard", question: "Your team strongly disagrees on the best approach for a major project. What is a constructive role you could play?",
//     options: [ { text: "Take sides immediately and argue fiercely", value: "A" }, { text: "Facilitate a discussion focusing on pros/cons of each approach, seeking common ground and objective criteria", value: "B" }, { text: "Withdraw from the discussion entirely", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s6", sectionId: "scenarios", difficulty: "hard", question: "You receive instructions for a task that seem ethically questionable.",
//     options: [ { text: "Carry out the task without question", value: "A" }, { text: "Seek clarification on the instructions and express your concerns respectfully to your supervisor or a designated ethics contact", value: "B" }, { text: "Publicly accuse your manager on social media", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s15", sectionId: "scenarios", difficulty: "hard", question: "A client is demanding something unreasonable that goes against company policy.",
//     options: [ { text: "Immediately give in to the client's demand", value: "A" }, { text: "Politely but firmly explain the policy, the reasons behind it, and explore alternative solutions that ARE possible", value: "B" }, { text: "Argue loudly with the client", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s16", sectionId: "scenarios", difficulty: "hard", question: "You discover a significant error made by a senior colleague that could impact the business.",
//     options: [ { text: "Ignore it to avoid conflict", value: "A" }, { text: "Find a discreet and appropriate way to raise the issue, perhaps directly with the colleague or your manager, focusing on the problem not the person", value: "B" }, { text: "Announce the error in a team meeting", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s17", sectionId: "scenarios", difficulty: "hard", question: "Your project's scope is continuously expanding without additional resources ('scope creep').",
//     options: [ { text: "Try to do all the extra work yourself and burn out", value: "A" }, { text: "Document the changes, assess the impact on timelines/resources, and discuss concerns with your manager to re-evaluate priorities", value: "B" }, { text: "Refuse to do any more work", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s18", sectionId: "scenarios", difficulty: "hard", question: "A team member consistently misses deadlines, affecting the entire team's progress.",
//     options: [ { text: "Publicly shame them", value: "A" }, { text: "Have a private conversation to understand the issues, offer support, and then escalate to a manager together if needed", value: "B" }, { text: "Secretly do their work for them", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s27", sectionId: "scenarios", difficulty: "hard", question: "You witness a colleague subtly undermining another colleague in meetings.",
//     options: [ { text: "Join in with the undermining", value: "A" }, { text: "Speak to the targeted colleague privately to offer support, and consider speaking to the undermining colleague or a manager if it persists", value: "B" }, { text: "Ignore it as it's not your problem", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s28", sectionId: "scenarios", difficulty: "hard", question: "Your manager gives you vague feedback like 'do better'.",
//     options: [ { text: "Assume you know what they mean", value: "A" }, { text: "Politely ask for specific examples or clarification on what 'better' looks like and how you can achieve it", value: "B" }, { text: "Complain about the vague feedback", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s29", sectionId: "scenarios", difficulty: "hard", question: "A colleague asks you to cover for them by lying to the manager.",
//     options: [ { text: "Agree to lie for them", value: "A" }, { text: "Politely refuse, explaining you're not comfortable being dishonest, but perhaps offer help in another way", value: "B" }, { text: "Report them immediately to HR", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "s30", sectionId: "scenarios", difficulty: "hard", question: "You are given responsibility for a project but lack the authority to make necessary decisions.",
//     options: [ { text: "Struggle and fail without raising the issue", value: "A" }, { text: "Discuss the authority gap with your manager, outlining the decisions needed and proposing a solution", value: "B" }, { text: "Overstep your authority anyway", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d1", sectionId: "digital", difficulty: "easy", question: "What makes a password strong?",
//     options: [ { text: "Using your pet's name", value: "A" }, { text: "A mix of uppercase letters, lowercase letters, numbers, and symbols", value: "B" }, { text: "'password'", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d4", sectionId: "digital", difficulty: "easy", question: "What does 'CC' usually mean when sending an email?",
//     options: [ { text: "Confidential Copy", value: "A" }, { text: "Carbon Copy (sends a visible copy to others)", value: "B" }, { text: "Cancel Copy", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d7", sectionId: "digital", difficulty: "easy", question: "How do you save a document you are working on?",
//     options: [ { text: "Close the program", value: "A" }, { text: "Use the 'Save' or 'Save As' command, usually found in the 'File' menu", value: "B" }, { text: "Unplug the computer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d8", sectionId: "digital", difficulty: "easy", question: "What is a web browser?",
//     options: [ { text: "A type of computer virus", value: "A" }, { text: "Software used to access and view websites (e.g., Chrome, Firefox, Safari)", value: "B" }, { text: "A physical part of the computer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d9", sectionId: "digital", difficulty: "easy", question: "What does the 'Reply All' button do in email?",
//     options: [ { text: "Replies only to the sender", value: "A" }, { text: "Replies to the sender and everyone else who was copied (CC'd or To'd)", value: "B" }, { text: "Deletes the email", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d10", sectionId: "digital", difficulty: "easy", question: "What is 'phishing'?",
//     options: [ { text: "A type of fishing sport", value: "A" }, { text: "Emails or messages trying to trick you into giving personal information", value: "B" }, { text: "A software update", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d20", sectionId: "digital", difficulty: "easy", question: "What does the 'BCC' field in an email do?",
//     options: [ { text: "Bold Carbon Copy", value: "A" }, { text: "Blind Carbon Copy (sends a copy secretly; other recipients don't see BCC'd addresses)", value: "B" }, { text: "Basic Carbon Copy", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d21", sectionId: "digital", difficulty: "easy", question: "What keyboard shortcut is commonly used for 'Copy'?",
//     options: [ { text: "Ctrl + V (or Cmd + V)", value: "A" }, { text: "Ctrl + C (or Cmd + C)", value: "B" }, { text: "Ctrl + X (or Cmd + X)", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d22", sectionId: "digital", difficulty: "easy", question: "What keyboard shortcut is commonly used for 'Paste'?",
//     options: [ { text: "Ctrl + V (or Cmd + V)", value: "A" }, { text: "Ctrl + C (or Cmd + C)", value: "B" }, { text: "Ctrl + P (or Cmd + P)", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "d23", sectionId: "digital", difficulty: "easy", question: "What is a '.pdf' file?",
//     options: [ { text: "A music file", value: "A" }, { text: "A document file format that preserves formatting across different systems", value: "B" }, { text: "A video game file", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d2", sectionId: "digital", difficulty: "medium", question: "You receive an unexpected email asking for your bank details or password. What should you do?",
//     options: [ { text: "Reply with the requested information", value: "A" }, { text: "Be suspicious, do not click links or provide info, delete it, and report as phishing if possible", value: "B" }, { text: "Forward it to all your contacts", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d5", sectionId: "digital", difficulty: "medium", question: "Why is it important to log out of websites and applications on shared or public computers?",
//     options: [ { text: "It makes the computer faster", value: "A" }, { text: "To prevent others from accessing your accounts and personal information", value: "B" }, { text: "It's not important", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d11", sectionId: "digital", difficulty: "medium", question: "What is a common sign of a phishing email?",
//     options: [ { text: "Perfect grammar and spelling", value: "A" }, { text: "Urgent threats, requests for sensitive info, generic greetings, suspicious links/senders", value: "B" }, { text: "An offer for free kittens", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d12", sectionId: "digital", difficulty: "medium", question: "What is 'cloud storage' (e.g., Google Drive, Dropbox, OneDrive)?",
//     options: [ { text: "Storing files on a weather satellite", value: "A" }, { text: "Saving files online so they can be accessed from different devices", value: "B" }, { text: "A type of USB stick", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d13", sectionId: "digital", difficulty: "medium", question: "How can you verify if a website is secure for entering sensitive information (like passwords or credit cards)?",
//     options: [ { text: "If the website looks visually appealing", value: "A" }, { text: "Look for 'https' in the address bar and a padlock icon", value: "B" }, { text: "If it asks for lots of information", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d14", sectionId: "digital", difficulty: "medium", question: "What is the risk of using the same password for multiple websites?",
//     options: [ { text: "It's easier to remember", value: "A" }, { text: "If one site is breached, attackers can access your other accounts", value: "B" }, { text: "There is no risk", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d24", sectionId: "digital", difficulty: "medium", question: "What does clearing your browser's cache and cookies do?",
//     options: [ { text: "Speeds up your internet connection", value: "A" }, { text: "Removes temporary files and website data stored on your computer", value: "B" }, { text: "Installs antivirus software", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d25", sectionId: "digital", difficulty: "medium", question: "What is a potential danger of connecting to unsecured public Wi-Fi?",
//     options: [ { text: "Your battery drains faster", value: "A" }, { text: "Others on the network could potentially intercept your data", value: "B" }, { text: "It improves your phone signal", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d26", sectionId: "digital", difficulty: "medium", question: "What is 'cyberbullying'?",
//     options: [ { text: "A type of computer game", value: "A" }, { text: "Using electronic communication to bully a person, typically by sending intimidating or threatening messages", value: "B" }, { text: "A way to make friends online", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d27", sectionId: "digital", difficulty: "medium", question: "Why is it advisable to back up important digital files?",
//     options: [ { text: "It takes up too much space", value: "A" }, { text: "To prevent data loss in case of hardware failure, deletion, or malware attack", value: "B" }, { text: "Backups are unnecessary", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d3", sectionId: "digital", difficulty: "hard", question: "What contributes to your 'digital footprint'?",
//     options: [ { text: "Only your email address", value: "A" }, { text: "Social media posts, Browse history, online purchases, comments, location data, etc.", value: "B" }, { text: "The brand of your computer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d6", sectionId: "digital", difficulty: "hard", question: "What is two-factor authentication (2FA) or multi-factor authentication (MFA)?",
//     options: [ { text: "A password that is twice as long", value: "A" }, { text: "An extra security step requiring something you know (password) plus something you have (phone code) or are (fingerprint)", value: "B" }, { text: "A backup password", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d15", sectionId: "digital", difficulty: "hard", question: "What does 'malware' encompass?",
//     options: [ { text: "Only computer viruses", value: "A" }, { text: "Malicious software including viruses, ransomware, spyware, trojans etc.", value: "B" }, { text: "Poorly designed websites", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d16", sectionId: "digital", difficulty: "hard", question: "Why is it important to keep your operating system and software updated?",
//     options: [ { text: "Updates often slow down your computer", value: "A" }, { text: "Updates often include security patches that protect against known vulnerabilities", value: "B" }, { text: "Updates change the way software looks unnecessarily", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d17", sectionId: "digital", difficulty: "hard", question: "What is a VPN (Virtual Private Network) primarily used for?",
//     options: [ { text: "To speed up your internet connection", value: "A" }, { text: "To create a secure, encrypted connection over a public network, enhancing privacy", value: "B" }, { text: "To get free Wi-Fi", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d18", sectionId: "digital", difficulty: "hard", question: "How can you manage your online reputation for professional purposes?",
//     options: [ { text: "Delete all social media accounts", value: "A" }, { text: "Be mindful of what you post, manage privacy settings, and build a positive presence on professional networks like LinkedIn", value: "B" }, { text: "Use an anonymous username for everything", value: "C" } ],
//     correctAnswer: "B",
//   },
//     {
//     id: "d19", sectionId: "digital", difficulty: "hard", question: "What is the difference between HTTP and HTTPS?",
//     options: [ { text: "There is no difference", value: "A" }, { text: "HTTPS encrypts the data transmitted between your browser and the website, making it secure", value: "B" }, { text: "HTTP is faster than HTTPS", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d28", sectionId: "digital", difficulty: "hard", question: "What are browser 'cookies' used for?",
//     options: [ { text: "To clean your computer screen", value: "A" }, { text: "To store small pieces of data on your computer to remember preferences, login status, or track activity", value: "B" }, { text: "To speed up web Browse significantly", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d29", sectionId: "digital", difficulty: "hard", question: "What does 'Creative Commons' licensing refer to?",
//     options: [ { text: "A type of paid software license", value: "A" }, { text: "Licenses that allow creators to share their work with specific permissions for others to use, share, or build upon", value: "B" }, { text: "A social media platform", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d30", sectionId: "digital", difficulty: "hard", question: "What is 'ransomware'?",
//     options: [ { text: "Software offered for free", value: "A" }, { text: "Malware that encrypts your files and demands payment for their release", value: "B" }, { text: "Antivirus software", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d31", sectionId: "digital", difficulty: "hard", question: "How can using online collaboration tools (like Google Docs, Microsoft Teams) improve teamwork?",
//     options: [ { text: "By making communication more difficult", value: "A" }, { text: "By allowing real-time co-editing, easy file sharing, and centralized communication", value: "B" }, { text: "By automatically completing tasks", value: "C" } ],
//     correctAnswer: "B",
//   },
// ];

// const allQuestions: Question[] = [
//   // ===================
//   // Readiness - Easy
//   // ===================
//   {
//     id: "r1", sectionId: "readiness", difficulty: "easy", question: "What should you usually bring to a job interview?",
//     options: [ { text: "Just yourself", value: "A" }, { text: "Copies of your CV and a pen", value: "B" }, { text: "Your lunch", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r2", sectionId: "readiness", difficulty: "easy", question: "How early should you aim to arrive for an interview?",
//     options: [ { text: "Exactly on time", value: "A" }, { text: "One hour early", value: "B" }, { text: "10-15 minutes early", value: "C" } ],
//     correctAnswer: "C",
//   },
//   {
//     id: "r6", sectionId: "readiness", difficulty: "easy", question: "What is generally appropriate attire for most job interviews?",
//     options: [ { text: "Beachwear", value: "A" }, { text: "Clean, smart casual or formal clothes", value: "B" }, { text: "Your favourite band t-shirt", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r9", sectionId: "readiness", difficulty: "easy", question: "What should you do with your phone during an interview?",
//     options: [ { text: "Turn it completely off or on silent", value: "A" }, { text: "Leave it on loud", value: "B" }, { text: "Text your friends", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r10", sectionId: "readiness", difficulty: "easy", question: "What does 'CV' stand for?",
//     options: [ { text: "Current Venture", value: "A" }, { text: "Curriculum Vitae", value: "B" }, { text: "Company Volunteer", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "r11", sectionId: "readiness", difficulty: "easy", question: "What is a common first question in an interview?",
//     options: [ { text: "What are your salary expectations?", value: "A" }, { text: "Tell me about yourself.", value: "B" }, { text: "When can you start?", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "r12", sectionId: "readiness", difficulty: "easy", question: "How should you greet the interviewer?",
//     options: [ { text: "With a confident handshake (if appropriate) and eye contact", value: "A" }, { text: "Nod silently", value: "B" }, { text: "High five", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r21", sectionId: "readiness", difficulty: "easy", question: "What is the main purpose of a CV?",
//     options: [ { text: "To list your hobbies", value: "A" }, { text: "To provide a summary of your skills, experience, and education", value: "B" }, { text: "To share your life story", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r22", sectionId: "readiness", difficulty: "easy", question: "Should you include references on your CV when first applying?",
//     options: [ { text: "Yes, always list names and contact details", value: "A" }, { text: "No, usually state 'References available upon request'", value: "B" }, { text: "Include your family members as references", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r23", sectionId: "readiness", difficulty: "easy", question: "What's a simple way to show you're listening during an interview?",
//     options: [ { text: "Interrupt frequently", value: "A" }, { text: "Nod occasionally and maintain eye contact", value: "B" }, { text: "Stare blankly at the wall", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r24", sectionId: "readiness", difficulty: "easy", question: "Is it okay to ask the interviewer to repeat a question?",
//     options: [ { text: "No, never", value: "A" }, { text: "Yes, if you didn't hear or understand it clearly", value: "B" }, { text: "Only if you weren't paying attention", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Readiness - Medium
//   // ===================
//   {
//     id: "r3", sectionId: "readiness", difficulty: "medium", question: "What does the STAR method (Situation, Task, Action, Result) help you answer?",
//     options: [ { text: "Questions about your hobbies", value: "A" }, { text: "Behavioural questions ('Tell me about a time when...')", value: "B" }, { text: "Technical skill tests", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r4", sectionId: "readiness", difficulty: "medium", question: "Why is researching a company BEFORE an interview important?",
//     options: [ { text: "To find out office gossip", value: "A" }, { text: "To show genuine interest and tailor your answers", value: "B" }, { text: "To check their stock price", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r7", sectionId: "readiness", difficulty: "medium", question: "What is a good type of question to ask the interviewer at the end?",
//     options: [ { text: "A question about the role specifics or team culture", value: "A" }, { text: "A question about the interviewer's personal life", value: "B" }, { text: "Asking if you got the job yet", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r13", sectionId: "readiness", difficulty: "medium", question: "What's the purpose of asking 'Do you have any questions for us?'",
//     options: [ { text: "To fill time", value: "A" }, { text: "To gauge your interest and engagement", value: "B" }, { text: "To test your memory", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "r14", sectionId: "readiness", difficulty: "medium", question: "How can you prepare for common interview questions?",
//     options: [ { text: "Hope for the best", value: "A" }, { text: "Think about examples from your experience and practice answering", value: "B" }, { text: "Memorise generic answers from the internet", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r15", sectionId: "readiness", difficulty: "medium", question: "What does positive body language include during an interview?",
//     options: [ { text: "Slouching and avoiding eye contact", value: "A" }, { text: "Sitting up straight, nodding, and maintaining good eye contact", value: "B" }, { text: "Folding your arms and frowning", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "r16", sectionId: "readiness", difficulty: "medium", question: "If an interview is online, what should you check beforehand?",
//     options: [ { text: "Your internet connection, camera, and microphone", value: "A" }, { text: "The weather outside", value: "B" }, { text: "Your social media notifications", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "r25", sectionId: "readiness", difficulty: "medium", question: "How can you explain a gap in your employment history?",
//     options: [ { text: "Make up a fictional job", value: "A" }, { text: "Be honest and focus on any skills gained or productive activities during that time", value: "B" }, { text: "Avoid mentioning it", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r26", sectionId: "readiness", difficulty: "medium", question: "What are some different types of interviews you might encounter?",
//     options: [ { text: "Only face-to-face interviews exist", value: "A" }, { text: "Phone screens, video interviews, panel interviews, technical tests", value: "B" }, { text: "Interviews conducted while skydiving", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r27", sectionId: "readiness", difficulty: "medium", question: "Why might an interviewer ask about your reasons for leaving a previous job?",
//     options: [ { text: "To make you feel bad", value: "A" }, { text: "To understand your motivations and see if you left on good terms", value: "B" }, { text: "They are just being nosy", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r28", sectionId: "readiness", difficulty: "medium", question: "How should you tailor your CV for a specific job application?",
//     options: [ { text: "Use the exact same CV for every job", value: "A" }, { text: "Highlight the skills and experiences most relevant to the job description", value: "B" }, { text: "Make the font size much larger", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Readiness - Hard
//   // ===================
//   {
//     id: "r5", sectionId: "readiness", difficulty: "hard", question: "If asked about a weakness, what's the MOST effective approach?",
//     options: [ { text: "Disguise a strength as a weakness (e.g., 'I'm a perfectionist')", value: "A" }, { text: "Mention a genuine, relevant weakness and explain how you're working on it", value: "B" }, { text: "Refuse to answer the question", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r8", sectionId: "readiness", difficulty: "hard", question: "How can you best demonstrate enthusiasm during a video interview?",
//     options: [ { text: "Use excessive hand gestures", value: "A" }, { text: "Maintain good eye contact with the camera, smile genuinely, and show active listening", value: "B" }, { text: "Constantly interrupt the interviewer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r17", sectionId: "readiness", difficulty: "hard", question: "What should you do after a job interview?",
//     options: [ { text: "Wait passively for them to contact you", value: "A" }, { text: "Send a thank-you email or note within 24-48 hours", value: "B" }, { text: "Call them multiple times a day", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r18", sectionId: "readiness", difficulty: "hard", question: "How should you respond if asked about salary expectations early on?",
//     options: [ { text: "Give a very high number immediately", value: "A" }, { text: "Try to defer the discussion or provide a researched range, stating it's negotiable", value: "B" }, { text: "Say you'll work for free", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r19", sectionId: "readiness", difficulty: "hard", question: "What if an interviewer asks an inappropriate or illegal question (e.g., about marital status, religion)?",
//     options: [ { text: "You must answer it truthfully", value: "A" }, { text: "Politely deflect, question its relevance, or state you're not comfortable answering", value: "B" }, { text: "Walk out of the interview immediately", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "r20", sectionId: "readiness", difficulty: "hard", question: "How can you handle interview nerves effectively?",
//     options: [ { text: "Avoid interviews altogether", value: "A" }, { text: "Prepare thoroughly, practice deep breathing, and focus on your qualifications", value: "B" }, { text: "Drink lots of caffeine beforehand", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r29", sectionId: "readiness", difficulty: "hard", question: "How should you professionally handle rejection after an interview?",
//     options: [ { text: "Send an angry email", value: "A" }, { text: "Thank the interviewer for their time and potentially ask for feedback to improve", value: "B" }, { text: "Ignore the rejection message", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r30", sectionId: "readiness", difficulty: "hard", question: "What does 'company culture' refer to, and why is it important to consider?",
//     options: [ { text: "The company's logo design", value: "A" }, { text: "The shared values, attitudes, and practices that shape the work environment; important for job satisfaction", value: "B" }, { text: "How often the company holds parties", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r31", sectionId: "readiness", difficulty: "hard", question: "If you receive a job offer, is it acceptable to negotiate the salary or benefits?",
//     options: [ { text: "No, you must accept the first offer", value: "A" }, { text: "Yes, it's often acceptable to professionally negotiate based on research and your value", value: "B" }, { text: "Only if you threaten to leave", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "r32", sectionId: "readiness", difficulty: "hard", question: "How can asking about professional development opportunities benefit you in an interview?",
//     options: [ { text: "It makes you look demanding", value: "A" }, { text: "It shows you are ambitious, eager to learn, and interested in growing with the company", value: "B" }, { text: "It's irrelevant to the job", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Workplace - Easy
//   // ===================
//   {
//     id: "w1", sectionId: "workplace", difficulty: "easy", question: "If you're unsure how to do a task at work, what should you do first?",
//     options: [ { text: "Guess", value: "A" }, { text: "Ask a colleague or your supervisor for clarification", value: "B" }, { text: "Ignore the task", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w2", sectionId: "workplace", difficulty: "easy", question: "What does 'being punctual' generally mean in a workplace?",
//     options: [ { text: "Arriving whenever you feel like it", value: "A" }, { text: "Arriving on time or slightly early for your shift/meetings", value: "B" }, { text: "Leaving early every day", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w6", sectionId: "workplace", difficulty: "easy", question: "What should you do if you are running late for work?",
//     options: [ { text: "Hope nobody notices", value: "A" }, { text: "Inform your supervisor as soon as possible", value: "B" }, { text: "Blame traffic when you arrive", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "w9", sectionId: "workplace", difficulty: "easy", question: "What is the typical expectation for workplace dress code, unless specified otherwise?",
//     options: [ { text: "Casual clothes like trackpants", value: "A" }, { text: "Clean, neat, and appropriate for the industry", value: "B" }, { text: "Costumes", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w10", sectionId: "workplace", difficulty: "easy", question: "Where should you usually take personal phone calls at work?",
//     options: [ { text: "At your desk loudly", value: "A" }, { text: "In a break area or outside, briefly and quietly", value: "B" }, { text: "During important meetings", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w11", sectionId: "workplace", difficulty: "easy", question: "What is important when using shared equipment like printers or microwaves?",
//     options: [ { text: "Leave it messy for the next person", value: "A" }, { text: "Use it respectfully and clean up after yourself", value: "B" }, { text: "Take it home", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w20", sectionId: "workplace", difficulty: "easy", question: "What's a professional way to answer your work phone?",
//     options: [ { text: "'Yeah?'", value: "A" }, { text: "Greeting, company/department name, your name, offer assistance (e.g., 'Good morning, [Company], [Your Name] speaking, how can I help?')", value: "B" }, { text: "Silence", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w21", sectionId: "workplace", difficulty: "easy", question: "Why is it important to keep your workspace relatively tidy?",
//     options: [ { text: "It makes it harder to find things", value: "A" }, { text: "It presents a professional image and helps you stay organised", value: "B" }, { text: "Tidiness is not important", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w22", sectionId: "workplace", difficulty: "easy", question: "If you need information about company policy (e.g., leave), where should you look first?",
//     options: [ { text: "Ask a random colleague", value: "A" }, { text: "Check the company intranet, employee handbook, or ask HR/your manager", value: "B" }, { text: "Search on Google", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w23", sectionId: "workplace", difficulty: "easy", question: "What should you do before leaving work for the day?",
//     options: [ { text: "Run out the door as fast as possible", value: "A" }, { text: "Briefly check emails, tidy your desk, and secure sensitive information", value: "B" }, { text: "Leave your computer logged in", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Workplace - Medium
//   // ===================
//   {
//     id: "w3", sectionId: "workplace", difficulty: "medium", question: "How should you best handle constructive criticism from your manager?",
//     options: [ { text: "Get defensive and argue", value: "A" }, { text: "Listen actively, ask clarifying questions, and see it as a chance to improve", value: "B" }, { text: "Complain about your manager to colleagues", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w4", sectionId: "workplace", difficulty: "medium", question: "What is a key characteristic of good teamwork?",
//     options: [ { text: "Focusing only on your individual tasks", value: "A" }, { text: "Communicating effectively and supporting colleagues", value: "B" }, { text: "Trying to outperform everyone else", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "w7", sectionId: "workplace", difficulty: "medium", question: "Why is maintaining a positive attitude beneficial at work?",
//     options: [ { text: "It prevents you from getting promoted", value: "A" }, { text: "It improves morale, collaboration, and resilience to challenges", value: "B" }, { text: "It annoys your coworkers", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w12", sectionId: "workplace", difficulty: "medium", question: "What does it mean to 'take initiative' at work?",
//     options: [ { text: "Waiting to be told exactly what to do", value: "A" }, { text: "Identifying opportunities or problems and taking action without being asked", value: "B" }, { text: "Taking long breaks", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w13", sectionId: "workplace", difficulty: "medium", question: "How should you generally communicate important information to your team?",
//     options: [ { text: "Through vague hints", value: "A" }, { text: "Clearly, concisely, and through appropriate channels (email, meeting, etc.)", value: "B" }, { text: "Gossip", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "w14", sectionId: "workplace", difficulty: "medium", question: "What is professional email etiquette?",
//     options: [ { text: "Using slang and emojis excessively", value: "A" }, { text: "Using a clear subject line, proofreading, and responding promptly", value: "B" }, { text: "Writing in all capital letters", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w15", sectionId: "workplace", difficulty: "medium", question: "If you make a mistake at work, what is the best course of action?",
//     options: [ { text: "Try to hide it or blame someone else", value: "A" }, { text: "Own up to it, inform your supervisor if necessary, and learn from it", value: "B" }, { text: "Pretend it never happened", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w24", sectionId: "workplace", difficulty: "medium", question: "What are some tools or techniques for managing your time effectively?",
//     options: [ { text: "Working randomly on tasks", value: "A" }, { text: "Using to-do lists, calendars, prioritizing tasks (e.g., Eisenhower Matrix)", value: "B" }, { text: "Procrastinating until the last minute", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w25", sectionId: "workplace", difficulty: "medium", question: "How should you generally interact with colleagues on social media?",
//     options: [ { text: "Overshare personal details and complain about work", value: "A" }, { text: "Maintain professionalism, be mindful of company image, and avoid controversial topics", value: "B" }, { text: "Tag them in embarrassing photos", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w26", sectionId: "workplace", difficulty: "medium", question: "Why is understanding a meeting's agenda important before attending?",
//     options: [ { text: "So you know when the snacks will be served", value: "A" }, { text: "To prepare relevant points, understand the purpose, and participate effectively", value: "B" }, { text: "It's not important to read the agenda", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "w27", sectionId: "workplace", difficulty: "medium", question: "What does being 'proactive' mean in a work context?",
//     options: [ { text: "Reacting only when problems occur", value: "A" }, { text: "Anticipating needs or issues and taking steps to address them before they escalate", value: "B" }, { text: "Avoiding work", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Workplace - Hard
//   // ===================
//   {
//     id: "w5", sectionId: "workplace", difficulty: "hard", question: "You witness a coworker potentially breaking a safety rule. What's the appropriate response?",
//     options: [ { text: "Film it for social media", value: "A" }, { text: "Address it directly if safe, or report it through official channels as per company policy", value: "B" }, { text: "Join in", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "w8", sectionId: "workplace", difficulty: "hard", question: "How can you proactively manage your workload to prevent burnout?",
//     options: [ { text: "Say yes to every request immediately", value: "A" }, { text: "Prioritize tasks using methods like urgency/importance, set realistic deadlines, and communicate bandwidth", value: "B" }, { text: "Work 16 hours every day", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "w16", sectionId: "workplace", difficulty: "hard", question: "How should you handle a conflict with a coworker?",
//     options: [ { text: "Gossip about them to others", value: "A" }, { text: "Address the issue privately and respectfully with the coworker first, focusing on behaviour not personality", value: "B" }, { text: "Escalate immediately to the CEO", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w17", sectionId: "workplace", difficulty: "hard", question: "What does 'maintaining confidentiality' mean in a workplace context?",
//     options: [ { text: "Sharing company secrets with competitors", value: "A" }, { text: "Protecting sensitive information about the company, clients, and colleagues", value: "B" }, { text: "Posting private customer data online", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w18", sectionId: "workplace", difficulty: "hard", question: "You disagree with a decision made by your manager. How should you voice your concerns?",
//     options: [ { text: "Complain loudly in the open office", value: "A" }, { text: "Request a private meeting to respectfully explain your perspective and offer alternatives, then accept the final decision", value: "B" }, { text: "Undermine the decision secretly", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w19", sectionId: "workplace", difficulty: "hard", question: "How can you contribute to a positive and inclusive work environment?",
//     options: [ { text: "Exclude colleagues who are different", value: "A" }, { text: "Be respectful, value diverse perspectives, and challenge inappropriate behaviour", value: "B" }, { text: "Make offensive jokes", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "w28", sectionId: "workplace", difficulty: "hard", question: "What is the role of a mentor in professional development?",
//     options: [ { text: "To do your work for you", value: "A" }, { text: "To provide guidance, share experience, offer advice, and support your career growth", value: "B" }, { text: "To criticize your every move", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w29", sectionId: "workplace", difficulty: "hard", question: "You accidentally receive an email with confidential information not meant for you.",
//     options: [ { text: "Forward it to all your friends", value: "A" }, { text: "Discreetly inform the sender, delete the email, and do not share the information", value: "B" }, { text: "Use the information for personal gain", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w30", sectionId: "workplace", difficulty: "hard", question: "What challenges might arise in cross-departmental collaboration, and how can they be addressed?",
//     options: [ { text: "Collaboration is always easy", value: "A" }, { text: "Differing priorities or communication styles; address through clear goals, regular communication, and defined roles", value: "B" }, { text: "Sabotaging the other department's work", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "w31", sectionId: "workplace", difficulty: "hard", question: "How can providing constructive feedback upwards (to a manager) be done effectively?",
//     options: [ { text: "Criticize them publicly in a meeting", value: "A" }, { text: "Choose an appropriate time, focus on specific observations/impacts, be solution-oriented, and maintain respect", value: "B" }, { text: "Send anonymous complaints", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Scenarios - Easy
//   // ===================
//   {
//     id: "s1", sectionId: "scenarios", difficulty: "easy", question: "A customer is angry about a faulty product they bought. What's a good first step?",
//     options: [ { text: "Argue with the customer", value: "A" }, { text: "Listen calmly to their complaint and apologise for the inconvenience", value: "B" }, { text: "Hide from the customer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s4", sectionId: "scenarios", difficulty: "easy", question: "You've finished your assigned tasks for the day. What should you proactively do?",
//     options: [ { text: "Pack up and leave immediately", value: "A" }, { text: "Ask your supervisor or a colleague if they need help with anything", value: "B" }, { text: "Start planning your weekend", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s7", sectionId: "scenarios", difficulty: "easy", question: "The office printer is out of paper. What should you do?",
//     options: [ { text: "Complain about it", value: "A" }, { text: "Find where the paper is stored and refill it, or notify the person responsible", value: "B" }, { text: "Wait for someone else to fix it", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s8", sectionId: "scenarios", difficulty: "easy", question: "A visitor arrives for a meeting with your manager, who is currently busy. What should you do?",
//     options: [ { text: "Ignore the visitor", value: "A" }, { text: "Greet them politely, offer them a seat, and let your manager know they've arrived", value: "B" }, { text: "Tell them to come back later", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "s9", sectionId: "scenarios", difficulty: "easy", question: "You answer the phone and the caller asks for someone who isn't available.",
//     options: [ { text: "Hang up", value: "A" }, { text: "Politely take a message, including their name, number, and reason for calling", value: "B" }, { text: "Tell them to call back repeatedly", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "s10", sectionId: "scenarios", difficulty: "easy", question: "You need a stapler but can't find yours. What's the best approach?",
//     options: [ { text: "Take one from a colleague's desk without asking", value: "A" }, { text: "Ask a nearby colleague if you could briefly borrow theirs", value: "B" }, { text: "Submit a formal request for a new stapler", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s19", sectionId: "scenarios", difficulty: "easy", question: "Your colleague asks where the coffee mugs are kept.",
//     options: [ { text: "Tell them to find it themselves", value: "A" }, { text: "Politely show them where the mugs are located", value: "B" }, { text: "Hide the mugs", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s20", sectionId: "scenarios", difficulty: "easy", question: "You see some rubbish on the floor in the office kitchen.",
//     options: [ { text: "Step over it", value: "A" }, { text: "Pick it up and put it in the bin", value: "B" }, { text: "Complain about the cleaners", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "s21", sectionId: "scenarios", difficulty: "easy", question: "You need to use the photocopier, but a colleague is already using it.",
//     options: [ { text: "Interrupt them and demand they hurry up", value: "A" }, { text: "Wait patiently nearby or ask politely how long they might be", value: "B" }, { text: "Go back to your desk and give up", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s22", sectionId: "scenarios", difficulty: "easy", question: "Your computer mouse stops working.",
//     options: [ { text: "Throw it against the wall", value: "A" }, { text: "Check the connection, try restarting the computer, or ask IT for help", value: "B" }, { text: "Borrow a colleague's without asking", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Scenarios - Medium
//   // ===================
//   {
//     id: "s2", sectionId: "scenarios", difficulty: "medium", question: "You accidentally delete an important shared file. What is the FIRST thing you should do?",
//     options: [ { text: "Hope no one notices", value: "A" }, { text: "Immediately inform your supervisor or IT department and explain what happened", value: "B" }, { text: "Try to blame the computer system", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s5", sectionId: "scenarios", difficulty: "medium", question: "A colleague asks for help with a non-urgent task, but you have an important deadline. How do you respond politely?",
//     options: [ { text: "Say 'No, I'm too busy' and turn away", value: "A" }, { text: "Explain your deadline but offer to help when you're free, or suggest someone else who might assist", value: "B" }, { text: "Agree to help immediately and risk missing your deadline", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s11", sectionId: "scenarios", difficulty: "medium", question: "You receive conflicting instructions from two different managers. What should you do?",
//     options: [ { text: "Ignore both sets of instructions", value: "A" }, { text: "Politely seek clarification, perhaps by bringing both managers together or asking your direct supervisor", value: "B" }, { text: "Choose the easier instruction", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s12", sectionId: "scenarios", difficulty: "medium", question: "You notice a colleague is struggling and falling behind on their work.",
//     options: [ { text: "Report them to the manager for being slow", value: "A" }, { text: "Offer your help if you have capacity, or suggest they speak to the supervisor", value: "B" }, { text: "Publicly point out their struggles", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s13", sectionId: "scenarios", difficulty: "medium", question: "You are invited to a meeting but have a prior commitment you cannot change.",
//     options: [ { text: "Don't show up and say nothing", value: "A" }, { text: "Inform the meeting organiser as soon as possible, explain briefly, and ask for minutes or updates", value: "B" }, { text: "Accept the invite and then don't attend", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s14", sectionId: "scenarios", difficulty: "medium", question: "Your computer crashes while you are working on an important document you hadn't saved recently.",
//     options: [ { text: "Panic and give up", value: "A" }, { text: "Restart the computer, check for auto-recovery files, and contact IT support if needed", value: "B" }, { text: "Blame IT for the crash", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s23", sectionId: "scenarios", difficulty: "medium", question: "You are asked to complete a task you have never done before and feel unqualified for.",
//     options: [ { text: "Pretend you know how to do it", value: "A" }, { text: "Be honest about your experience level and ask for guidance, training, or resources", value: "B" }, { text: "Refuse to do the task", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s24", sectionId: "scenarios", difficulty: "medium", question: "You find an error in a report that your team is about to submit.",
//     options: [ { text: "Ignore it to avoid delaying the submission", value: "A" }, { text: "Bring the error to the attention of your team lead or manager immediately", value: "B" }, { text: "Secretly correct it without telling anyone", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s25", sectionId: "scenarios", difficulty: "medium", question: "A colleague is often late to team meetings, delaying the start.",
//     options: [ { text: "Complain about them to other colleagues", value: "A" }, { text: "Gently raise the issue with them privately or suggest the team leader address punctuality expectations", value: "B" }, { text: "Start the meeting late every time", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s26", sectionId: "scenarios", difficulty: "medium", question: "You have too many tasks and are struggling to meet all deadlines.",
//     options: [ { text: "Suffer in silence and miss deadlines", value: "A" }, { text: "Communicate proactively with your manager, explain the situation, and discuss potential solutions like reprioritization", value: "B" }, { text: "Delegate your work to colleagues without permission", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Scenarios - Hard
//   // ===================
//   {
//     id: "s3", sectionId: "scenarios", difficulty: "hard", question: "Your team strongly disagrees on the best approach for a major project. What is a constructive role you could play?",
//     options: [ { text: "Take sides immediately and argue fiercely", value: "A" }, { text: "Facilitate a discussion focusing on pros/cons of each approach, seeking common ground and objective criteria", value: "B" }, { text: "Withdraw from the discussion entirely", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s6", sectionId: "scenarios", difficulty: "hard", question: "You receive instructions for a task that seem ethically questionable.",
//     options: [ { text: "Carry out the task without question", value: "A" }, { text: "Seek clarification on the instructions and express your concerns respectfully to your supervisor or a designated ethics contact", value: "B" }, { text: "Publicly accuse your manager on social media", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s15", sectionId: "scenarios", difficulty: "hard", question: "A client is demanding something unreasonable that goes against company policy.",
//     options: [ { text: "Immediately give in to the client's demand", value: "A" }, { text: "Politely but firmly explain the policy, the reasons behind it, and explore alternative solutions that ARE possible", value: "B" }, { text: "Argue loudly with the client", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s16", sectionId: "scenarios", difficulty: "hard", question: "You discover a significant error made by a senior colleague that could impact the business.",
//     options: [ { text: "Ignore it to avoid conflict", value: "A" }, { text: "Find a discreet and appropriate way to raise the issue, perhaps directly with the colleague or your manager, focusing on the problem not the person", value: "B" }, { text: "Announce the error in a team meeting", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s17", sectionId: "scenarios", difficulty: "hard", question: "Your project's scope is continuously expanding without additional resources ('scope creep').",
//     options: [ { text: "Try to do all the extra work yourself and burn out", value: "A" }, { text: "Document the changes, assess the impact on timelines/resources, and discuss concerns with your manager to re-evaluate priorities", value: "B" }, { text: "Refuse to do any more work", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "s18", sectionId: "scenarios", difficulty: "hard", question: "A team member consistently misses deadlines, affecting the entire team's progress.",
//     options: [ { text: "Publicly shame them", value: "A" }, { text: "Have a private conversation to understand the issues, offer support, and then escalate to a manager together if needed", value: "B" }, { text: "Secretly do their work for them", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s27", sectionId: "scenarios", difficulty: "hard", question: "You witness a colleague subtly undermining another colleague in meetings.",
//     options: [ { text: "Join in with the undermining", value: "A" }, { text: "Speak to the targeted colleague privately to offer support, and consider speaking to the undermining colleague or a manager if it persists", value: "B" }, { text: "Ignore it as it's not your problem", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "s28", sectionId: "scenarios", difficulty: "hard", question: "Your manager gives you vague feedback like 'do better'.",
//     options: [ { text: "Assume you know what they mean", value: "A" }, { text: "Politely ask for specific examples or clarification on what 'better' looks like and how you can achieve it", value: "B" }, { text: "Complain about the vague feedback", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "s29", sectionId: "scenarios", difficulty: "hard", question: "A colleague asks you to cover for them by lying to the manager.",
//     options: [ { text: "Agree to lie for them", value: "A" }, { text: "Politely refuse, explaining you're not comfortable being dishonest, but perhaps offer help in another way", value: "B" }, { text: "Report them immediately to HR", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "s30", sectionId: "scenarios", difficulty: "hard", question: "You are given responsibility for a project but lack the authority to make necessary decisions.",
//     options: [ { text: "Struggle and fail without raising the issue", value: "A" }, { text: "Discuss the authority gap with your manager, outlining the decisions needed and proposing a solution", value: "B" }, { text: "Overstep your authority anyway", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Digital - Easy
//   // ===================
//   {
//     id: "d1", sectionId: "digital", difficulty: "easy", question: "What makes a password strong?",
//     options: [ { text: "Using your pet's name", value: "A" }, { text: "A mix of uppercase letters, lowercase letters, numbers, and symbols", value: "B" }, { text: "'password'", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d4", sectionId: "digital", difficulty: "easy", question: "What does 'CC' usually mean when sending an email?",
//     options: [ { text: "Confidential Copy", value: "A" }, { text: "Carbon Copy (sends a visible copy to others)", value: "B" }, { text: "Cancel Copy", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d7", sectionId: "digital", difficulty: "easy", question: "How do you save a document you are working on?",
//     options: [ { text: "Close the program", value: "A" }, { text: "Use the 'Save' or 'Save As' command, usually found in the 'File' menu", value: "B" }, { text: "Unplug the computer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d8", sectionId: "digital", difficulty: "easy", question: "What is a web browser?",
//     options: [ { text: "A type of computer virus", value: "A" }, { text: "Software used to access and view websites (e.g., Chrome, Firefox, Safari)", value: "B" }, { text: "A physical part of the computer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d9", sectionId: "digital", difficulty: "easy", question: "What does the 'Reply All' button do in email?",
//     options: [ { text: "Replies only to the sender", value: "A" }, { text: "Replies to the sender and everyone else who was copied (CC'd or To'd)", value: "B" }, { text: "Deletes the email", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d10", sectionId: "digital", difficulty: "easy", question: "What is 'phishing'?",
//     options: [ { text: "A type of fishing sport", value: "A" }, { text: "Emails or messages trying to trick you into giving personal information", value: "B" }, { text: "A software update", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d20", sectionId: "digital", difficulty: "easy", question: "What does the 'BCC' field in an email do?",
//     options: [ { text: "Bold Carbon Copy", value: "A" }, { text: "Blind Carbon Copy (sends a copy secretly; other recipients don't see BCC'd addresses)", value: "B" }, { text: "Basic Carbon Copy", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d21", sectionId: "digital", difficulty: "easy", question: "What keyboard shortcut is commonly used for 'Copy'?",
//     options: [ { text: "Ctrl + V (or Cmd + V)", value: "A" }, { text: "Ctrl + C (or Cmd + C)", value: "B" }, { text: "Ctrl + X (or Cmd + X)", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d22", sectionId: "digital", difficulty: "easy", question: "What keyboard shortcut is commonly used for 'Paste'?",
//     options: [ { text: "Ctrl + V (or Cmd + V)", value: "A" }, { text: "Ctrl + C (or Cmd + C)", value: "B" }, { text: "Ctrl + P (or Cmd + P)", value: "C" } ],
//     correctAnswer: "A",
//   },
//   {
//     id: "d23", sectionId: "digital", difficulty: "easy", question: "What is a '.pdf' file?",
//     options: [ { text: "A music file", value: "A" }, { text: "A document file format that preserves formatting across different systems", value: "B" }, { text: "A video game file", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Digital - Medium
//   // ===================
//   {
//     id: "d2", sectionId: "digital", difficulty: "medium", question: "You receive an unexpected email asking for your bank details or password. What should you do?",
//     options: [ { text: "Reply with the requested information", value: "A" }, { text: "Be suspicious, do not click links or provide info, delete it, and report as phishing if possible", value: "B" }, { text: "Forward it to all your contacts", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d5", sectionId: "digital", difficulty: "medium", question: "Why is it important to log out of websites and applications on shared or public computers?",
//     options: [ { text: "It makes the computer faster", value: "A" }, { text: "To prevent others from accessing your accounts and personal information", value: "B" }, { text: "It's not important", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d11", sectionId: "digital", difficulty: "medium", question: "What is a common sign of a phishing email?",
//     options: [ { text: "Perfect grammar and spelling", value: "A" }, { text: "Urgent threats, requests for sensitive info, generic greetings, suspicious links/senders", value: "B" }, { text: "An offer for free kittens", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d12", sectionId: "digital", difficulty: "medium", question: "What is 'cloud storage' (e.g., Google Drive, Dropbox, OneDrive)?",
//     options: [ { text: "Storing files on a weather satellite", value: "A" }, { text: "Saving files online so they can be accessed from different devices", value: "B" }, { text: "A type of USB stick", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d13", sectionId: "digital", difficulty: "medium", question: "How can you verify if a website is secure for entering sensitive information (like passwords or credit cards)?",
//     options: [ { text: "If the website looks visually appealing", value: "A" }, { text: "Look for 'https' in the address bar and a padlock icon", value: "B" }, { text: "If it asks for lots of information", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d14", sectionId: "digital", difficulty: "medium", question: "What is the risk of using the same password for multiple websites?",
//     options: [ { text: "It's easier to remember", value: "A" }, { text: "If one site is breached, attackers can access your other accounts", value: "B" }, { text: "There is no risk", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d24", sectionId: "digital", difficulty: "medium", question: "What does clearing your browser's cache and cookies do?",
//     options: [ { text: "Speeds up your internet connection", value: "A" }, { text: "Removes temporary files and website data stored on your computer", value: "B" }, { text: "Installs antivirus software", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d25", sectionId: "digital", difficulty: "medium", question: "What is a potential danger of connecting to unsecured public Wi-Fi?",
//     options: [ { text: "Your battery drains faster", value: "A" }, { text: "Others on the network could potentially intercept your data", value: "B" }, { text: "It improves your phone signal", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d26", sectionId: "digital", difficulty: "medium", question: "What is 'cyberbullying'?",
//     options: [ { text: "A type of computer game", value: "A" }, { text: "Using electronic communication to bully a person, typically by sending intimidating or threatening messages", value: "B" }, { text: "A way to make friends online", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d27", sectionId: "digital", difficulty: "medium", question: "Why is it advisable to back up important digital files?",
//     options: [ { text: "It takes up too much space", value: "A" }, { text: "To prevent data loss in case of hardware failure, deletion, or malware attack", value: "B" }, { text: "Backups are unnecessary", value: "C" } ],
//     correctAnswer: "B",
//   },
//   // ===================
//   // Digital - Hard
//   // ===================
//   {
//     id: "d3", sectionId: "digital", difficulty: "hard", question: "What contributes to your 'digital footprint'?",
//     options: [ { text: "Only your email address", value: "A" }, { text: "Social media posts, Browse history, online purchases, comments, location data, etc.", value: "B" }, { text: "The brand of your computer", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d6", sectionId: "digital", difficulty: "hard", question: "What is two-factor authentication (2FA) or multi-factor authentication (MFA)?",
//     options: [ { text: "A password that is twice as long", value: "A" }, { text: "An extra security step requiring something you know (password) plus something you have (phone code) or are (fingerprint)", value: "B" }, { text: "A backup password", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d15", sectionId: "digital", difficulty: "hard", question: "What does 'malware' encompass?",
//     options: [ { text: "Only computer viruses", value: "A" }, { text: "Malicious software including viruses, ransomware, spyware, trojans etc.", value: "B" }, { text: "Poorly designed websites", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d16", sectionId: "digital", difficulty: "hard", question: "Why is it important to keep your operating system and software updated?",
//     options: [ { text: "Updates often slow down your computer", value: "A" }, { text: "Updates often include security patches that protect against known vulnerabilities", value: "B" }, { text: "Updates change the way software looks unnecessarily", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d17", sectionId: "digital", difficulty: "hard", question: "What is a VPN (Virtual Private Network) primarily used for?",
//     options: [ { text: "To speed up your internet connection", value: "A" }, { text: "To create a secure, encrypted connection over a public network, enhancing privacy", value: "B" }, { text: "To get free Wi-Fi", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d18", sectionId: "digital", difficulty: "hard", question: "How can you manage your online reputation for professional purposes?",
//     options: [ { text: "Delete all social media accounts", value: "A" }, { text: "Be mindful of what you post, manage privacy settings, and build a positive presence on professional networks like LinkedIn", value: "B" }, { text: "Use an anonymous username for everything", value: "C" } ],
//     correctAnswer: "B",
//   },
//    {
//     id: "d19", sectionId: "digital", difficulty: "hard", question: "What is the difference between HTTP and HTTPS?",
//     options: [ { text: "There is no difference", value: "A" }, { text: "HTTPS encrypts the data transmitted between your browser and the website, making it secure", value: "B" }, { text: "HTTP is faster than HTTPS", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d28", sectionId: "digital", difficulty: "hard", question: "What are browser 'cookies' used for?",
//     options: [ { text: "To clean your computer screen", value: "A" }, { text: "To store small pieces of data on your computer to remember preferences, login status, or track activity", value: "B" }, { text: "To speed up web Browse significantly", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d29", sectionId: "digital", difficulty: "hard", question: "What does 'Creative Commons' licensing refer to?",
//     options: [ { text: "A type of paid software license", value: "A" }, { text: "Licenses that allow creators to share their work with specific permissions for others to use, share, or build upon", value: "B" }, { text: "A social media platform", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d30", sectionId: "digital", difficulty: "hard", question: "What is 'ransomware'?",
//     options: [ { text: "Software offered for free", value: "A" }, { text: "Malware that encrypts your files and demands payment for their release", value: "B" }, { text: "Antivirus software", value: "C" } ],
//     correctAnswer: "B",
//   },
//   {
//     id: "d31", sectionId: "digital", difficulty: "hard", question: "How can using online collaboration tools (like Google Docs, Microsoft Teams) improve teamwork?",
//     options: [ { text: "By making communication more difficult", value: "A" }, { text: "By allowing real-time co-editing, easy file sharing, and centralized communication", value: "B" }, { text: "By automatically completing tasks", value: "C" } ],
//     correctAnswer: "B",
//   },
// ];

interface QuestionModalProps {
  onAnswer: (correct: boolean) => void;
  difficulty?: "easy" | "medium" | "hard";
  sectionId?: string;
}

function shuffleArray<T>(array: T[]): T[] {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
}


export function QuestionModal({ onAnswer, difficulty = "medium", sectionId }: QuestionModalProps) {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [showResult, setShowResult] = useState(false);
  const [controllerFocus, setControllerFocus] = useState(0);
  const [focusOnConfirm, setFocusOnConfirm] = useState(false);
  const [controllerConnected, setControllerConnected] = useState(false);
  const [dynamicQuestion, setDynamicQuestion] = useState<Question | null>(null);
  const [shuffledOptions, setShuffledOptions] = useState<QuestionOption[]>([]);
  const { incrementIncorrectAnswers } = useTrophies();
  const requestRef = useRef<number>();
  const optionRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const confirmRef = useRef<HTMLButtonElement | null>(null);
  const lastInputTime = useRef<number>(0);
  const inputDelay = 100;
  const successAudio = useRef(new Audio(successSound));
  const incorrectAudio = useRef(new Audio(incorrectSound));
  const lastQuestionIdRef = useRef<string | null>(null);

  const shakeKeyframes = `
    @keyframes shake {
      0% { transform: translateX(0); }
      20% { transform: translateX(-8px); }
      40% { transform: translateX(8px); }
      60% { transform: translateX(-5px); }
      80% { transform: translateX(5px); }
      100% { transform: translateX(0); }
    }
    .animate-shake {
      animation: shake 0.7s cubic-bezier(.36,.07,.19,.97) both;
    }
  `;

  const moveHorizontal = useCallback((direction: number) => {
    setControllerFocus((current) => {
      const total = shuffledOptions.length || 0;
      if (total === 0) return 0;
      const newFocus = (current + direction + total) % total;
      return newFocus;
    });
  }, [shuffledOptions.length]);

  const handleSubmit = useCallback(() => {
    if (!selectedAnswer || showResult || !dynamicQuestion) return;

    const isCorrect = selectedAnswer === dynamicQuestion.correctAnswer;
    setShowResult(true);

    if (isCorrect) {
      successAudio.current.play();
    } else {
      incorrectAudio.current.play();
      incrementIncorrectAnswers();
    }

    setTimeout(() => {
      setShowResult(false);
      setSelectedAnswer(null);
      // Don't reset the question here, let the useEffect handle it if props change
      onAnswer(isCorrect);
      // Trigger selection of a new question by changing a dependency (e.g., a counter) if needed,
      // but currently it relies on the parent component potentially changing sectionId/difficulty
      // or remounting the modal. If the modal stays mounted, we need a way to request a new question.
      // For now, assuming parent handles triggering a new question cycle.
    }, 3500);
  }, [selectedAnswer, showResult, dynamicQuestion, incrementIncorrectAnswers, onAnswer]);


  useEffect(() => {
    let questionToSet: Question | null = null;
    let baseFilteredQuestions: Question[] = [];

    if (sectionId) {
      baseFilteredQuestions = allQuestions.filter(
        (q) => q.sectionId === sectionId && q.difficulty === difficulty
      );
    } else {
      baseFilteredQuestions = allQuestions.filter(q => q.difficulty === difficulty);
    }

    if (baseFilteredQuestions.length === 0 && allQuestions.length > 0) {
        baseFilteredQuestions = allQuestions.filter(q => q.difficulty === difficulty);
         if (baseFilteredQuestions.length === 0) { // Still empty? Use any question
             baseFilteredQuestions = [...allQuestions];
             console.warn(`No questions found for difficulty: ${difficulty}. Using any question.`);
         } else {
             console.warn(`No questions found for sectionId: ${sectionId} with difficulty ${difficulty}. Using fallback difficulty questions.`);
         }
    }

    let availableQuestions = baseFilteredQuestions.filter(q => q.id !== lastQuestionIdRef.current);

    if (availableQuestions.length === 0 && baseFilteredQuestions.length > 0) {
        console.warn(`Only one question available or last shown was only option for ${sectionId || 'quick decision'}-${difficulty}. Allowing repeat.`);
        availableQuestions = baseFilteredQuestions;
    }


    if (availableQuestions.length > 0) {
        const randomIndex = Math.floor(Math.random() * availableQuestions.length);
        questionToSet = availableQuestions[randomIndex];
    } else if (allQuestions.length > 0) {
         // Absolute fallback if filtering somehow failed or allQuestions is empty initially
         const anyQuestionIndex = Math.floor(Math.random() * allQuestions.length);
         questionToSet = allQuestions[anyQuestionIndex];
         console.error("Absolute fallback: No questions available after filtering, selected random from all.");
    } else {
         console.error("No questions defined in allQuestions array!");
    }


    if (questionToSet) {
        setDynamicQuestion(questionToSet);
        setShuffledOptions(shuffleArray([...questionToSet.options]));
        lastQuestionIdRef.current = questionToSet.id;
        setSelectedAnswer(null);
        setShowResult(false);
        setControllerFocus(0);
        setFocusOnConfirm(false);
    }

  }, [sectionId, difficulty]);


  useEffect(() => {
    const handleGamepadConnected = (event: GamepadEvent) => {
      setControllerConnected(true);
    };
    const handleGamepadDisconnected = (event: GamepadEvent) => {
      const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
      let stillConnected = false;
      for (let i = 0; i < gamepads.length; i++) {
          if (gamepads[i]) {
              stillConnected = true;
              break;
          }
      }
      setControllerConnected(stillConnected);
    };

    window.addEventListener("gamepadconnected", handleGamepadConnected);
    window.addEventListener("gamepaddisconnected", handleGamepadDisconnected);

    const checkGamepads = () => {
      const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
      for (let i = 0; i < gamepads.length; i++) {
        if (gamepads[i]) {
          setControllerConnected(true);
          break;
        }
      }
    };
    checkGamepads();

    return () => {
      window.removeEventListener("gamepadconnected", handleGamepadConnected);
      window.removeEventListener("gamepaddisconnected", handleGamepadDisconnected);
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!controllerConnected || !dynamicQuestion) return; // Added dynamicQuestion check

    let prevDpadLeft = false;
    let prevDpadRight = false;
    let prevDpadUp = false;
    let prevDpadDown = false;
    let prevLeftStickLeft = false;
    let prevLeftStickRight = false;
    let prevLeftStickUp = false;
    let prevLeftStickDown = false;
    let prevRightStickLeft = false;
    let prevRightStickRight = false;
    let prevXButton = false;

    const pollGamepad = () => {
        const now = Date.now();
        const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
        let gamepad = null;
        for (let i = 0; i < gamepads.length; i++) {
            if (gamepads[i]) {
                gamepad = gamepads[i];
                break;
            }
        }

        if (gamepad) {
            const dpadLeft = gamepad.buttons[14]?.pressed;
            const dpadRight = gamepad.buttons[15]?.pressed;
            const dpadUp = gamepad.buttons[12]?.pressed;
            const dpadDown = gamepad.buttons[13]?.pressed;

            const leftStickLeft = gamepad.axes[0] < -0.5;
            const leftStickRight = gamepad.axes[0] > 0.5;
            const leftStickUp = gamepad.axes[1] < -0.5;
            const leftStickDown = gamepad.axes[1] > 0.5;

            const rightStickLeft = gamepad.axes[2] < -0.5;
            const rightStickRight = gamepad.axes[2] > 0.5;

            const xButton = gamepad.buttons[0]?.pressed;

            if (!showResult) {
                if (!focusOnConfirm) {
                    if (!prevDpadLeft && dpadLeft && now - lastInputTime.current > inputDelay) {
                        moveHorizontal(-1);
                        lastInputTime.current = now;
                    }
                    if (!prevDpadRight && dpadRight && now - lastInputTime.current > inputDelay) {
                        moveHorizontal(1);
                        lastInputTime.current = now;
                    }
                    if (!prevLeftStickLeft && leftStickLeft && now - lastInputTime.current > inputDelay) {
                         moveHorizontal(-1);
                         lastInputTime.current = now;
                    }
                    if (!prevLeftStickRight && leftStickRight && now - lastInputTime.current > inputDelay) {
                         moveHorizontal(1);
                         lastInputTime.current = now;
                    }
                    if (!prevRightStickLeft && rightStickLeft && now - lastInputTime.current > inputDelay) {
                        moveHorizontal(-1);
                        lastInputTime.current = now;
                    }
                    if (!prevRightStickRight && rightStickRight && now - lastInputTime.current > inputDelay) {
                        moveHorizontal(1);
                        lastInputTime.current = now;
                    }

                    if (((!prevDpadDown && dpadDown) || (!prevLeftStickDown && leftStickDown)) && selectedAnswer) {
                        setFocusOnConfirm(true);
                        lastInputTime.current = now;
                    }

                } else {
                     if (((!prevDpadUp && dpadUp) || (!prevLeftStickUp && leftStickUp))) {
                        setFocusOnConfirm(false);
                        lastInputTime.current = now;
                    }
                }

                 if (!prevXButton && xButton) {
                    if (focusOnConfirm) {
                        if (selectedAnswer) {
                            handleSubmit();
                        }
                    } else {
                        const currentOptionValue = shuffledOptions[controllerFocus]?.value;
                        if (currentOptionValue) {
                            setSelectedAnswer(currentOptionValue);
                        }
                    }
                     lastInputTime.current = now;
                }
            }

            prevDpadLeft = dpadLeft;
            prevDpadRight = dpadRight;
            prevDpadUp = dpadUp;
            prevDpadDown = dpadDown;
            prevLeftStickLeft = leftStickLeft;
            prevLeftStickRight = leftStickRight;
            prevLeftStickUp = leftStickUp;
            prevLeftStickDown = leftStickDown;
            prevRightStickLeft = rightStickLeft;
            prevRightStickRight = rightStickRight;
            prevXButton = xButton;
        }

        requestRef.current = requestAnimationFrame(pollGamepad);
    };

    requestRef.current = requestAnimationFrame(pollGamepad);

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [controllerConnected, showResult, selectedAnswer, controllerFocus, focusOnConfirm, dynamicQuestion, shuffledOptions, moveHorizontal, handleSubmit]); // Added shuffledOptions dependency

  useEffect(() => {
    if (controllerConnected) {
      if (focusOnConfirm) {
        confirmRef.current?.focus();
      } else if (optionRefs.current[controllerFocus]) {
        optionRefs.current[controllerFocus]?.focus();
      }
    }
  }, [controllerFocus, focusOnConfirm, controllerConnected]);

   useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && selectedAnswer && !showResult) {
        handleSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedAnswer, showResult, handleSubmit]);


  if (!dynamicQuestion) return null;

  const capitalizedSectionId = sectionId ? sectionId.toUpperCase() : "QUICK DECISION";

  return (
    <motion.div
      className="fixed inset-0 z-[300] flex items-end justify-center pointer-events-none"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-gray-900/90 backdrop-blur-xl p-6 w-full shadow-xl relative overflow-hidden pointer-events-auto"
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        transition={{ type: "spring", stiffness: 400, damping: 40 }}
      >
        <style dangerouslySetInnerHTML={{ __html: shakeKeyframes }} />
        <div className="max-w-3xl mx-auto relative z-10">
          <div className="mb-5 text-center">
            <h3 className="text-yellow-400 font-extrabold text-3xl tracking-wide uppercase mb-3">
              {capitalizedSectionId}
            </h3>
            <p className="text-base font-bold text-white tracking-wide max-w-xl mx-auto">
              {dynamicQuestion.question}
            </p>
          </div>

          <div className="flex flex-col gap-5 items-center">
            <div className="flex justify-center w-full gap-4 px-4 sm:px-0 mx-auto">
              {shuffledOptions.map((option, index) => (
                <motion.button
                  key={option.value} // Key is the unique value A, B, or C
                  ref={(el) => (optionRefs.current[index] = el)} // Ref is based on shuffled index
                  className={`py-4 px-3 rounded-xl text-center relative overflow-hidden transition-all duration-200 border-2 group flex-1 sm:flex-none sm:w-52 md:w-64 lg:w-72 focus:outline-none ${
                    selectedAnswer === option.value // Check based on value
                      ? "bg-yellow-500/20 text-yellow-300 border-yellow-500/80 shadow-lg shadow-yellow-500/30"
                      : controllerFocus === index && controllerConnected && !focusOnConfirm // Focus based on index
                      ? "bg-gray-800/70 border-yellow-300/70 shadow-md shadow-yellow-300/20"
                      : "bg-gray-800/70 hover:bg-gray-700/60 border-gray-700/70 hover:border-yellow-500/50 hover:shadow-md"
                  } ${
                    showResult
                      ? option.value === dynamicQuestion.correctAnswer // Check correctness based on value
                        ? "bg-green-500/40 text-green-300 border-green-500/80 shadow-lg shadow-green-500/30"
                        : selectedAnswer === option.value // Check if this incorrect option was selected
                        ? "bg-red-500/40 text-red-300 border-red-500/80 shadow-lg shadow-red-500/30 animate-shake"
                        : "opacity-60 border-gray-700/70"
                      : ""
                  }`}
                  onClick={() => {
                    if (!showResult) {
                        setSelectedAnswer(option.value); // Select based on value
                         if (focusOnConfirm) {
                            setFocusOnConfirm(false);
                         }
                    }
                  }}
                  whileHover={!showResult ? { scale: 1.03 } : undefined}
                  whileTap={!showResult ? { scale: 0.98 } : undefined}
                  disabled={showResult}
                >
                  <div className="flex flex-col items-center gap-2 w-full">
                    <div
                      className={`w-14 h-14 rounded-full flex items-center justify-center text-2xl font-bold mb-1 transition-all ${
                        selectedAnswer === option.value // Style based on value
                          ? "bg-yellow-500/30 text-yellow-100"
                          : controllerFocus === index && controllerConnected && !focusOnConfirm // Style based on index
                          ? "bg-yellow-400/20 text-yellow-300"
                          : "bg-gray-700/90 text-yellow-400"
                      } ${
                        showResult
                          ? option.value === dynamicQuestion.correctAnswer // Style based on value
                            ? "bg-green-500/60 text-white"
                            : selectedAnswer === option.value // Style based on value
                                ? "bg-red-500/60 text-white"
                                : "bg-gray-700/90 text-yellow-400"
                          : ""
                      }`}
                    >
                      {option.value} {/* Display the value (A, B, C) */}
                    </div>
                    <span className="text-sm font-extrabold tracking-wide text-yellow-300">
                      {option.text} {/* Display the text */}
                    </span>
                  </div>
                </motion.button>
              ))}
            </div>
            <div className="w-full sm:w-1/2 md:w-2/5 lg:w-1/3 relative">
                <motion.button
                    ref={confirmRef}
                    className={`w-full px-6 py-4 rounded-xl text-sm font-extrabold uppercase tracking-wider transition-all duration-200 focus:outline-none ${
                        !selectedAnswer || showResult
                        ? "bg-gray-700/60 text-gray-300 cursor-not-allowed"
                        : "bg-blue-600 text-white hover:bg-blue-500"
                    }`}
                    onClick={handleSubmit}
                    disabled={!selectedAnswer || showResult}
                    whileHover={selectedAnswer && !showResult ? { scale: 1.05 } : undefined}
                    whileTap={selectedAnswer && !showResult ? { scale: 0.95 } : undefined}
                >
                    CONFIRM
                </motion.button>
                 {focusOnConfirm && controllerConnected && !showResult && selectedAnswer && (
                    <div className="absolute inset-[-2px] pointer-events-none rounded-xl border-2 border-yellow-300 animate-pulse"></div>
                )}
            </div>
            {controllerConnected && (
              <div className="absolute bottom-3 right-4 flex items-center gap-2 text-xs text-gray-400">
                <Gamepad className="w-4 h-4" />
                <span>Controller Connected</span>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}