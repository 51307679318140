import { lazy, Suspense, useEffect, useState, useCallback } from "react";
import { MainLayout } from "@/components/layout/main-layout";
import { ActivitiesPanel } from "@/components/activities/activities-panel";
import { ChatPanel } from "@/components/chat/chat-panel";
import { ResultsPanel } from "@/components/results/results-panel";
import { LoginPage } from "@/pages/auth/login";
import { NameCollection } from "@/components/auth/name-collection";
import { MaintenanceOverlay } from "@/components/maintenance/maintenance-overlay";
import { useMobileTabs } from "@/hooks/use-mobile-tabs";
import { useAuthWithOnboarding, useAuth } from "@/hooks/use-auth";
import { NetworkStatus } from "@/components/network-status"; 
import { Toaster } from "@/components/ui/toaster";
import { useChatStore } from "@/store/chat-store";
import { useMaintenanceStore } from "@/store/maintenance-store";
import { useDisableContextMenu } from '@/lib/hooks/useDisableContextMenu';
import { createClient } from '@supabase/supabase-js';
import { IntroductionPage } from "@/components/introduction/introduction-page";
import { CourseMenu } from "@/components/course/course-menu";
import { Option2Menu } from "@/components/course/option2-menu";
import { Option3Menu } from "@/components/course/option3-menu";
import type { FC } from 'react';
import { Option4Menu as Option4MenuComponent } from "@/components/course/option4-menu";

const Option4Menu: FC = () => {
  const MenuComponent = Option4MenuComponent as FC;
  return <MenuComponent />;
};
// import { questionCache } from './services/servicesQuestionCacheService';

const JobExploration = lazy(() => import("@/components/job-exploration/job-exploration"));

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

const TamsLogo = () => (
  <div className="fixed inset-0 bg-gray-900 flex items-center justify-center z-[9999]">
    <div className="text-center">
      <h1 className="text-6xl sm:text-8xl font-bold mb-4 bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
        TAMS 2025
      </h1>
    </div>
  </div>
);

export default function App() {
  const [isInitializing, setIsInitializing] = useState(true);
  const [initError, setInitError] = useState<Error | null>(null);
  const [showTamsLogo, setShowTamsLogo] = useState(false);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const { activeTab } = useMobileTabs();
  const auth = useAuthWithOnboarding();
  const { isAuthenticated, codeId, logout } = useAuth();
  
  const { 
    showJobExploration, 
    setShowJobExploration, 
    showProjectCreator, 
    showIntroduction,
    showOption2Menu,
    showOption3Menu,
    showOption4Menu,
    showCourseMenu
  } = useChatStore();
  const { checkMaintenanceStatus } = useMaintenanceStore();
  
  const [supabaseStatus, setSupabaseStatus] = useState<'checking' | 'connected' | 'error'>('checking');
  const [cacheInitialized, setCacheInitialized] = useState(false);
  
  useEffect(() => {
    if (!isAuthenticated || !codeId) {
      return;
    }

    const verifySession = async () => {
      try {
        const { data, error } = await supabase
          .from('login_codes')
          .select('id')
          .eq('id', codeId)
          .single();

        if (error || !data) {
          console.warn('Session verification failed, logging out.');
          logout();
        }
      } catch (error) {
        console.error('Session verification error:', error);
      }
    };

    verifySession();
  }, [isAuthenticated, codeId, logout]);
  
  const checkDatabaseConnection = useCallback(async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const { error } = await supabase
        .from('system_settings')
        .select('id')
        .single();
      
      if (error) {
        if (error.message?.includes('Failed to fetch')) {
          console.warn('[App] Please connect to Supabase to continue');
        } else {
          console.error('[App] Database connection error:', error);
        }
        setSupabaseStatus('error');
        return false;
      }
      
      setSupabaseStatus('connected');
      return true;
    } catch (err) {
      console.error('[App] Database connection error:', err);
      setSupabaseStatus('error');
      return false;
    }
  }, []);

  // const initializeQuestionCache = useCallback(async () => {
  //   try {
  //     await questionCache.initializeCache();
  //     setCacheInitialized(true);
  //     console.log('[App] Question cache initialized');
  //   } catch (err) {
  //     console.error('[App] Question cache initialization error:', err);
  //   }
  // }, []);
  
  const updateContent = () => {
    if (!auth.isAuthenticated) {
      setContent(
        <>
          <LoginPage />
        </>
      );
      return;
    }

    if (auth.needsOnboarding) {
      setContent(
        <>
          <NameCollection />
        </>
      );
      return;
    }

    setContent(
      <>
        <MaintenanceOverlay />
        <NetworkStatus />
        <MainLayout>
          <div className="flex-1 flex min-h-0 relative">
            <ActivitiesPanel className="hidden md:flex w-80 min-h-0" />
            
            <div className="flex-1 md:hidden min-h-0">
              {activeTab === 'sources' && <ActivitiesPanel className="w-full min-h-0" />}
              {activeTab === 'chat' && <ChatPanel className="w-full min-h-0" />}
              {activeTab === 'tasks' && <ResultsPanel className="w-full min-h-0" />}
            </div>
            
            <div className="hidden md:block flex-1 min-h-0">
              <ChatPanel className="w-full min-h-0" />
            </div>
            
            <div className="hidden md:flex w-80 min-h-0">
              <ResultsPanel />
            </div>
          </div>

          <Suspense fallback={null}>
            {showJobExploration && (
              <JobExploration onClose={() => setShowJobExploration(false)} />
            )}
          </Suspense>

          <Suspense fallback={null}>
            {showIntroduction && (
              <IntroductionPage />
            )}
          </Suspense>

          <CourseMenu />
          <Option2Menu />
          <Option3Menu />
          {showOption4Menu && <Option4Menu />}
        </MainLayout>
      </>
    );
  };

  useDisableContextMenu();

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const isConnected = await checkDatabaseConnection();
        if (!isConnected) {
          return;
        }
        
        const maintenanceMode = await checkMaintenanceStatus();
        setShowTamsLogo(maintenanceMode);
        
        // initializeQuestionCache();
        
        setInitError(null);
        updateContent();
      } catch (err) {
        setInitError(err instanceof Error ? err : new Error('Failed to initialize app'));
        console.error('[App] Initialization error:', err);
        setSupabaseStatus('error');
      } finally {
        setIsInitializing(false);
      }
    };
    
    initializeApp();
  }, [checkMaintenanceStatus, checkDatabaseConnection]);

  useEffect(() => {
    if (!isInitializing) updateContent();
  }, [auth.isAuthenticated, auth.needsOnboarding, activeTab, showJobExploration, showProjectCreator, showIntroduction, showOption2Menu, showOption3Menu, showOption4Menu, showCourseMenu, isInitializing]);

  useEffect(() => {
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'style';
        link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap';
        document.head.appendChild(link);
      });
    }
  }, []);

  useEffect(() => {
    if (supabaseStatus === 'connected' && !cacheInitialized) {
      const sections = ['Readiness', 'Workplace', 'Scenarios', 'Digital'];
      const difficulties = ['easy', 'medium', 'hard'];
      
      // for (const section of sections) {
      //   for (const difficulty of difficulties) {
      //     questionCache.refreshCache(section, difficulty as 'easy' | 'medium' | 'hard');
      //   }
      // }
    }
  }, [supabaseStatus, cacheInitialized]);

  if (isInitializing || supabaseStatus === 'checking') {
    return (
      <div className="fixed inset-0 bg-gray-900 flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }
  
  if (initError || supabaseStatus === 'error') {
    return (
      <>
        <div className="fixed inset-0 bg-gray-900 flex items-center justify-center p-4">
          <div className="bg-gray-800 rounded-xl p-6 max-w-md w-full text-center space-y-4">
            <h2 className="text-xl font-medium mb-2">Unable to Load App</h2>
            <p className="text-gray-400 mb-4">
              {supabaseStatus === 'error' 
                ? 'Please click the "Connect to Supabase" button in the top right to continue.'
                : 'Please check your connection and try again.'
              }
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => window.location.reload()}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
              >
                Retry Connection
              </button>
            </div>
          </div>
        </div>
        <Toaster />
      </>
    );
  }

  if (showTamsLogo && supabaseStatus === 'connected') {
    return <TamsLogo />;
  }

  return (
    <>
      {content}
      <Toaster />
    </>
  );
}