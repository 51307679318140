import { useEffect, useState, useCallback, useRef } from "react";
import { QuestionModal } from "../question-modal";
import { memoryQuestions, MemoryQuestion } from "@/data/memory-questions";
import { useTrophies } from "@/hooks/use-trophies";
import { useGameState } from "@/hooks/use-game-state";

interface IFrameProps {
  onClose: () => void;
  title?: string;
  difficulty?: "easy" | "medium" | "hard";
  sectionId?: string;
}

export default function TamsMCFrame({
  onClose,
  title = "TamsMC",
  difficulty: difficultyProp = "easy",
  sectionId,
}: IFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [showQuestion, setShowQuestion] = useState(false);
  const [question, setQuestion] = useState<MemoryQuestion | null>(null);
  const { awardTrophy } = useTrophies();
  const { setGameState, setWinner } = useGameState();

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data?.type === "triggerQuestion") {
        setQuestion(getRandomQuestion());
        setShowQuestion(true);
        if (iframeRef.current?.contentWindow) {
          iframeRef.current.contentWindow.postMessage({ type: "disableControls" }, "*");
        }
      }
    }
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleQuestionAnswer = useCallback(
    async (correct: boolean) => {
      if (correct) {
        const trophyType = {
          easy: "bronze",
          medium: "silver",
          hard: "gold"
        }[difficultyProp] as "bronze" | "silver" | "gold";
        await awardTrophy(trophyType);
        setGameState("won");
        setWinner("You");
      } else {
        setGameState("won");
        setWinner("Computer");
      }
      setShowQuestion(false);
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage({ type: "enableControls" }, "*");
        iframeRef.current.contentWindow.postMessage({ type: "resetCountdown" }, "*");
      }
    },
    [difficultyProp, awardTrophy, setGameState, setWinner]
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999
      }}
    >
      <iframe
        ref={iframeRef}
        src="https://tamsmc.vercel.app/"
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; pointer-lock"
        sandbox="allow-scripts allow-same-origin allow-storage-access-by-user-activation allow-forms allow-pointer-lock"
      />
      {showQuestion && question && (
        <QuestionModal
          onAnswer={handleQuestionAnswer}
          difficulty={difficultyProp}
          sectionId={sectionId}
        />
      )}
    </div>
  );
}

function getRandomQuestion(): MemoryQuestion {
  return memoryQuestions[Math.floor(Math.random() * memoryQuestions.length)];
}
