import { type LucideIcon, GraduationCap, Book, Presentation, Users, Video, FileText, Briefcase, Target } from 'lucide-react';

export interface CourseSection {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  modules: CourseModule[];
}

export interface CourseModule {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  lessons: CourseLesson[];
}

export interface CourseLesson {
  id: string;
  title: string;
  description: string;
  duration: string;
  type: 'video' | 'interactive' | 'reading' | 'quiz' | 'link' | 'Content';
  content?: string;
  url?: string;
  media?: {
    type: 'video' | 'image';
    url: string;
    caption?: string;
  }[];
}

// This is the flattened structure that skips the module layer
export const option2Content: CourseSection[] = [
  {
    id: 'day-1',
    title: 'Day 1',
    description: 'Complete tasks.',
    icon: GraduationCap,
    color: 'bg-indigo-500',
    modules: [
      {
        id: 'time-management-module',
        title: 'Tasks',
        description: 'Daily tasks to complete',
        icon: Book,
        color: 'bg-indigo-400',
        lessons: [
          {
            id: 'time-management',
            title: 'Time Management',
            description: 'Learn to manage your study time effectively',
            duration: '20 min',
            type: 'video',
            content: `
              <h1>Effective Time Management</h1>
              
              <p class="lead">
                Master the art of managing your time efficiently for better academic performance.
              </p>
              
              [video:https://www.youtube.com/embed/example2]
              
              <h2>Key Time Management Strategies</h2>
              
              <div class="grid gap-6 my-8">
                <div class="bg-white/5 p-6 rounded-xl">
                  <h3 class="text-xl mb-4">The Pomodoro Technique</h3>
                  <p>Break your study sessions into focused 25-minute intervals.</p>
                </div>
                
                <div class="bg-white/5 p-6 rounded-xl">
                  <h3 class="text-xl mb-4">Priority Matrix</h3>
                  <p>Organize tasks based on urgency and importance.</p>
                </div>
              </div>
              
              [image:https://images.unsplash.com/photo-1434030216411-0b793f4b4173?w=1200]
              
              <h3>Daily Planning Tips</h3>
              <ul>
                <li>Create a structured schedule</li>
                <li>Set realistic goals</li>
                <li>Include breaks and rest periods</li>
                <li>Review and adjust regularly</li>
              </ul>
              
              <div class="bg-purple-500/10 p-6 rounded-xl my-8">
                <h4 class="text-purple-400 mb-4">Practice Exercise</h4>
                <p>Create your own study schedule using the techniques learned.</p>
              </div>
            `
          }
        ]
      }
    ]
  }
];