import { DivideIcon as LucideIcon } from "lucide-react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

interface ActivityCardProps {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  glowColor: string;
  disabled?: boolean;
  type?: 'game' | 'interview';
  onClick?: () => void;
}

export function ActivityCard({ 
  title, 
  description, 
  icon: Icon, 
  color, 
  disabled = false,
  onClick
}: ActivityCardProps) {
  return (
    <motion.div 
      className={cn("flex-1", disabled && "opacity-40")}
      whileHover={disabled ? undefined : { scale: 1.02 }}
      whileTap={disabled ? undefined : { scale: 0.98 }}
    >
      <div 
        className={cn(
          "flex flex-col items-start p-5 rounded-xl bg-gray-800 text-left w-full h-[140px]",
          !disabled && "hover:bg-gray-700 transition-colors cursor-pointer",
          disabled && "cursor-not-allowed"
        )}
        onClick={!disabled ? onClick : undefined}
      >
        <div className={cn("p-2 rounded-xl mb-3", color)}>
          <Icon size={20} className="text-white" />
        </div>
        <div className="flex-1 flex flex-col gap-1.5">
          <h4 className={cn(
            "font-medium line-clamp-1",
            title.length > 10 ? "text-sm" : "text-base"
          )}>{title}</h4>
          <p className="text-xs text-gray-400 line-clamp-2">{description}</p>
        </div>
      </div>
    </motion.div>
  );
}