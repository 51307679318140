import React from 'react';
import { motion } from 'framer-motion';

interface MobileKeypadProps {
  onKeyPress: (key: string) => void;
  onBackspace: () => void;
  disabled?: boolean;
}

const keys = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  ['', '0', '⌫']
];

export function MobileKeypad({ onKeyPress, onBackspace, disabled = false }: MobileKeypadProps) {
  return (
    <div className="w-full max-w-xs mx-auto mt-8">
      <div className="grid grid-cols-3 gap-3">
        {keys.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {row.map((key, colIndex) => (
              <motion.button
                key={`${rowIndex}-${colIndex}`}
                className={`
                  h-16 rounded-2xl text-2xl font-medium backdrop-blur-sm
                  ${key === '⌫' ? 'bg-red-500/20 text-red-500' : key ? 'bg-white/10' : 'bg-transparent cursor-default'}
                  ${key && !disabled ? 'hover:bg-white/20 active:bg-white/30' : ''}
                  ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                  transition-colors
                `}
                whileTap={key && !disabled ? { scale: 0.95 } : undefined}
                onClick={() => {
                  if (disabled) return;
                  if (key === '⌫') {
                    onBackspace();
                  } else if (key) {
                    onKeyPress(key);
                  }
                }}
                disabled={disabled}
              >
                {key}
              </motion.button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}