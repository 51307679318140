import { useEffect, useState, useCallback } from "react";
import { QuestionModal } from "../question-modal";
import { memoryQuestions, MemoryQuestion } from "@/data/memory-questions";
import { useTrophies } from "@/hooks/use-trophies";
import { useGameState } from "@/hooks/use-game-state";

interface StickHeroFrameProps {
  onClose: () => void;
  title?: string;
  difficulty?: "easy" | "medium" | "hard";
  onRestart?: () => void;
  sectionId?: string;
}

export default function StickHeroFrame({
  onClose,
  title = "Stick Hero",
  difficulty: difficultyProp = "easy",
  onRestart,
  sectionId
}: StickHeroFrameProps) {
  const { awardTrophy } = useTrophies();
  const { setGameState, setWinner } = useGameState();
  const [showQuestion, setShowQuestion] = useState(false);
  const [question, setQuestion] = useState<MemoryQuestion | null>(null);
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">(difficultyProp);
  useEffect(() => {
    setDifficulty(difficultyProp);
  }, [difficultyProp]);
  useEffect(() => {
    const iframe = document.querySelector("iframe");
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ type: "disableRestart", disable: showQuestion }, "*");
    }
  }, [showQuestion]);
  useEffect(() => {
    function checkStorage() {
      const gameData = localStorage.getItem("stickHeroResult");
      if (gameData) {
        const { result, score } = JSON.parse(gameData);
        if (result === "lost") {
          setQuestion(getRandomQuestion());
          setShowQuestion(true);
        } else {
          setGameState("won");
          setWinner("You");
        }
        localStorage.removeItem("stickHeroResult");
      }
    }
    const interval = setInterval(checkStorage, 1000);
    function handleMessage(event: MessageEvent) {
      if (event.data?.type === "closeGame") {
        onClose();
      }
    }
    window.addEventListener("message", handleMessage);
    return () => {
      clearInterval(interval);
      window.removeEventListener("message", handleMessage);
    };
  }, [setGameState, setWinner, onClose, sectionId]);
  const handleQuestionAnswer = useCallback(async (correct: boolean) => {
    if (correct) {
      const trophyType = { easy: "bronze", medium: "silver", hard: "gold" }[difficulty] as "bronze" | "silver" | "gold";
      await awardTrophy(trophyType);
      setGameState("won");
      setWinner("You");
    } else {
      setGameState("won");
      setWinner("Computer");
    }
    setShowQuestion(false);
  }, [difficulty, awardTrophy, setGameState, setWinner, sectionId]);
  return (
    <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", zIndex: 9999 }}>
      <iframe
        src={`/SH/index.html?difficulty=${difficulty}&section=${sectionId || ""}`}
        width="100%"
        height="100%"
        style={{ border: "none", pointerEvents: showQuestion ? "none" : "auto" }}
        title={title}
        sandbox="allow-scripts allow-same-origin"
      />
      {showQuestion && question && (
        <QuestionModal
          question={question}
          onAnswer={handleQuestionAnswer}
          title={title}
          difficulty={difficulty}
          sectionId={sectionId}
        />
      )}
    </div>
  );
}

function getRandomQuestion(): MemoryQuestion {
  return memoryQuestions[Math.floor(Math.random() * memoryQuestions.length)];
}
