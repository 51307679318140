import { motion, AnimatePresence } from 'framer-motion';
import { PanelHeader } from "@/components/panel-header";
import { ExternalLink, Loader2, AlertCircle, CheckCircle, Circle } from "lucide-react";
import { cn } from "@/lib/utils";
import { useChatStore } from "@/store/chat-store";
import { useState, useEffect } from 'react';

const STORAGE_KEY = 'task_completion_status';

interface Task {
  id: string;
  title: string;
  description: string;
  jobLink: string | boolean;
}

const mockSearchResults: Task[] = [
  {
    id: "119",
    title: "Digital Activities",
    description: "Digital Activities",
    jobLink: "https://digital-activities.vercel.app"
  },
  {
    id: "126",
    title: "Generate Images",
    description: "Generate some images!",
    jobLink: "https://digital-activities.vercel.app/custom"
  },
  {
    id: "120",
    title: "Changes to Platform ",
    description: "I'll briefly show the changes to the platform.",
   jobLink: false,
  },
  {
    id: "121",
    title: "Interview Activity",
    description: "Try the improved text interview activity.",
   jobLink: false,
  },
  {
    id: "122",
    title: "Improved Games",
    description: "Try the improved games and interview questions.",
   jobLink: false,
  },
  {
    id: "123",
    title: "Intro to Google Lens",
    description: "A tool to help with identifiying information.",
   jobLink: false,
  },
  {
    id: "124",
    title: "WEEK ONE FEEDBACK",
    description: "If you haven't already, please provide feedback via this link. It is anonymous.",
    jobLink: "https://forms.gle/8FdpdfJ8D2a8eheCA"
  },
  {
    id: "125",
    title: "WEEK FIVE FEEDBACK",
    description: "If you haven't already, please provide feedback via this link. It is anonymous.",
    jobLink: "https://forms.gle/djaCnVntvPijUsvJ8"
  },
 
  // {
  
  //   id: "344",
  //   title: "WEEK TWO FEEDBACK",
  //   description: "If you haven't already, please provide feedback via this link. It is anonymous.",
  //   jobLink: "https://forms.gle/NQB3EL5REcZmFXq98"
  // },






];

interface ResultsPanelProps {
  className?: string;
}

export function ResultsPanel({ className }: ResultsPanelProps) {
  const { hasResults, isSearching, searchError } = useChatStore();
  const [completedTasks, setCompletedTasks] = useState<Record<string, boolean>>({});
  const [isClient, setIsClient] = useState(false);
  
  const displayResults = mockSearchResults;
  const showResults = true;

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient) {
      try {
        const savedTasks = localStorage.getItem(STORAGE_KEY);
        if (savedTasks) {
          setCompletedTasks(JSON.parse(savedTasks));
        }
      } catch (error) {
        console.error('Error loading completed tasks from localStorage:', error);
      }
    }
  }, [isClient]);

  const toggleTaskCompletion = (e: React.MouseEvent, taskId: string) => {
    e.preventDefault();
    e.stopPropagation();
    
    const newCompletedTasks = {
      ...completedTasks,
      [taskId]: !completedTasks[taskId]
    };
    
    setCompletedTasks(newCompletedTasks);
    
    if (typeof window !== 'undefined') {
      try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(newCompletedTasks));
      } catch (error) {
        console.error('Error saving to localStorage:', error);
      }
    }
  };

  const completedTasksList = displayResults.filter(job => completedTasks[job.id]);
  const uncompletedTasksList = displayResults.filter(job => !completedTasks[job.id]);

  const renderTaskCard = (job: Task, index: number) => {
    const isCompleted = completedTasks[job.id] || false;
    const hasLink = job.jobLink && job.jobLink !== false;
    console.log(hasLink);
    const cardStyle = {
      width: '100%',
      maxWidth: 'none',
      display: 'flex',
      alignItems: 'flex-start'
    };
    
    const CardContent = () => (
      <div className="flex items-start justify-between w-full">
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between mb-0.5">
            <h3 className="font-medium text-sm text-white truncate">{job.title}</h3>
            {hasLink && (
              <ExternalLink size={14} className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity ml-2 flex-shrink-0" />
            )}
          </div>
          <p className="text-gray-400 text-xs line-clamp-2">{job.description}</p>
        </div>
        <button 
          onClick={(e) => toggleTaskCompletion(e, job.id)}
          className="ml-3 flex-shrink-0 focus:outline-none"
        >
          {isCompleted ? (
            <CheckCircle className="w-5 h-5 text-green-500" />
          ) : (
            <Circle className="w-5 h-5 text-gray-400 hover:text-white" />
          )}
        </button>
      </div>
    );

    return (
      <div className="w-full" style={{ width: '100%' }} key={job.id}>
        <motion.div 
          initial={{ opacity: 0, y: 20 }} 
          animate={{ opacity: 1, y: 0 }} 
          transition={{ delay: 0.1 + index * 0.05, duration: 0.3 }} 
          className="w-full"
          style={{ width: '100%' }}
        >
          {hasLink ? (
            <motion.a 
              href={typeof job.jobLink === 'string' ? job.jobLink : '#'}
              target="_blank" 
              rel="noopener noreferrer" 
              className={cn(
                "rounded-xl p-3 transition-all duration-200 group relative w-full flex shadow-md", 
                isCompleted 
                  ? "bg-gradient-to-br from-green-900/40 to-green-800/20 hover:from-green-900/50 hover:to-green-800/30" 
                  : "bg-gradient-to-br from-gray-800/60 to-gray-800/40 hover:from-gray-700/60 hover:to-gray-700/40"
              )}
              whileHover={{ scale: 1.02, y: -2 }} 
              whileTap={{ scale: 0.98 }}
              style={cardStyle}
            >
              <CardContent />
            </motion.a>
          ) : (
            <motion.div 
              className={cn(
                "rounded-xl p-3 transition-all duration-200 group relative cursor-default w-full flex shadow-md", 
                isCompleted 
                  ? "bg-gradient-to-br from-green-900/40 to-green-800/20" 
                  : "bg-gradient-to-br from-gray-800/60 to-gray-800/40"
              )}
              whileHover={{ scale: 1.02, y: -2 }} 
              whileTap={{ scale: 0.98 }}
              style={cardStyle}
            >
              <CardContent />
            </motion.div>
          )}
        </motion.div>
      </div>
    );
  };

  const containerStyle = { width: '100%', maxWidth: 'none' };

  return (
    <div 
      className={cn("flex flex-col h-full min-h-0 border-l border-gray-800 w-full", className)} 
      data-panel="results"
      style={containerStyle}
    >
      <PanelHeader title="MY TASKS" />
      <div 
        className="flex-1 overflow-y-auto scrollbar-activities w-full" 
        style={containerStyle}
      >
        <AnimatePresence mode="wait">
          {isSearching ? (
            <motion.div className="h-full flex flex-col items-center justify-center text-center py-12" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Loader2 className="w-8 h-8 animate-spin text-blue-500 mb-4" />
              <p className="text-gray-400">Searching for tasks...</p>
            </motion.div>
          ) : searchError ? (
            <motion.div className="h-full flex flex-col items-center justify-center text-center py-12" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <div className="w-16 h-16 rounded-full bg-red-500/20 flex items-center justify-center mb-4">
                <AlertCircle className="w-8 h-8 text-red-500" />
              </div>
              <p className="text-red-500 font-medium mb-2">Search Error</p>
              <p className="text-gray-400 max-w-sm">{searchError}</p>
            </motion.div>
          ) : hasResults || showResults ? (
            <div 
              className="p-3 w-full" 
              style={containerStyle}
            >
              <motion.div 
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }} 
                transition={{ duration: 0.3 }} 
                className="w-full"
                style={containerStyle}
              >
                {displayResults.length > 0 ? (
                  <div className="w-full" style={containerStyle}>
                    <div className="mb-4 w-full" style={{ width: '100%' }}>
                    <div className="my-5 border-t border-gray-700/30 relative w-full">
                      <div className="absolute inset-x-0 -top-2.5 flex items-center justify-center">
                        <div className="bg-gray-900 px-4 py-0.5 text-gray-500 text-xs rounded-full border border-gray-700/20">
                         <b> UNCOMPLETED</b>
                        </div>
                      </div>
                    </div>
                    
                      {uncompletedTasksList.length > 0 ? (
                        <div className="space-y-3 w-full" style={{ width: '100%' }}>
                          {uncompletedTasksList.map((job, index) => renderTaskCard(job, index))}
                        </div>
                      ) : (
                        <div className="text-gray-500 text-xs px-3 py-4 text-center bg-gray-800/30 rounded-xl w-full">
                        NO UNCOMPLETED TASKS
                      </div>
                      
                      )}
                    </div>
                    
                    <div className="my-5 border-t border-gray-700/30 relative w-full">
                      <div className="absolute inset-x-0 -top-2.5 flex items-center justify-center">
                        <div className="bg-gray-900 px-4 py-0.5 text-gray-500 text-xs rounded-full border border-gray-700/20">
                        <b> COMPLETED</b>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-4 w-full" style={{ width: '100%' }}>
                    
                      {completedTasksList.length > 0 ? (
                        <div className="space-y-3 w-full" style={{ width: '100%' }}>
                          {completedTasksList.map((job, index) => renderTaskCard(job, index))}
                        </div>
                      ) : (
                        <div className="text-gray-500 text-xs px-3 py-4 text-center bg-gray-800/30 rounded-xl w-full">
  NO COMPLETED TASKS
</div>

                      )}
                    </div>
                  </div>
                ) : (
                  <motion.div className="p-8 flex flex-col items-center text-center w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <h3 className="text-base font-medium text-gray-300 mb-1">No tasks</h3>
                    <p className="text-xs text-gray-500">Tasks will show up here</p>
                  </motion.div>
                )}
              </motion.div>
            </div>
          ) : (
            <motion.div className="p-8 flex flex-col items-center text-center w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <h3 className="text-base font-medium text-gray-300 mb-1">No tasks</h3>
              <p className="text-xs text-gray-500">Tasks will show up here</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}