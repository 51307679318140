import React from 'react';
import { X } from 'lucide-react';

type NewInterviewUIProps = {
  onClose: () => void;
  interviewId: string;
  type: 'background' | 'situational';
};

const NewInterviewUI: React.FC<NewInterviewUIProps> = ({ onClose, interviewId, type }) => {
  console.log('NewInterviewUI received type:', type);
  const path = type === 'background' ? 'timed' : 'pace';
  return (
    <div className="fixed inset-0 z-[9999] bg-black">
      <iframe
        src={`https://text-interviews-tams.vercel.app/${path}?id=${encodeURIComponent(interviewId)}`}
        title="Embedded External Site"
        className="w-full h-full border-0"
        allow="fullscreen"
      />
      <button
        onClick={onClose}
        className="absolute top-4 right-4 bg-white/10 hover:bg-white/20 text-white p-2 rounded-full z-[10000]">
        <X className="w-6 h-6" />
      </button>
    </div>
  );
};

export default NewInterviewUI;
