import { useState } from 'react';
import { Square } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';
import { useChatStore } from '@/store/chat-store';

interface ChatInputProps {
  onSend: (message: string) => void;
  className?: string;
}

export function ChatInput({ onSend, className }: ChatInputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [message, setMessage] = useState('');
  const { handleUserMessage } = useChatStore();

  const handleSubmit = async () => {
    if (message.trim()) {
      await handleUserMessage(message.trim());
      setMessage('');
    }
  };

  return (
    <div className={cn('px-4 py-3 border-t border-gray-800 bg-gray-900 w-full', className)}>
      <motion.div
        className="relative"
        animate={isFocused ? { scale: 1.02 } : { scale: 1 }}
        transition={{ type: 'spring', stiffness: 300, damping: 25 }}
      >
        <div className={cn(
          'flex gap-2 items-center bg-gray-800/80 backdrop-blur-sm rounded-2xl pl-4 pr-2 py-2 transition-all duration-200',
          isFocused && 'bg-gray-800 shadow-lg shadow-black/20'
        )}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
            placeholder="Type your message..."
            className="flex-1 bg-transparent outline-none text-sm placeholder:text-gray-500"
          />

          <motion.button 
            className="p-2 bg-blue-600 rounded-xl hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSubmit}
            disabled={!message.trim()}
          >
            <Square size={16} className="rotate-45" />
          </motion.button>
        </div>

        <AnimatePresence>
          {isFocused && (
            <motion.div 
              className="absolute inset-0 -z-10 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-pink-500/10 rounded-2xl blur-xl"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}