import { type LucideIcon, Briefcase, Target, Users, FileText, Video, Star, Trophy } from 'lucide-react';

export interface CareerSection {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  modules: CareerModule[];
}

export interface CareerModule {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  resources: CareerResource[];
}

export interface CareerResource {
  id: string;
  title: string;
  description: string;
  type: 'video' | 'document' | 'interactive' | 'assessment';
  duration?: string;
  content?: string;
}

export const option3Content: CareerSection[] = [
  {
    id: 'career-planning',
    title: 'Career Planning',
    description: 'Plan your career path',
    icon: Briefcase,
    color: 'bg-emerald-500',
    modules: [
      {
        id: 'goal-setting',
        title: 'Goal Setting',
        description: 'Define your career goals',
        icon: Target,
        color: 'bg-emerald-400',
        resources: [
          {
            id: 'career-assessment',
            title: 'Career Assessment',
            description: 'Discover your career interests and strengths',
            type: 'assessment',
            duration: '30 min',
            content: `
              <h1>Career Assessment Guide</h1>

              <p class="lead">
                Discover your professional strengths and ideal career path through our comprehensive assessment.
              </p>

              [video:https://www.youtube.com/embed/example3]

              <h2>Assessment Overview</h2>

              <div class="grid gap-6 my-8">
                <div class="bg-white/5 p-6 rounded-xl">
                  <h3 class="text-xl mb-4">Skills Analysis</h3>
                  <p>Evaluate your current skills and identify areas for growth.</p>
                </div>
                
                <div class="bg-white/5 p-6 rounded-xl">
                  <h3 class="text-xl mb-4">Interest Mapping</h3>
                  <p>Explore career fields that align with your interests.</p>
                </div>
              </div>

              [image:https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?w=1200]

              <h3>Assessment Steps</h3>
              <ol>
                <li>Complete the skills inventory</li>
                <li>Take the interest assessment</li>
                <li>Review your personality profile</li>
                <li>Analyze career matches</li>
              </ol>

              <div class="bg-emerald-500/10 p-6 rounded-xl my-8">
                <h4 class="text-emerald-400 mb-4">Action Items</h4>
                <p>Use your assessment results to create a personalized career development plan.</p>
              </div>
            `
          }
        ]
      }
    ]
  }
];