import { type LucideIcon, BookOpen, Code, Brain, Target, Star, Trophy, Rocket, Zap, Lightbulb, Bot, Wrench, Search, Gamepad2 } from 'lucide-react';
import chuckyVideo from "../../CHUCKY.mp4";
export interface CourseSection {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  modules: CourseModule[];
}

export interface CourseModule {
  id: string;
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  lessons: CourseLesson[];
}

export interface CourseLesson {
  id: string;
  title: string;
  description: string;
  duration: string;
  type: 'video' | 'interactive' | 'reading' | 'quiz' | 'link' | 'Content';
  content?: string;
  url?: string;
  media?: {
    type: 'video' | 'image';
    url: string;
    caption?: string;
  }[];
}

export interface Tool {
  id: string;
  name: string;
  description: string;
  url: string;
  category: string;
  tags: string[];
  isPaid?: boolean;
  pricing?: string;
}

// Tool categories for filtering
export const toolCategories = [
  'Productivity',
  'Development',
  'Design',
  'Communication',
  'Project Management',
  'Marketing',
  'Analytics',
  'Security',
  'Education',
  'Finance'
];

// Tool collection
export const tools: Tool[] = [
  {
    id: 'vscode',
    name: 'Visual Studio Code',
    description: 'Popular code editor with extensive plugin support',
    url: 'https://code.visualstudio.com',
    category: 'Development',
    tags: ['IDE', 'Code Editor', 'Programming'],
    isPaid: false
  },
  {
    id: 'figma',
    name: 'Figma',
    description: 'Collaborative interface design tool',
    url: 'https://www.figma.com',
    category: 'Design',
    tags: ['UI/UX', 'Design', 'Collaboration'],
    isPaid: true,
    pricing: 'Free tier available'
  }
];

export const courseContent: CourseSection[] = [
  {
    id: 'introduction',
    title: 'DAY 1',
    description: 'Introduction',
    icon: BookOpen,
    color: 'bg-blue-500',
    modules: [
      {
        id: 'getting-started',
        title: 'Getting Started',
        description: 'Learn the fundamentals',
        icon: Rocket,
        color: 'bg-blue-400',
        lessons: [
          {
            id: 'welcome',
            title: 'About Me',
            description: 'About Me',
            duration: '',
           type: 'Content',
            content: `
              <h1>About Me</h1>
              
              <p>
                Kia ora! I’m Hayden, a software developer who has loved tech, problem-solving, and creative projects for as long as I can remember.
              </p>
              
              <br>
        
              <h2>My Journey</h2>
              
              <p>
                Not long ago, I was doing the TAMS course — nervous, unsure, and not knowing what to expect. 
                It helped build my confidence and led to me getting employed. Now, I get to explore my passion for tech every day.
              </p>
              
              <br>
        
              <h2>What I Love</h2>
              
              <p>
                I enjoy experimenting with AI and digital tools, building projects that bring ideas to life, 
                and helping others feel comfortable with technology.
              </p>
              
              <br>
        
              <h2>My Current Project</h2>
              
 <p>
        One of my personal projects is using AI to make Chucky move and talk,
        with the goal of turning him into a full animatronic.
      </p>
     <div style="width: 60%; height: 0px; position: relative; padding-bottom: 56.25%;">
  <iframe src="https://streamable.com/e/qiq8sb?quality=highest" frameborder="0" width="100%" height="100%" allowfullscreen style="width: 100%; height: 100%; position: absolute;"></iframe>
</div>


              <br>
        
              <h2>My Goal for You</h2>
              
              <p>
                Whether you're really into tech, just exploring, or mainly here to get employed, my goal is to make sure 
                you're comfortable, having fun, and getting something valuable out of this experience—whatever that looks like for you.
              </p>
              
              <br>
            `
        },
        
        
          {
            id: 'welcome',
            title: 'About TAMS',
            description: 'ABOUT TAMS',
            duration: '',
            type: 'Content',
            content: `
       <h1>TAMS</h1>

<p>
  TAMS is a hands-on, interactive course designed to build your digital skills in a fun and engaging way. Over the next few weeks, you'll explore AI tools, solve challenges, and develop practical skills that can help in the workplace and beyond.
</p>
<br>
<h2>What to Expect</h2>
<p>
  This isn’t a typical classroom—you’ll be playing games, working on creative projects, and tackling real-world digital tasks. Everything is designed to be <strong>simple, useful, and relevant</strong>—no boring theory, just skills you can actually use.
</p>
<br>
<h3>How TAMS Helps You Get Employed</h3>
<p>
  - Learn essential digital skills that employers look for<br>
  - Practise writing professional emails and organising tasks<br>
  - Develop problem-solving and research skills for the workplace<br>
  - Gain confidence using AI tools in a work environment
</p>

<br>
<h3>One-on-One Support</h3>
<p>
  We take a personalised approach to learning. Once you're inside the platform, you’ll get plenty of support to help you through challenges. If something isn’t clicking, we’ll work through it together.
</p>
<br>

<p>
  Each day brings something new, so be ready to experiment, learn, and build skills that will help you land a job—all while having fun!
</p>

            `
          },
          {
            id: 'welcome',
            title: 'ESCAPE ROOM!',
            description: 'ESCAPE ROOM!',
            duration: '',
            type: 'Content',
            content: `
            <h1>ESCAPE ROOM ACTIVITY</h1>
            
            <p class="lead">
              <a href="https://tams-escape-room.vercel.app/" target="_blank" rel="noopener noreferrer">CLICK HERE</a>
            </p>
          `
          
          },
         
        ]
      },
      
    ]
  },
  {
    id: 'introduction',
    title: 'DAY 2',
    description: 'Continued Platform & Games',
    icon: BookOpen,
    color: 'bg-blue-500',
    modules: [
      {
        id: 'getting-started',
        title: 'Getting Started',
        description: 'Learn the fundamentals',
        icon: Rocket,
        color: 'bg-blue-400',
        lessons: [
          {
            id: 'welcome',
            title: 'Updates to Course Platform',
            description: 'Updates to Course Platform!',
            duration: '',
            type: 'Content',
            content: `
            <h1>Updates to Course & Platforms</h1>
            
            <p class="lead">
            - To help you understand interview questions better, you can now highlight the question, right click and click 'Explain'. This will take you to ChatGPT to convert it to something you already understand.
          <br><br>  - You can now highlight text on content pages like this one, right click, and click 'Simplify' to simplify the content.
          <br><br> - Daily riddles are now randomised
          <br><br> - Start of Digital Skills Games, with 2 more being added on Wednesday.
            </p>
          `
          
          },
          {
            id: 'welcome',
            title: '5 Warmup Riddles',
            description: 'Riddles',
            duration: '',
            type: 'Content',
            content: `
       <h1>Riddles!</h1>

<p>
  I will provide a QR Code to scan on your mobile device.
</p>


            `
          },
          {
            id: 'welcome',
            title: 'Daily Digital Skills',
            description: 'Using and learning various digital tools and activities',
            duration: '',
           type: 'Content',
            content: `
              <h1>Digital Skills!</h1>
              
              <p>
               Try out various digital skills games for the session today. 
              </p>
              
 <a href="https://digital-activities.vercel.app/" target="_blank" rel="noopener noreferrer">Click here to start</a>

            `
        },
        
        
    
  
         
        ]
      },
      
    ]
  },
  {
    id: 'introduction',
    title: 'DAY 3',
    description: 'Continued Platform & Games',
    icon: BookOpen,
    color: 'bg-blue-500',
    modules: [
      {
        id: 'getting-started',
        title: 'Getting Started',
        description: 'Learn the fundamentals',
        icon: Rocket,
        color: 'bg-blue-400',
        lessons: [
   
          {
            id: 'welcome',
            title: 'Warmup Riddles',
            description: 'Riddles',
            duration: '',
            type: 'Content',
            content: `
       <h1>Riddles!</h1>

<p>
  Day 3 of Week 1 for Morning Warmup Riddles.
</p>
<a href="https://clinquant-jelly-707275.netlify.app/" target="_blank" rel="noopener noreferrer">Click here to continue</a>



            `
          },
          {
            id: 'welcome',
            title: 'Daily Digital Skills',
            description: 'Daily Digital Skills, now with AI VS Real & AI option enabled for logo design.',
            duration: '',
           type: 'Content',
            content: `
              <h1>Digital Skills!</h1>
              
              <p>
              Daily Digital Skills, now with AI VS Real & AI option enabled for logo design.
              </p>
              
 <a href="https://digital-activities.vercel.app/" target="_blank" rel="noopener noreferrer">Click here to start</a>

            `
        },
        {
          id: 'welcome',
          title: 'AI Challenges',
          description: 'AI Challenges',
          duration: '',
          type: 'Content',
          content: `
     <h1>AI Challenges</h1>

<p>
Today, we’ll be starting AI image generation challenges, and try to bring them to life. The goal is to generate custom and unique content to you that would not be found on the Internet:<br><br>
- Create at least 2 images of a person, with your name or custom text (small amounts) in the image. They could be holding a sign, wearing clothing with it, etc.<br><br>
- Create at least 2 creatures of your own design and creativity. <br><br>
- Create at least 2 environments - this could be horror themed, a dream house, etc.<br><br>


Once created, email the images to me -- hayden@pursuitnz.com -- and we can try to bring them to life.
</p>

<a href="https://digital-activities.vercel.app/custom/" target="_blank" rel="noopener noreferrer">Click here to start</a>
          `
        },
 
        
  
          {
            id: 'welcome',
            title: 'Feedback & One on One',
            description: 'Feedback & One on One',
            duration: '',
            type: 'Content',
            content: `
       <h1>Feedback & One on Ones</h1>
       <a href="https://forms.gle/pAkkjrz2abr6YHWk7" target="_blank" rel="noopener noreferrer">Click here to give feedback (anonymous) </a>

<p>
I will be doing one on ones to help find employment, utilising AI tools.
</p>

            `
          },
         
        ]
      },
      
    ]
  },
];