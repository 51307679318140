import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { type SkillSection, type SkillTrack, option4Content } from '@/data/option4-content';
import { useState } from 'react';
import { useChatStore } from '@/store/chat-store';

export function Option4Menu() {
  const [selectedSection, setSelectedSection] = useState<SkillSection | null>(null);
  const [selectedTrack, setSelectedTrack] = useState<SkillTrack | null>(null);
  const { showOption4Menu, setShowOption4Menu } = useChatStore();
}