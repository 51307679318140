import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Check } from 'lucide-react';
import { cn } from '@/lib/utils';
import { genres } from '@/data/game-genres';

interface GenreDropdownProps {
  selectedGenre: string | null;
  onSelect: (genreId: string | null) => void;
}

export function GenreDropdown({ selectedGenre, onSelect }: GenreDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const selectedGenreData = genres.find(g => g.id === selectedGenre);

  return (
    <div className="relative">
      <motion.button
        className="w-full px-4 py-2.5 bg-gray-800 rounded-xl flex items-center justify-between group hover:bg-gray-700/80 transition-colors"
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <div className="flex items-center gap-2">
          {selectedGenreData?.icon && (
            <selectedGenreData.icon size={18} className="text-blue-400" />
          )}
          <span className="text-sm font-medium">
            {selectedGenreData?.title || 'All Genres'}
          </span>
        </div>
        <ChevronDown 
          size={16} 
          className={cn(
            "text-gray-400 transition-transform",
            isOpen && "rotate-180"
          )} 
        />
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              className="fixed inset-0 z-30"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
            />
            <motion.div
              className="absolute top-full left-0 right-0 mt-2 bg-gray-800 rounded-xl overflow-hidden z-40 border border-gray-700"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <div className="p-1">
                <button
                  className="w-full px-3 py-2 rounded-lg flex items-center justify-between hover:bg-gray-700/50 transition-colors"
                  onClick={() => {
                    onSelect(null);
                    setIsOpen(false);
                  }}
                >
                  <span className="text-sm">All Genres</span>
                  {!selectedGenre && (
                    <Check size={16} className="text-blue-400" />
                  )}
                </button>
                
                {genres.map((genre) => (
                  <button
                    key={genre.id}
                    className="w-full px-3 py-2 rounded-lg flex items-center justify-between hover:bg-gray-700/50 transition-colors"
                    onClick={() => {
                      onSelect(genre.id);
                      setIsOpen(false);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <genre.icon size={16} className="text-gray-400" />
                      <span className="text-sm">{genre.title}</span>
                    </div>
                    {selectedGenre === genre.id && (
                      <Check size={16} className="text-blue-400" />
                    )}
                  </button>
                ))}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}