import { ReactNode } from "react";
import { MobileNav } from "../mobile-nav";

interface MainLayoutProps {
  children: ReactNode;
}

export function MainLayout({ children }: MainLayoutProps) {
  return (
    <div className="h-[100dvh] w-screen max-w-[100vw] bg-gray-900 text-gray-300 flex flex-col overflow-hidden">
      <main className="flex-1 flex min-h-0 pt-safe">
        <div className="flex-1 flex min-h-0 pb-safe">
          {children}
        </div>
      </main>
      <MobileNav />
    </div>
  );
}