import { create } from 'zustand';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
  {
    global: {
      headers: {
        'x-facilitator-code': '1111'
      }
    }
  }
);

interface AuthStore {
  isAuthenticated: boolean;
  needsOnboarding: boolean;
  userName: string | null;
  codeId: string | null;
  setAuth: (data: { isAuthenticated: boolean; needsOnboarding: boolean; codeId: string | null }) => void;
  setUserName: (name: string | null) => void;
  login: (code: string, username?: string) => Promise<boolean>;
  setName: (name: string) => Promise<boolean>;
  logout: () => void;
}

const loadState = () => {
  try {
    const savedState = localStorage.getItem('tams-auth-storage');
    if (savedState) {
      return JSON.parse(savedState);
    }
  } catch (error) {
    console.error('Failed to load auth state:', error);
  }
  return null;
};

const saveState = (state: Partial<AuthStore>) => {
  try {
    const subset = {
      isAuthenticated: state.isAuthenticated,
      needsOnboarding: state.needsOnboarding,
      userName: state.userName,
      codeId: state.codeId
    };
    localStorage.setItem('tams-auth-storage', JSON.stringify(subset));
  } catch (error) {
    console.error('Failed to save auth state:', error);
  }
};

const initialState = loadState() || {
  isAuthenticated: false,
  needsOnboarding: false,
  userName: null,
  codeId: null
};

export const useAuth = create<AuthStore>((set, get) => ({
  isAuthenticated: initialState.isAuthenticated || false,
  needsOnboarding: initialState.needsOnboarding || false,
  userName: initialState.userName || null,
  codeId: initialState.codeId || null,

  setAuth: (data) => {
    set(data);
    saveState({...get(), ...data});
  },
  
  setUserName: (name) => {
    set({ userName: name });
    saveState({...get(), userName: name});
  },

  login: async (code: string, username?: string) => {
    try {
      // Explicit facilitator check — code must be 1111 AND username must be 'Facilitator'
      if (code === '1111' && username === 'Facilitator') {
        const { data: facilitator, error: facilitatorError } = await supabase
          .from('login_codes')
          .select('id, name, used')
          .match({ code: '1111', name: 'Facilitator' })
          .single();
  
        if (facilitatorError || !facilitator) {
          console.error('Facilitator login failed:', facilitatorError);
          return false;
        }
  
        const newState = {
          isAuthenticated: true,
          needsOnboarding: false,
          codeId: facilitator.id,
          userName: 'Facilitator'
        };
  
        set(newState);
        saveState(newState);
  
        return true;
      }
  
      // All other users (including if username is provided and not 'Facilitator')
      if (username) {
        const { data, error } = await supabase
          .from('login_codes')
          .select('id, name, used')
          .match({ code, name: username })
          .single();
  
        if (error || !data) {
          console.error('Login failed:', error);
          return false;
        }
  
        const newState = {
          isAuthenticated: true,
          needsOnboarding: !data.used,
          codeId: data.id,
          userName: data.used ? data.name : null
        };
  
        set(newState);
        saveState(newState);
  
        return true;
      }
  
      // If no username, fallback to code only (generic match)
      const { data, error } = await supabase
        .from('login_codes')
        .select('id, name, used')
        .eq('code', code)
        .single();
  
      if (error || !data) {
        console.error('Login failed:', error);
        return false;
      }
  
      const newState = {
        isAuthenticated: true,
        needsOnboarding: !data.used,
        codeId: data.id,
        userName: data.used ? data.name : null
      };
  
      set(newState);
      saveState(newState);
  
      return true;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  },
  

  setName: async (name: string) => {
    const { codeId } = get();
    if (!codeId) return false;

    try {
      const { error } = await supabase
        .from('login_codes')
        .update({ name, used: true })
        .eq('id', codeId);

      if (error) {
        console.error('Error setting name:', error);
        return false;
      }

      const newState = {
        ...get(),
        needsOnboarding: false,
        userName: name
      };
      
      set(newState);
      saveState(newState);

      return true;
    } catch (error) {
      console.error('Error setting name:', error);
      return false;
    }
  },

  logout: () => {
    const newState = {
      isAuthenticated: false,
      needsOnboarding: false,
      userName: null,
      codeId: null
    };
    
    set(newState);
    saveState(newState);
  }
}));

export const useAuthWithOnboarding = () => {
  const store = useAuth();
  return {
    isAuthenticated: store.isAuthenticated,
    needsOnboarding: store.needsOnboarding,
    userName: store.userName
  };
};