import { motion } from 'framer-motion';

interface BackgroundImageProps {
  imageUrl: string;
}

export function BackgroundImage({ imageUrl }: BackgroundImageProps) {
  return (
    <motion.div className="absolute inset-0">
      <div 
        className="w-full h-full bg-cover bg-center bg-black transition-opacity duration-500"
        style={{ backgroundImage: `url(${imageUrl})` }}
        onLoad={(e) => {
          const target = e.target as HTMLDivElement;
          target.style.opacity = '0.6';
        }}
      />
      <motion.div 
        className="absolute inset-0 bg-black/40"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      />
    </motion.div>
  );
}