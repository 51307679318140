import { motion } from 'framer-motion';
import { type Message } from '@/store/chat-store';
import { cn } from '@/lib/utils';
import { SearchStep } from '../job-search/search-step';
import { chatActions } from '@/store/chat-actions';
import { ChatOptions } from './chat-options';

export function ChatMessage({ message }: { message: Message }) {
  const handleOptionSelect = (value: string, isCustom?: boolean) => {
    if (message.content === "What's your ideal location?") {
      chatActions.handleLocationUpdate(value, isCustom);
    } else if (message.content === "What type of job?") {
      chatActions.handleJobTypeUpdate(value, isCustom);
    } else if (message.content === "What field of job?") {
      chatActions.handleJobFieldUpdate(value, isCustom);
    }
  };

  // If message has options and is a search step
  if (message.options && (
    message.content === "What's your ideal location?" ||
    message.content === "What type of job?" ||
    message.content === "What field of job?"
  )) {
    return (
      <div className="flex-1 flex items-center justify-center p-4">
        <SearchStep
          question={message.content}
          options={message.options}
          onSelect={handleOptionSelect}
          onCustomInput={(value) => handleOptionSelect(value, true)}
          selectedValue={message.selectedOption}
        />
      </div>
    );
  }

  // If message has options but is not a search step
  if (message.options) {
    return (
      <ChatOptions 
        options={message.options}
        onSelect={(value) => handleOptionSelect(value)}
        allowCustomInput={false}
      />
    );
  }

  // Regular chat message
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={cn(
        "max-w-[80%] rounded-2xl px-4 py-2 mb-4",
        message.type === 'user' ? "bg-blue-600 ml-auto" : "bg-gray-800"
      )}
    >
      {message.content}
    </motion.div>
  );
}