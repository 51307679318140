import { useChatStore } from './chat-store';
import { locations, jobTypes, jobFields } from '@/components/job-search/search-options';
import { useMobileTabs } from '@/hooks/use-mobile-tabs';
import { useActivitiesHighlight } from '@/hooks/use-activities-highlight';
import { useGameState } from '@/hooks/use-game-state';
import { useAuth } from '@/hooks/use-auth';
import { createClient } from '@supabase/supabase-js';
import { searchJobs } from '@/lib/perplexity';

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

class ChatActionsManager {
  handleStartActivity = () => {
    const { setActiveTab } = useMobileTabs.getState();
    const { setHighlighted } = useActivitiesHighlight.getState();
    const store = useChatStore.getState();
    store.setShowProjectCreator(false);
    store.setShowJobExploration(false);
    store.setIsJobSearchActive(false);
    store.clearMessages();
    setHighlighted(true);
    setActiveTab('sources');
  };

  handleCreateProject = () => {
    const store = useChatStore.getState();
    const { setActiveTab } = useMobileTabs.getState();
    const { setHighlighted } = useActivitiesHighlight.getState();
    setHighlighted(false);
    store.setShowJobExploration(false);
    store.setIsJobSearchActive(false);
    store.clearMessages();
    setActiveTab('chat');
    store.setShowProjectCreator(true);
  };

  handleExploreJob = () => {
    const store = useChatStore.getState();
    const { setActiveTab } = useMobileTabs.getState();
    const { setHighlighted } = useActivitiesHighlight.getState();
    setHighlighted(false);
    store.setShowProjectCreator(false);
    store.setIsJobSearchActive(false);
    store.clearMessages();
    setActiveTab('chat');
    store.setShowJobExploration(true);
  };

  handleSearchJobs = () => {
    const store = useChatStore.getState();
    const { setHighlighted } = useActivitiesHighlight.getState();
    const { clearSelection } = useGameState.getState();
    const { setActiveTab } = useMobileTabs.getState();
    console.log('Starting job search flow');
    setHighlighted(false);
    clearSelection();
    store.clearMessages();
    store.resetJobSearch();
    store.setHasResults(false);
    store.setJobCount(0);
    store.setIsJobSearchActive(true);
    setActiveTab('chat');
    store.addMessage("What's your ideal location?", "bot", locations);
  };

  handleLocationUpdate = (location: string, isCustom?: boolean) => {
    const store = useChatStore.getState();
    console.log('Location selected:', location, 'Custom:', isCustom);
    const customOption = isCustom ? { label: location, value: location, isCustom: true } : undefined;
    const updatedOptions = customOption ? [...locations, customOption] : locations;
    store.updateMessageOption("What's your ideal location?", updatedOptions, location);
    store.updateJobSearch({ location });
    store.addMessage("What type of job?", "bot", jobTypes);
  };

  handleJobTypeUpdate = (type: string, isCustom?: boolean) => {
    const store = useChatStore.getState();
    console.log('Job type selected:', type, 'Custom:', isCustom);
    const customOption = isCustom ? { label: type, value: type, isCustom: true } : undefined;
    const updatedOptions = customOption ? [...jobTypes, customOption] : jobTypes;
    store.updateMessageOption("What type of job?", updatedOptions, type);
    store.updateJobSearch({ type });
    store.addMessage("What field of job?", "bot", jobFields);
  };

  handleJobFieldUpdate = async (field: string, isCustom?: boolean) => {
    const store = useChatStore.getState();
    const { codeId } = useAuth.getState();
    const { location, type } = store.currentJobSearch;
    console.log('Job field selected:', field, 'Custom:', isCustom);
    console.log('Current search params:', { location, type, field });
    const customOption = isCustom ? { label: field, value: field, isCustom: true } : undefined;
    const updatedOptions = customOption ? [...jobFields, customOption] : jobFields;
    store.updateMessageOption("What field of job?", updatedOptions, field);
    store.updateJobSearch({ field });
    store.setIsSearching(true);
    store.setSearchError(null);
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      console.log('Making API call with params:', { location, type, field });
      const jobs = await searchJobs({ 
        location: location || 'New Zealand',
        type: type || 'Full-time',
        field: field,
        count: 1
      });
      console.log('API response:', jobs);
      store.setSearchResults(jobs);
      if (codeId) {
        try {
          await supabase.rpc('increment_job_searches', { user_id: codeId });
        } catch (err) {
          const error = err instanceof Error ? err : new Error('Failed to update job search stats');
          console.error('Stats update error:', error);
        }
      }
      setTimeout(() => {
        store.setIsJobSearchActive(false);
        useMobileTabs.getState().setActiveTab('tasks');
      }, 2000);
    } catch (error) {
      const formattedError = error instanceof Error ? error : new Error(String(error));
      console.error('Search error:', formattedError);
      store.setSearchError(formattedError.message);
    }
    store.setIsSearching(false);
  };

  returnToChat = () => {
    const { setActiveTab } = useMobileTabs.getState();
    const { setHighlighted } = useActivitiesHighlight.getState();
    const { clearSelection } = useGameState.getState();
    const store = useChatStore.getState();
    setHighlighted(false);
    clearSelection();
    store.clearMessages();
    setActiveTab('chat');
  };

  handleSummarise = () => {
    const query = encodeURIComponent(
        "Summarise the following text in a clear and concise way:\n\n" +
        "📌 Summary Length: (Short | Medium | Detailed) - Choose based on content.\n" +
        "📌 Focus: (Key points | Simplified explanation | Professional summary).\n" +
        "📌 Maintain the original meaning while improving clarity.\n\n" +
        "Here is the text to summarise:"
    );
    window.open(`https://chatgpt.com/?q=${query}`, '_blank');
};

handleEmail = () => {
    const query = encodeURIComponent(
        "Write an email based on the following details:\n\n" +
        "📌 Recipient: (Boss | Colleague | Client | Other)\n" +
        "📌 Purpose: (Request | Apology | Follow-up | Inquiry | Other)\n" +
        "📌 Tone: (Formal | Friendly | Professional | Casual)\n" +
        "📌 Key details to include: (Specify any necessary information).\n\n" +
        "Generate a well-structured email using New Zealand English:\n\n" +
        "Here are the details:"
    );
    window.open(`https://chatgpt.com/?q=${query}`, '_blank');
};

handleSpelling = () => {
    const query = encodeURIComponent(
        "Check the following text for spelling, grammar, and clarity using **New Zealand English**.\n\n" +
        "📌 Fix all spelling mistakes.\n" +
        "📌 Correct grammatical errors while maintaining natural tone.\n" +
        "📌 (Optional) Suggest minor style improvements if necessary.\n\n" +
        "Here is the text to check:"
    );
    window.open(`https://chatgpt.com/?q=${query}`, '_blank');
};

handleExplain = () => {
    const query = encodeURIComponent(
        "Explain the following concept in a clear and simple way:\n\n" +
        "📌 Adjust explanation level: (Basic | Intermediate | Advanced).\n" +
        "📌 Provide examples where relevant.\n" +
        "📌 Break down complex ideas into easy-to-understand parts.\n\n" +
        "Here is the topic to explain:"
    );
    window.open(`https://chatgpt.com/?q=${query}`, '_blank');
};

}

export const chatActions = new ChatActionsManager();
