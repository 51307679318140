import { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '@/hooks/use-auth';

export function NameCollection() {
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { setName: updateName } = useAuth();

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim() && !isSubmitting) {
      setIsSubmitting(true);
      setError('');
      
      const success = await updateName(name.trim());
      
      if (!success) {
        setError('Failed to save name. Please try again.');
      }
      
      setIsSubmitting(false);
    }
  }, [name, isSubmitting, updateName]);

  return (
    <motion.div 
      className="fixed inset-0 bg-gray-900 flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 via-purple-500/20 to-pink-500/20 opacity-50" />
      
      <motion.div 
        className="relative z-10 w-full max-w-lg px-6"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <form onSubmit={handleSubmit} className="relative">
          <motion.div
            className="relative"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-6 py-4 rounded-2xl bg-white/5 backdrop-blur-sm border-2 border-white/10 focus:border-blue-500/50 focus:outline-none text-center text-2xl font-medium placeholder:text-white/30 transition-colors"
              placeholder="What's your name?"
              autoFocus
            />
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-xl -z-10" />
          </motion.div>
          
          <motion.button
            type="submit"
            className="w-full px-6 py-4 rounded-2xl bg-gradient-to-r from-blue-500 to-purple-500 font-medium text-lg mt-4 disabled:opacity-50 disabled:cursor-not-allowed transition-opacity"
            disabled={!name.trim() || isSubmitting}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5 }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isSubmitting ? 'Saving...' : 'Continue'}
          </motion.button>
          
          {error && (
            <motion.p
              className="text-red-500 text-sm mt-2 text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {error}
            </motion.p>
          )}
        </form>

        <motion.p
          className="text-center text-white/40 mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          This will be used to personalise your experience
        </motion.p>
      </motion.div>
    </motion.div>
  );
}