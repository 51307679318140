import { useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { TextSimplifier } from './text-simplifier';
import { ToolExplorer } from './tool-explorer';

interface CourseContentProps {
  title: string;
  content: string;
  onClose: () => void;
}

interface ModifiedRange {
  range: Range;
  originalText: string;
  element: HTMLElement;
}

export function CourseContent({ title, content, onClose }: CourseContentProps) {
  const [selectedText, setSelectedText] = useState('');
  const [showSimplifier, setShowSimplifier] = useState(false);
  const [simplifierPosition, setSimplifierPosition] = useState({ x: 0, y: 0 });
  const [modifiedRanges, setModifiedRanges] = useState<ModifiedRange[]>([]);
  const [isModified, setIsModified] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleTextSelection = useCallback((e: MouseEvent | TouchEvent) => {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      setShowSimplifier(false);
      return;
    }

    const text = selection.toString().trim();
    if (!text) {
      setShowSimplifier(false);
      return;
    }

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    // Check if selection is within a modified span
    const container = range.commonAncestorContainer;
    const modifiedSpan = container.nodeType === 3 ? container.parentElement : container as HTMLElement;
    const isModifiedText = modifiedSpan?.classList.contains('text-blue-400');

    // Calculate position for simplifier
    const isMobile = window.innerWidth < 768;
    let x: number;
    let y: number;

    if (isMobile) {
      // On mobile, position at the bottom of the screen
      x = Math.min(Math.max(window.innerWidth / 2, 100), window.innerWidth - 100);
      y = window.innerHeight - 150;
    } else {
      // On desktop, position next to selection
      x = Math.min(rect.right + 10, window.innerWidth - 300);
      y = rect.top + (rect.height / 2);
    }

    setSelectedText(text);
    setIsModified(isModifiedText);
    setSimplifierPosition({
      x,
      y: y + window.scrollY
    });
    setShowSimplifier(true);
  }, []);

  const replaceSelectedText = useCallback((newText: string) => {
    const selection = window.getSelection();
    if (!selection || !contentRef.current) return;

    const range = selection.getRangeAt(0);
    const span = document.createElement('span');
    span.className = 'bg-blue-500/10 text-blue-400 px-1 rounded transition-colors select-text';
    span.textContent = newText;
    
    // Store original text and range for reverting
    const originalText = range.toString();
    setModifiedRanges(prev => [...prev, {
      range: range.cloneRange(),
      originalText,
      element: span
    }]);

    range.deleteContents();
    range.insertNode(span);
    selection.removeAllRanges();

    // Animate the replacement
    span.style.opacity = '0';
    requestAnimationFrame(() => {
      span.style.transition = 'opacity 0.3s ease';
      span.style.opacity = '1';
    });
  }, []);

  const revertModifiedText = useCallback(() => {
    const selection = window.getSelection();
    if (!selection) return;

    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;
    const modifiedSpan = container.nodeType === 3 ? container.parentElement : container as HTMLElement;

    // Find the modified range that matches this span
    const modifiedRange = modifiedRanges.find(m => m.element === modifiedSpan);
    if (modifiedRange) {
      // Restore original text
      modifiedSpan.textContent = modifiedRange.originalText;
      modifiedSpan.className = 'select-text';

      // Remove from tracking
      setModifiedRanges(prev => prev.filter(m => m !== modifiedRange));
    }
  }, [modifiedRanges]);

  const renderContent = () => {
    // For tool explorer, return early
    if (title === 'Tool Search') {
      return <ToolExplorer />;
    }

    // Process content for media embeds
    let processedContent = content;
    const videoRegex = /\[video:([^\]]+)\]/g;
    const imageRegex = /\[image:([^\]]+)\]/g;

    // Replace video tags
    processedContent = processedContent.replace(videoRegex, (match, url) => {
      return `
        <div class="video-embed my-8 rounded-xl overflow-hidden bg-black/20 backdrop-blur-sm">
          <div class="aspect-video">
            <iframe 
              src="${url}" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen
              class="w-full h-full"
            ></iframe>
          </div>
        </div>
      `;
    });

    // Replace image tags
    processedContent = processedContent.replace(imageRegex, (match, url) => {
      return `
        <div class="image-embed my-8">
          <img 
            src="${url}" 
            alt="Course content image"
            class="w-full rounded-xl shadow-lg"
            loading="lazy"
          />
        </div>
      `;
    });

    // Make all text content selectable by wrapping in spans
    processedContent = processedContent.replace(
      /(<h[1-6]>|<p>|<li>|<blockquote>)(.*?)(<\/h[1-6]>|<\/p>|<\/li>|<\/blockquote>)/g,
      (match, openTag, content, closeTag) => {
        return `${openTag}<span class="select-text">${content}</span>${closeTag}`;
      }
    );

    return processedContent;
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black/90 backdrop-blur-lg z-[500] course-content-scrollbar"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="min-h-full w-full max-w-4xl mx-auto px-4 sm:px-6 py-24 text-white"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <motion.button
          className="fixed top-6 right-6 p-3 hover:bg-white/10 rounded-xl transition-colors"
          onClick={onClose}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <X size={24} />
        </motion.button>

        <motion.h1 
          className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 bg-gradient-to-r from-white to-white/60 bg-clip-text text-transparent select-text"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {title}
        </motion.h1>

        <motion.div
          ref={contentRef}
          className="prose prose-lg prose-invert max-w-none overflow-x-hidden"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
          onMouseUp={(e) => handleTextSelection(e.nativeEvent)}
          onTouchEnd={(e) => handleTextSelection(e.nativeEvent)}
        >
          {title === 'Tool Search' ? (
            <ToolExplorer />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: renderContent() }} />
          )}
        </motion.div>

        <AnimatePresence>
          {showSimplifier && (
            <TextSimplifier
              selectedText={selectedText}
              onClose={() => setShowSimplifier(false)}
              onReplace={replaceSelectedText}
              onRevert={revertModifiedText}
              position={simplifierPosition}
              isModified={isModified}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
}